import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDBox";
import MDTypography from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDTypography";
import MDButton from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDButton";

// Billing page components
import ClaimsRowsViewCard from "./ClaimsRowsViewCard";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress
} from "@mui/material";

function IDPFNOLClaimsView({ claimsData }) {
  const [openDocument, setOpenDocument] = useState(false);
  const [documentContent, setDocumentContent] = useState(null);
  const [documentType, setDocumentType] = useState(null);
  const [documentName, setDocumentName] = useState("");
  const [loadingClaims, setLoadingClaims] = useState({}); // Object to track loading state for each claim

  const ic_id = localStorage.getItem("ic_id_for_dashboard")


  const handleDocumentView = async (claimId) => {
    setLoadingClaims(prevLoading => ({ ...prevLoading, [claimId]: true })); // Start loading for specific claimId
    try {
      const company = JSON.parse(localStorage.getItem("carrier_admin_company"));
      const response = await axios.post(
        `${process.env.REACT_APP_URL}get_idp_process_documents/`, // Your API endpoint
        {
          ic_id: ic_id || company.ic_id,
          claim_id: claimId
        }
      );

      console.log("Document API Response:", response.data);

      if (response.data.encoded_content) {
        setDocumentContent(response.data.encoded_content);
        setDocumentType(response.data.content_type);
        setDocumentName(response.data.document_name);
        setOpenDocument(true);
      } else {
        console.error("Error fetching document: No content received");
        // You might want to display an error message to the user here
      }

    } catch (error) {
      console.error("Error fetching document:", error);
      // Handle error, e.g., display an error message
    } finally {
      setLoadingClaims(prevLoading => ({ ...prevLoading, [claimId]: false })); // Stop loading for specific claimId
    }
  };

  const handleCloseDocument = () => {
    setOpenDocument(false);
    setDocumentContent(null);
    setDocumentType(null);
    setDocumentName("");
  };

  const getDocumentUrl = () => {
    if (documentType === 'pdf') {
      const pdfBytes = Uint8Array.from(atob(documentContent), c => c.charCodeAt(0));
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      return URL.createObjectURL(blob);
    } else if (documentType === 'html') {
      return `data:text/html;base64,${btoa(documentContent)}`;
    } else {
      return null;
    }
  };

  return (
    <Card id="billing-info">
      <MDBox pt={3} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Claims Information
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} sx={{ maxHeight: "422px", overflowY: "auto" }}> {/* Set max height and enable vertical scroll */}
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {/* Table for all claims data */}
          <table style={{ width: "100%", tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Claim Number</th>
                <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Claim Created At</th>
                <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Policy Holder Name</th>
                <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Policy Number</th>
                <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Loss Date and Time</th>
                <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Loss Location</th>
                <th style={{ padding: "8px", textAlign: "center", fontSize: "12px" }}>Document</th> {/* New heading for Document View */}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(claimsData) && claimsData.length > 0 ? (
                claimsData.map((claim, index) => (
                  <tr key={claim.claim_id}>
                    <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claim.claim_id}</td>
                    <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claim.claim_created_at}</td>
                    <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claim.policy_holder_name}</td>
                    <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claim.policy_number}</td>
                    <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claim.loss_date_and_time}</td>
                    <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claim.loss_location}</td>
                    <td style={{ padding: "8px", textAlign: "center" }}>
                      <MDButton
                        variant="outlined"
                        color="info"
                        onClick={() => handleDocumentView(claim.claim_id)}
                        size="small"
                        title="Document View"
                        disabled={loadingClaims[claim.claim_id] || false} // Disable only if loading for this claimId
                        startIcon={
                          loadingClaims[claim.claim_id] && ( // Show loading indicator only for this claimId
                            <CircularProgress size={16} color="inherit" />
                          )
                        }
                      >
                        <Icon>description</Icon>
                      </MDButton>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" style={{ padding: "8px", textAlign: "center" }}>
                    No Claims found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </MDBox>
      </MDBox>
      {/* Document Popup Dialog */}
      <Dialog open={openDocument} onClose={handleCloseDocument} fullWidth maxWidth="md">
        <DialogTitle>{documentName}</DialogTitle>
        <DialogContent>
          {documentType === 'pdf' && (
            <embed src={getDocumentUrl()} width="100%" height="600px" type="application/pdf" />
          )}
          {documentType === 'html' && (
            <div dangerouslySetInnerHTML={{ __html: documentContent }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDocument} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default IDPFNOLClaimsView;
