import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDBox";
import MDTypography from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDTypography";
import MDButton from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDButton";

// Billing page components
import ClaimsRowsViewCard from "./ClaimsRowsViewCard";

// Data for rows (success)
const successData = [
  {
    name: "John Doe",
    company: "Tech Solutions",
    email: "john@tech.com",
    vat: "FRB1234567",
  },
  {
    name: "Jane Smith",
    company: "Innovate LLC",
    email: "jane@innovate.com",
    vat: "FRB1234567",
  },
];

function FNOLClaimsView({ claimsData }) {
  const renderRows = (data) =>
    data.map((bill, index) => (
      <ClaimsRowsViewCard
        key={index}
        name={bill.name}
        company={bill.company}
        email={bill.email}
        vat={bill.vat}
        noGutter={index === data.length - 1}
        actions={
          <MDBox display="flex" gap={1}>
            <MDButton variant="outlined" color="info">
              <Icon>visibility</Icon>&nbsp;Document View
            </MDButton>
            <MDButton variant="outlined" color="info">
              <Icon>email</Icon>&nbsp;Email View
            </MDButton>
          </MDBox>
        }
      />
    ));

  return (
    <Card id="billing-info">
    <MDBox pt={3} px={2} display="flex" justifyContent="space-between" alignItems="center">
      <MDTypography variant="h6" fontWeight="medium">
        Claims Information
      </MDTypography>
    </MDBox>
    <MDBox pt={1} pb={2} px={2} sx={{ maxHeight: "422px", overflowY: "auto" }}> {/* Set max height and enable vertical scroll */}
      <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
        {/* Table for all claims data */}
        <table style={{ width: "100%", tableLayout: "fixed" }}>
          <thead>
            <tr>
              <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Claim Number</th>
              <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Claim Created At</th>
              <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Policy Holder Name</th>
              <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Policy Number</th>
              <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Loss Date and Time</th>
              <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Loss Location</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(claimsData) && claimsData.length > 0 ? (
              claimsData.map((claim, index) => (
                <tr key={claim.claim_id}> 
                  <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claim.claim_id}</td>
                  <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claim.claim_created_at}</td>
                  <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claim.policy_holder_name}</td>
                  <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claim.policy_number}</td>
                  <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claim.loss_date_and_time}</td>
                  <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claim.loss_location}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" style={{ padding: "8px", textAlign: "center" }}>
                  No Claims found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </MDBox>
    </MDBox>
  </Card>
  );
}

export default FNOLClaimsView;
