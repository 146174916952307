import { useState } from "react";
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDBox";
import MDTypography from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDTypography";
import MDButton from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDButton";
import IDPFNOLClaimsView from "./IDPFNOLClaimsView";
import axios from "axios";

// Material Dashboard 2 React context
import { useMaterialUIController } from "../../../CompanyDashboardChartsCardsLayouts/context";
import { CircularProgress } from "@mui/material";

function IDPFNOLBatchCard({ title }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [expanded, setExpanded] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const [claims, setClaims] = useState([]); // Store fetched claims
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator

   

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const ic_id = localStorage.getItem("ic_id_for_dashboard")

  const handleFetchClaims = async () => {
    setIsLoading(true); // Start loading
    try {
      const company = JSON.parse(localStorage.getItem("carrier_admin_company"));
      const formData = new FormData();
      formData.append("ic_id", ic_id || company.ic_id);
      formData.append("channel", "IDP");

      const response = await axios.post(
        `${process.env.REACT_APP_URL}get_all_channels_claims/`,
        formData
      );
      
      console.log("Claims Data IDP:", response.data); 
      const idpClaims = Object.values(response.data.claims_details);
      setClaims(idpClaims); 
      setFetchCount(idpClaims.length); // Update count

    } catch (error) {
      console.error("Error fetching claims:", error);
      // Handle error, e.g., show an error message to the user
    } finally {
      setIsLoading(false); // Stop loading regardless of success or failure
    }
  };

  return (
    <Card id="delete-account">
      <MDBox pt={2} pb={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          {title}
        </MDTypography>
        <MDBox display="flex" alignItems="center">
        <MDButton
          variant="gradient"
          color="info"
          onClick={handleFetchClaims}
          sx={{ mr: 2, ml: 2, display: 'flex', alignItems: 'center' }}
          disabled={isLoading} 
        >
          {isLoading && <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />} 
          {isLoading ? "Fetching..." : "Fetch Claims"}
        </MDButton>
          <MDTypography variant="h6" fontWeight="medium" color="textSecondary" sx={{ mb: 1 }}>
          Success Claims: {fetchCount} 
          </MDTypography>
        </MDBox>
        <MDButton variant="gradient" color="dark" onClick={toggleExpand}>
          <Icon sx={{ fontWeight: "bold" }}>{expanded ? "expand_less" : "expand_more"}</Icon>
          &nbsp;{expanded ? "Hide IDP Claims" : "View IDP Claims"}
        </MDButton>
      </MDBox>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <MDBox p={2}>
          <IDPFNOLClaimsView claimsData={claims} />
        </MDBox>
      </Collapse>
    </Card>
  );
}

// Setting default values for the props of IDPFNOLBatchCard
IDPFNOLBatchCard.defaultProps = {
  title: "IDP Claims",
};

// Typechecking props for the IDPFNOLBatchCard
IDPFNOLBatchCard.propTypes = {
  title: PropTypes.string,
};

export default IDPFNOLBatchCard;
