import React, { useEffect } from "react";
import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
 
const CustomDialog = ({ open, handleClose }) => {
//   const navigate = useNavigate();
 
const isInsured = localStorage.getItem('isInsured')
  const handleLogin = () => {
    if(isInsured === 'yes'){
        const dummy = localStorage.getItem('dummy')
        localStorage.removeItem("showPopup");
        localStorage.setItem('Auth',dummy)
        localStorage.removeItem('dummy')
        setTimeout(() => {
            const auth =  localStorage.getItem("Auth")
            // setdummy(auth)
            localStorage.setItem('dummy',auth)
            localStorage.setItem('showPopup',true)
            localStorage.removeItem('Auth');
            window.location.reload();
        },60 * 60 * 1000 );
        handleClose();
      }
      else{
        const dummy = sessionStorage.getItem('dummy')
        localStorage.removeItem("showPopup");
    sessionStorage.setItem('NonInsuredAuth',dummy)
    sessionStorage.removeItem('dummy');
 
    setTimeout(() => {
        const NonInsurAuth =  sessionStorage.getItem("NonInsuredAuth")
        // setdummy(auth)
        sessionStorage.setItem('dummy',NonInsurAuth)
        localStorage.setItem('showPopup',true)
       
        sessionStorage.removeItem('NonInsuredAuth');
        // localStorage.removeItem('Auth');
        window.location.reload();
        // setToken(null);
    },20 * 60 * 1000 );
    handleClose();
      }
 
 
   
    // navigate("/signin"); // Redirect to the login page or wherever needed
  };
 
  return (
    <Dialog
      open={open}
      aria-labelledby="login-signup-dialog"
      disableEscapeKeyDown
      disableBackdropClick
    >
      <DialogContent>
        <h2>You have been logged out</h2>
        <p>Please ReLogin to continue.</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleLogin} color="primary">
          Re LOGIN
        </Button>
      </DialogActions>
    </Dialog>
  );
};
 
export default CustomDialog;