import React, { useState, useEffect } from 'react';
import {
  Box, FormControlLabel, Link, Checkbox,
  Grid,
  Button,
  Typography,
  Container,
  Avatar,
  TextField,
  Paper,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert'; // For custom alert in Snackbar
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import StyledButtonComponent from '../../../components/StyledButton';
import { useNavigate } from 'react-router-dom';
import SignInNavbar from '../../../components/SignInNavbar';
import ReCAPTCHA from 'react-google-recaptcha';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import InsurAI_IMG from "../../../assets/sideimg_1.jpg";
import Footer from '../../../components/footer';
import axios from 'axios'; // Import axios for API calls
import Header from '../../../components/header';


// Custom Alert component for Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const initialValue = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  date_of_birth: "",
};

export default function PolicySignup() {
  const [signupValues, setSignupValues] = useState(initialValue);
  const [otp, setOtp] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showOtpField, setShowOtpField] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [isVerifyEmailButtonEnabled, setIsVerifyEmailButtonEnabled] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [signupSuccess, setSignupSuccess] = useState(false); // New state for signup success

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isScreen = useMediaQuery('(max-width:900px)');

  let {
    firstName,
    lastName,
    email,
    phoneNumber,
    date_of_birth,
  } = signupValues

  const onChangeRecap = (value) => {
    setRecaptchaToken(value);
    validateForm();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSignupValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };


  const validateField = (fieldName, value) => {
    let error = '';
    switch (fieldName) {
      case 'firstName':
      case 'lastName':
        error = value.trim() ? '' : 'This field is required';
        break;
      case 'email':
        error = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Invalid email address';
        break;
      case 'phoneNumber':
        error = /^\d{10}$/.test(value) ? '' : 'Phone number must be 10 digits';
        break;
      case 'date_of_birth':
        error = value ? '' : 'Date of birth is required';
        break;
      default:
        break;
    }
    setFieldErrors(prev => ({ ...prev, [fieldName]: error }));
  };

  const validateForm = () => {
    const isFormValid = Object.values(fieldErrors).every(error => error === '') &&
      Object.values(signupValues).every(value => value.trim() !== '') &&
      recaptchaToken;
    setIsVerifyEmailButtonEnabled(isFormValid);
  };

  useEffect(() => {
    validateForm();
  }, [signupValues, fieldErrors, recaptchaToken]);


  console.log("signupvalues", signupValues);



  const handleVerifyEmail = async () => {
    setError('');
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("mobile_number", phoneNumber);

      const response = await axios.post(`${process.env.REACT_APP_URL}Policy/verify_email_and_mobile/`, formData);

      if (response.status === 200) {
        setShowOtpField(true);
        setOtpSent(true); // Show OTP sent Snackbar
        setOtp(''); // Clear any previous OTP value
      } else {
        setError(response.data.error || 'Verification failed. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      setError(error.response?.data?.error || 'An error occurred during verification.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendOTP = async () => {
    setError('');
    setResendLoading(true);

    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("mobile_number", phoneNumber);

      const response = await axios.post(`${process.env.REACT_APP_URL}Policy/verify_email_and_mobile/`, formData);

      if (response.status === 200) {
        setOtpSent(true); // Show OTP sent Snackbar 
        setOtp(''); // Clear the OTP field
      } else {
        setError(response.data.error || 'Failed to resend OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error resending OTP:', error);
      setError(error.response?.data?.error || 'An error occurred while resending OTP.');
    } finally {
      setResendLoading(false);
    }
  };

 
  const handleSignUp = async () => {
    setError('');
    setIsLoading(true);

    try {
      const formData = new FormData();
      // ... append all signup data to formData
      formData.append('first_name', firstName);
      formData.append('last_name', lastName);
      formData.append('email', email);
      formData.append('mobile_number', phoneNumber);
      formData.append('date_of_birth', date_of_birth);
      formData.append('otp', otp);
      formData.append('recaptcha', recaptchaToken);

      const response = await axios.post(`${process.env.REACT_APP_URL}Policy/policy_sign_up/`, formData);

      if (response.status === 201) {
        setSignupSuccess(true);
         // Correctly set localStorage
         localStorage.setItem('PolicyHolderDetailsAfterPolicyIntakeSignIn', JSON.stringify({
          user_name: firstName + " " + lastName,
          user_email: email,
          mobile_number: phoneNumber,
          date_of_birth: date_of_birth,
        }));
        setTimeout(() => {
          navigate('/Demo/SmartQuote');
        }, 3000);
      } else {
        setError(response.data.error || 'Signup failed. Please try again.');
      }
    } catch (error) {
      console.error('Error during signup:', error);
      setError(error.response?.data?.error || 'An error occurred during signup.');
    } finally {
      setIsLoading(false);
    }
  };






  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOtpSent(false);
  };



  const customTextFieldStyle = {
    color: '#000000',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderBottom: '0.2px solid rgba(0, 0, 0, 0.5)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderBottom: '0.2px solid rgba(0, 0, 0, 0.5)',
      borderRadius: "none"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderBottom: '2px solid #0B70FF',
      borderRadius: "none"
    },
  };


  const handleGotoSigIn = () => {
    navigate('/policysignIn')
  };
  const [checked, setChecked] = useState(false);


  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      {/* ...  Snackbar for OTP sent message */}
      <Snackbar
        open={otpSent}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Set Snackbar position 
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          OTP sent to your email!
        </Alert>
      </Snackbar>
      {/* Snackbar for Signup Success  */}
      <Snackbar
        open={signupSuccess}
        autoHideDuration={3000} // Adjust duration as needed
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Successfully signed up! You will be redirected shortly...
        </Alert>
      </Snackbar>

      {/* <SignInNavbar 00 */}
      <Header />
      <Grid container>
        <Grid item xs={12} sm={12} md={6.5}>
          <Box sx={{ position: "relative", width: isScreen ? "100vw" : "50vw", height: "100%", backgroundColor: 'blue' }}>

            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // alignItems: "center",
                color: "white",
                padding: 4,
              }}
            >
              <Typography variant="h3" component="h1" gutterBottom className='Nasaliza' sx={{ color: 'orange' }}>
                Policy Intake
              </Typography>
              <Typography variant="h3" component="h1" gutterBottom className='Nasaliza'>Sign up to explore</Typography>
              <Typography variant="h2" component="h2" className='Nasaliza'>
                Innovon Tech Solutions
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/* <Grid m={'10rem'}></Grid> */}
        <Grid item xs={12} sm={12} md={4.5}>
          <Box
            sx={{

              bgcolor: "background.paper",
              borderRadius: 2,
              width: isScreen ? "100vw" : "100%",

              height: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Container component="main" maxWidth="md">
              <Paper elevation={6} sx={{ padding: 2, borderRadius: 2, marginTop: 10, marginBottom: 10 }}>
                <Box>
                  <Grid sx={{
                    backgroundColor: "rgb(49, 114, 246)",
                    marginTop: '-2rem',
                    color: 'white',
                    borderRadius: "20px",
                    padding: '2rem 0rem',
                  }}>
                    <Typography component="h1" variant="h5" sx={{ color: "orange" }} className='Nasaliza'>
                      Policy Intake
                    </Typography>
                    <Typography component="h1" variant="h5" sx={{}} className='Nasaliza'>Sign up</Typography>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                        <LockOutlinedIcon />
                      </Avatar>
                    </Box>
                  </Grid>
                  <Box component="form" sx={{ width: "100%", }}>
                    <Box sx={{ padding: theme.spacing(4), margin: 'auto', maxWidth: '400px' }}>
                      <Grid item xs={12} md={12} container justifyContent="center" spacing={isMobile ? 1 : 2}>
                        <Grid item xs={12}>
                          {error && (
                            <Typography style={{ color: "red" }}> {error}</Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            margin="normal"
                            required
                            id="firstName"
                            label="First Name"
                            name="firstName"
                            autoComplete="given-name"
                            autoFocus
                            value={firstName}
                            onChange={handleInputChange}
                            error={!!fieldErrors.firstName}
                            helperText={fieldErrors.firstName}
                            sx={customTextFieldStyle}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            margin="normal"
                            required
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            autoComplete="family-name"
                            value={lastName}
                            onChange={handleInputChange}
                            error={!!fieldErrors.lastName}
                            helperText={fieldErrors.lastName}
                            sx={customTextFieldStyle}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            margin="normal"
                            required
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={handleInputChange}
                            error={!!fieldErrors.email}
                            helperText={fieldErrors.email}
                            sx={customTextFieldStyle}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            margin="normal"
                            required
                            id="phoneNumber"
                            label="Phone Number"
                            name="phoneNumber"
                            autoComplete="tel"
                            value={phoneNumber}
                            onChange={handleInputChange}
                            error={!!fieldErrors.phoneNumber}
                            helperText={fieldErrors.phoneNumber}
                            sx={customTextFieldStyle}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <TextField
                            fullWidth
                            margin="normal"
                            required
                            id="dob"
                            label="Date of Birth"
                            name="date_of_birth"
                            autoComplete="bday"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={date_of_birth}
                            onChange={handleInputChange}
                            error={!!fieldErrors.date_of_birth}
                            helperText={fieldErrors.date_of_birth}
                            sx={customTextFieldStyle}
                          />
                        </Grid>
                        {/* OTP Input (Conditional Rendering) */}
                        {showOtpField && (
                          <Grid item xs={12} md={12} container alignItems="center"> {/* Use container for alignment */}
                            <Grid item xs={7}> {/* Adjust width as needed */}
                              <TextField
                                fullWidth
                                margin="normal"
                                required
                                id="otp"
                                label="Enter OTP"
                                name="otp"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                sx={customTextFieldStyle}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <StyledButtonComponent // Use StyledButtonComponent here
                                buttonWidth={130}
                                onClick={handleResendOTP}
                                disabled={resendLoading}
                                sx={{ mt: 2 }}
                                fullWidth
                              >
                                {resendLoading ? <CircularProgress size={20} color="inherit" /> : 'Resend OTP'}
                              </StyledButtonComponent>
                            </Grid>
                          </Grid>

                        )}

                        <Grid item xs={12} md={12} sx={{ marginTop: theme.spacing(2), display: 'flex', justifyContent: 'center' }}>
                          <ReCAPTCHA sitekey={`${process.env.REACT_APP_RECAPTCHA}`} onChange={onChangeRecap} />
                        </Grid>
                        <Grid item xs={12} md={12}  sx={{ textAlign: 'center'}}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              name='terms&conditions'
                                              checked={checked}
                                              onChange={handleCheckboxChange}
                                              color="primary"
                                              size='25px'
                                            />
                                          }
                                          label={
                                            <Typography variant="body2" >
                                              I accept the <Link href="/TermsofUse" target="_blank" >Terms of Use</Link>
                                            </Typography>
                                          } />
                                      </Grid>
                        <Grid item xs={12} md={12}>                            
                          <StyledButtonComponent
                            fullWidth
                            onClick={showOtpField ? handleSignUp : handleVerifyEmail}
                            disabled={isLoading || (showOtpField && !otp) || !checked || !isVerifyEmailButtonEnabled}
                            disableColor={"#B6E3FF"}
                          >
                            {isLoading ? (
                              <CircularProgress size={24} color="inherit" />
                            ) : showOtpField ? (
                              'Sign Up'
                            ) : (
                              'Verify Email'
                            )}
                          </StyledButtonComponent>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography align="center" sx={{ paddingTop: 2, color: 'gray' }} className='Nasaliza'>
                            Have an account?<Button color="primary" onClick={handleGotoSigIn}>Sign In</Button>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Container>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
