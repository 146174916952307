import React, { useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Upload } from 'lucide-react';
import { GoogleGenerativeAI } from "@google/generative-ai";
import { Await, useNavigate } from "react-router-dom";
import axios from 'axios';
import { Tilt } from 'react-tilt';
import LRR_1 from '../assets/LRR_1.png';
import LRR_2 from '../assets/LRR_2.png';
import LRR_3 from '../assets/LRR_3.png';
import LS_1 from '../assets/LS_1.png';
import LS_2 from '../assets/LS_2.png';
import LS_upload from '../assets/LS_upload.png';
import LS_FINAL from '../assets/LS_FINAL.png';
import * as XLSX from 'xlsx';
import { BarChart, LineChart, GitBranch, Brain } from "lucide-react";
import StyledButtonComponent from '../components/StyledButton';
import Loss_Run from '../assets/Loss_Run.png';
import Loss_result from '../assets/Loss_result.png';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  Container,
  CardContent,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,Link,
  Paper,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Grid, Card
} from '@mui/material';

import { styled } from '@mui/system';
import Header from '../components/header';
import Footer from '../components/footer';
import FileUpload from '../components/fileupload';
import processclaim from "../assets/processclaim.png";
import { css } from "@emotion/css";
import DataObject from "@mui/icons-material/DataObject";
import SpeedIcon from "@mui/icons-material/Speed";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { keyframes } from '@emotion/react';
import { PlayCircleFilled, } from "@mui/icons-material";
import ContactMailIcon from '@mui/icons-material/ContactMail';
const features = [
  {
    title: "Accurate Data Extraction",
    description:
      "Our advanced computer vision technology ensures accurate data extraction, eliminating errors and improving data quality.",
    icon: <DataObject sx={{ fontSize: 50, color: "#1976d2" }} />,
  },
  {
    title: "Enhanced Time Efficiency",
    description:
      "Optimize your operations with our automated data extraction technology. This solution significantly reduces manual processing time, and enhances time efficiency.",
    icon: <SpeedIcon sx={{ fontSize: 50, color: "#1976d2" }} />,
  },
  {
    title: "User-Friendly Interface",
    description:
      "Our intuitive interface makes it easy for anyone to use our data extraction solution, no technical expertise required.",
    icon: <TouchAppIcon sx={{ fontSize: 50, color: "#1976d2" }} />,
  },
];
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateY(20px);
  }
  to {
    transform: translateY(0);
  }
`;


const themeStyle = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#0d6f79",
      paper: "#0a5a60",
    },
    text: {
      primary: "#fff",
      secondary: "#b3e5fc",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    h4: {
      color: "#fff",
    },
    h6: {
      color: "#b3e5fc",
    },
    body1: {
      color: "#e0f7fa",
    },
  },
});
const GlassyCard = styled(motion.div)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.8)',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(3),
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 48px 0 rgba(31, 38, 135, 0.3)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  justifyContent: "center",
  textAlign: "center",
  width: 200,
  background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
  border: 0,
  borderRadius: 20,
  boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  color: 'white',
  height: 40,
  padding: '0 2px',
  margin: "2rem auto",
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  fontSize: '0.875rem',
  '&:hover': {
    background: 'linear-gradient(45deg, #1976D2 30%, #00BCD4 90%)',
    transform: 'scale(1.05)',
    boxShadow: '0 6px 10px 4px rgba(33, 203, 243, .4)',
  },
}));


const AnimatedTableRow = motion(TableRow);
const styles = {
  container: css`
    padding: 2rem;
    text-align: center;
    height: 70vh;
   
  `,
  fadeIn: css`
    animation: fadeIn 2s ease-in-out;
  `,
  uploading: css`
    margin-top: 2rem;
    animation: fadeIn 2s ease-in-out;
  `,
  scanningContainer: css`
    margin-top: 2rem;
    position: relative;
    width: 300px;
    height: 400px;
    background-color: #e0e0e0;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  `,
  scanningEffect: css`
    position: relative;
    width: 100%;
    height: 100%;
  `,
  scanLine: css`
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: green;
    animation: scanAnimation 2s linear infinite;
  `,
  jsonData: css`
    margin-top: 2rem;
    padding: 1rem;
    background-color: #e0f7fa;
    text-align: left;
  `,
};

const images = [LS_1, LS_2];





const LossRunReports = () => {
  const Authorization = !!localStorage.getItem("Auth") || !!sessionStorage.getItem("NonInsuredAuth");
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const [uploading, setUploading] = useState(false);
  const [scanning, setScanning] = useState(false);
  const [jsonData, setJsonData] = useState(null);
  const [isHovered, setHovered] = useState(false);
  useEffect(() => {
    const startCycle = () => {
      setUploading(true);
      setJsonData(null);

      setTimeout(() => {
        setUploading(false);
        setScanning(true);

        setTimeout(() => {
          setScanning(false);
          const data = {
            "Policy Number": "HI23001001",
            "Property Address": "123 Main Street Springfield 62701 Illinois USA",
            "Loss Date and Time": "2022-01-15",
            "Loss Location": "9573, Northwest Street, Houston, Texas,77030, USA",
            "Type of Loss": "Fire",
            "Loss Description": "Shoot circuit in my house",
            "Reported By": "John Street Singh",
            "Report Number": "FIRE3727",
            "Police/Fire Department Contacted?": "True",
            "Claim Document": "Claim Note 4 (1).pdf",
          };
          setJsonData(data);
        }, 4000); // Time for scanning effect
      }, 2000); // Time for uploading effect
    };

    startCycle(); // Start the initial cycle

    const interval = setInterval(() => {
      startCycle(); // Restart the cycle every 10 seconds
    }, 10000);

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  const checked = (val) => {
    if (!Authorization) {
      window.scrollTo(0, 0);
      localStorage.setItem("rout", val);
    } else {
      window.scrollTo(0, 0);
    }
  };

  const [randomImage, setRandomImage] = useState(null);
  useEffect(() => {
    if (scanning) {
      // Function to get a random image
      const getRandomImage = () => {
        const randomIndex = Math.floor(Math.random() * images.length);
        return images[randomIndex];
      };

      // Set the random image
      setRandomImage(getRandomImage());
    }
  }, [scanning]);





  return (
    <>
      <Header />


      <ThemeProvider theme={themeStyle}>
        <Box
          sx={{

            marginTop: "2rem",
            paddingTop: "1rem",
            paddingBottom: "2rem",
            textAlign: "center",
            backgroundColor: "#010066",
            height: "auto",

          }}

        >
          <Typography sx={{ fontSize: "2.3rem", display: "block", paddingTop: '2.4rem', color: 'white' }} className="Nasaliza"  >
          DocAI Loss Run
          </Typography>
          <Grid
            container
            spacing={4}
            sx={{ alignItems: "center", justifyContent: "center", width: '100%', maxWidth: 1200, margin: 'auto' }}
          >
            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", justifyContent: "center", animation: `${slideIn} 1s ease-in-out` }}>
                <ThemeProvider theme={theme}>
                  <style>{keyframes}</style>
                  <Box className={styles.container}>
                    <Typography variant="h6" component="h6" className={"Nasaliza"} sx={{ color: 'orange', animation: `${fadeIn} 1.5s ease-in-out` }}>
                    DocAI Loss Run
                    </Typography>
                    {uploading && (
                      <Box className={styles.uploading} sx={{ animation: `${fadeIn} 1.5s ease-in-out` }}>
                        <img
                          src={LS_upload}
                          alt="Scanning Effect"
                          style={{
                            width: '80%',
                            height: '50vh',
                            maxWidth: '100%',
                            maxHeight: '100%',
                          }}
                        />

                      </Box>
                    )}
                    {scanning && (
                      <Box sx={{ position: 'relative', width: '100%', height: 'auto', animation: `${fadeIn} 1.5s ease-in-out` }}>
                        <Box sx={{ position: 'relative', width: '100%', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {randomImage && (
                            <img
                              src={randomImage}
                              alt="Scanning Effect"
                              style={{
                                width: '100%',
                                height: '50vh',
                                maxWidth: '100%',
                                maxHeight: '100%',
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    )}
                    {jsonData && (
                      <Box className={styles.fadeIn} style={{
                        width: isMobile ? '100vw' : '100%',
                        height: 'auto',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        animation: `${fadeIn} 1.5s ease-in-out`,
                      }}>

                        <img
                          src={LS_FINAL}
                          alt="Scanning Effect"
                          style={{
                            width: '100%',
                            height: '50vh',
                            maxWidth: '100%',
                            maxHeight: '100%',
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </ThemeProvider>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} >
              <Typography variant="h4" component="h3" textAlign={'left'} className="Nasaliza" sx={{ animation: `${slideIn} 1s ease-in-out` }}>
                Intelligent Loss Run Extraction using Insur<span style={{ color: 'red', fontWeight: 'bold' }}>AI</span>.
              </Typography>

              <Box sx={{ animation: `${slideIn} 1.4s ease-in-out` }}>
                <Typography variant="body1" gutterBottom textAlign={'left'}>
                  <Box component="ul" sx={{ paddingLeft: 2 }} >
                    <Typography component="li" sx={{
                      marginBottom: 1, fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                      textAlign: 'justify',
                      hyphens: 'auto',
                      wordBreak: 'break-word',
                      '& > span': { display: 'inline-block' }
                    }}>
                      Leverage advanced computer vision and LLMs to effortlessly transform PDFs, Excel files, and images into actionable insights.
                    </Typography>
                    <Typography component="li" sx={{
                      marginBottom: 1, fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                      textAlign: 'justify',
                      hyphens: 'auto',
                      wordBreak: 'break-word',
                      '& > span': { display: 'inline-block' }
                    }}>
                      Extract and format critical data with ease or integrate seamlessly via our robust API for smooth system compatibility.
                    </Typography>
                    <Typography component="li" sx={{
                      textAlign: 'left', fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                      textAlign: 'justify',
                      hyphens: 'auto',
                      wordBreak: 'break-word',
                      '& > span': { display: 'inline-block' }
                    }}>
                      Save up to 90% of your time with our automated, highly accurate solution, ensuring precision and efficiency.
                    </Typography>
                  </Box>
                </Typography>
              </Box>

              {!Authorization ?
                <Box sx={{ mt: 2 }} textAlign={'left'}>
                  <Link
                    style={{ color: 'black' }}
                    onClick={() => checked("/login")}
                    href="/requestdemo"
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                  >
                    <StyledButtonComponent buttonWidth={200} >
                      Request for Demo
                    </StyledButtonComponent>
                  </Link>
                  <StyledButtonComponent buttonWidth={200} sx={{marginLeft:isMobile?"0rem":"10px"}}>
                    <Link href="https://www.youtube.com/watch?v=nOPripLzoLc" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                      <PlayCircleFilled sx={{ marginRight: '8px' }} />
                      Watch Video
                    </Link>
                  </StyledButtonComponent>

                </Box>
                :
                <Box sx={{ mt: 2 }} textAlign={'center'}>
                  <StyledButtonComponent buttonWidth={200}
                    onClick={() => navigate("/Demo/DocAIlossrunreport")}
                    style={{  }}
                  >
                    Demo
                  </StyledButtonComponent>
                  <StyledButtonComponent buttonWidth={200} sx={{marginLeft:isMobile?"0rem":"10px" }}>
                    <Link href="https://www.youtube.com/watch?v=nOPripLzoLc" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                      <PlayCircleFilled sx={{ marginRight: '8px' }} />
                      Watch Video
                    </Link>
                  </StyledButtonComponent>

                </Box>
              }
               <Box sx={{textAlign:'center',marginRight:isMobile?'0rem':"2rem"}}>
              <Typography
                        className="Nasaliza"
                        sx={{
                          fontWeight: 'bold',
                         
                          fontSize: '1.1rem',
                         textAlign:'center',
                          color: 'white',
                        }}
                      >
                        <ContactMailIcon sx={{ marginRight: '10px', fontSize: '1.5rem', color: 'white' }} />
                        Contact us for free POC
                        </Typography>

              </Box>
            </Grid>
          </Grid>

        </Box>
      </ThemeProvider>


      <Container
        sx={{
          height: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: isMobile ? '5rem' : '2rem',
          marginBottom: '2rem'
        }}
      >

        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Box
            sx={{
              padding: isMobile ? '20px' : '40px',
              textAlign: 'center',
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }} className='Nasaliza'>
              Automated Data Extraction for Efficient Information Processing
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Leverage our cutting-edge technology to streamline information management, reduce manual effort, and minimize errors.
            </Typography>

            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xs={12} md={5}>
                <Box
                  component="img"
                  src={Loss_Run}
                  alt="Receipt"
                  sx={{
                    maxWidth: '100%',
                    height: 'auto',
                    borderRadius: '8px',
                    boxShadow: theme.shadows[3],
                  }}
                />
              </Grid>

              <Grid item xs={12} md={1}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    fontSize: '3rem',
                  }}
                >
                  ➡️
                </Box>
              </Grid>

              <Grid item xs={12} md={5}>
                <Box
                  sx={{

                    padding: '16px',
                    borderRadius: '8px',

                    overflowX: 'auto',
                    maxWidth: '100%',
                  }}
                >
                  <Box
                    component="img"
                    src={Loss_result}
                    alt="Receipt"
                    sx={{
                      maxWidth: '100%',
                      height: 'auto',
                      borderRadius: '8px',
                      boxShadow: theme.shadows[3],
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid></Container>


      <Box sx={{ padding: { xs: 2, md: 4 }, backgroundColor: "#f0f2f5" }}>
        <Grid
          container
          spacing={4}
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%', maxWidth: 1200, margin: 'auto' }}
        >
          {/* Left Side: Text Content */}
          <Grid item xs={12} md={5}>
            <Typography variant="h4" component="h1" gutterBottom className='Nasaliza' color='blue' textAlign={'left'}>
              Streamline Loss Run Data Extraction Process with Ease
            </Typography>
            <Typography variant="subtitle1" component="p" sx={{
              fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
              textAlign: 'justify',
              hyphens: 'auto',
              wordBreak: 'break-word',
              '& > span': { display: 'inline-block' }
            }}>
              Our automated data extraction solution saves you time and effort by
              efficiently extracting key points from images or PDFs and presenting
              them in a clear and organized structured format.

            </Typography>
            <Box sx={{ textAlign: 'left', marginTop: '3rem' }}>
              {
                Authorization ? (
                  <Box>
                    <StyledButtonComponent buttonWidth={250}
                      onClick={() => navigate("/Demo/DocAIlossrunreport")}
                      style={{ marginTop: isMobile ? '16px' : '0' }}
                    >
                      Demo
                    </StyledButtonComponent>
                    <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: '280px', marginTop: '-85px' }}>
                      <Link href="https://www.youtube.com/watch?v=nOPripLzoLc" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                        <PlayCircleFilled sx={{ marginRight: '8px' }} />
                        Watch Video
                      </Link>
                    </StyledButtonComponent>
                  </Box>
                ) : (
                  <Box>
                    <StyledButtonComponent
                      buttonWidth={250}
                      onClick={() => navigate("/requestdemo")}
                    >
                      Request for Demo
                    </StyledButtonComponent>
                    <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: '280px', marginTop: '-85px' }}>
                      <Link href="https://www.youtube.com/watch?v=nOPripLzoLc" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                        <PlayCircleFilled sx={{ marginRight: '8px' }} />
                        Watch Video
                      </Link>
                    </StyledButtonComponent>
                  </Box>
                )
              }

            </Box>


          </Grid>

          {/* Right Side: Cards */}
          <Grid item xs={12} md={7}>
            <Grid container spacing={4}>
              {/* First and Second Cards */}
              {features.slice(0, 2).map((feature, index) => (
                <Grid item key={index} xs={12} sm={6}>
                  <Card
                    sx={{
                      maxWidth: 345,
                      height: '300px',
                      margin: "0 auto",
                      textAlign: "center",
                      padding: 2,
                      borderRadius: "12px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                      "&:hover": { boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.3)" },
                    }}
                  >
                    <CardContent>
                      <Box sx={{ mb: 2 }}>{feature.icon}</Box>
                      <Typography variant="h6" component="h2" className='Nasaliza' color='#010066' >
                        {feature.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" sx={{ fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif" }}>
                        {feature.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
              {/* Third Card */}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <Card
                    sx={{
                      maxWidth: 345,
                      textAlign: "center",
                      padding: 2,
                      borderRadius: "12px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                      "&:hover": { boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.3)" },
                    }}
                  >
                    <CardContent>
                      <Box sx={{ mb: 2 }}>{features[2].icon}</Box>
                      <Typography variant="h6" component="h2" className='Nasaliza' color='#010066'>
                        {features[2].title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" sx={{ fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif" }}>
                        {features[2].description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </>
  );
};



export default LossRunReports;