import React from "react";

import "./services.css";
import Header from "../components/header";
import Footer from "../components/footer";
import { Box, Grid, Typography } from "@mui/material";
import Allservices_img from "../assets/Allservices_img.webp";

import innoMain from "../assets/innoMain.webp";

export default function AllServices() {
  return (
    <>
      <Header />
      <Box className="box-1" style={{ marginTop: "150px" }}>
        {/* <Grid className="inno-container">
          <Grid className="inno-box">
          <Grid className="inno-title" component={'div'}>
            <Typography className="inno-typedText" >Innovon Tech</Typography>

            </Grid>
            <Grid component={'div'}>
                      
            <Typography className="inno-para" >
              Innovontech is a leading provider of innovative insurance
              solutions. We specialize in providing exceptional customer service
              and tailor-made insurance solutions to meet the needs of our
              clients. Our team of experts utilizes cutting-edge technology to
              provide efficient and reliable services.
            </Typography>
              </Grid>
          </Grid>
            <Grid className="inno-img-box" >
              <img src={innoMain} alt="innomain" className="innoMain-img"/>
            </Grid>
          </Grid> */}
      </Box>
      <Box className="Allservices_box">
        <Grid>
          <Grid className="service-summary">
            <Typography variant="h3">Our Services</Typography>
            {/* <Typography className="servies-head" >
            At Innovontek, we specialize in creating customized solutions for
            our clients in the insurance industry. We provide a range of
            services, including First Notice of Loss (FNOL), In-Depth FNOL
            (IDPFNOL), and AI Insurance solutions. Our team of experts works
            closely with clients to deliver tailored solutions that meet their
            unique needs. Our commitment to excellence and innovation sets us
            apart in the industry.
          </Typography> */}
          </Grid>
        </Grid>
        <Grid className="services_img">
          <img
            src={Allservices_img}
            alt="Allservices_img"
            className="serv_img"
          />
        </Grid>
        <Grid className="services-box">
          <Grid
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              marginLeft: "115px",
            }}
          >
            <Grid className="ser-card1">
              <Typography className="ser-head">
                First Notice of Loss (FNOL)
                <div style={{ border: "2px solid blue", width: "100px" }}></div>
              </Typography>
              <Typography className="ser-para">
                Our FNOL services provide clients with a comprehensive solution
                for managing claims. We use state-of-the-art technology to
                streamline the claims process, minimizing disruption and
                maximizing efficiency. Our team of experts works closely with
                clients to ensure timely and accurate reporting, facilitating
                prompt resolution of claims.
              </Typography>
            </Grid>
            <Grid className="ser-card1">
              <Typography className="ser-head">
               
              DocAI FNOL
                <div style={{ border: "2px solid blue", width: "80px" }}></div>
              </Typography>
              <Typography className="ser-para">
                Our innovative Intelligent Document Processing (DocAI FNOL)
                solutions harness the power of advanced LLM technology and AI to
                expedite document processing with unparalleled efficiency. By
                automating the claim process, we empower users to minimize their
                reliance on manual tasks, enhancing overall productivity and
                accuracy.
              </Typography>
            </Grid>
            {/* ...........................................sec-2.............................. */}
            <Grid className="ser-card1">
              <Typography className="ser-head">
                InsurAI Solutions
                <div style={{ border: "2px solid blue", width: "85px" }}></div>
              </Typography>
              <Typography className="ser-para">
                Our Al Insurance solutions leverage the latest advances in
                artificial intelligence and machine learning to deliver
                customized solutions that meet our clients' unique needs. We use
                advanced algorithms to analyze data, predict risks, and optimize
                outcomes. Our solutions are designed to be flexible, scalable,
                and adaptable to changing market conditions.
              </Typography>
            </Grid>
            <Grid className="ser-card1">
              <Typography className="ser-head">
                Customized Solutions
                <div style={{ border: "2px solid blue", width: "80px" }}></div>
              </Typography>
              <Typography className="ser-para">
                We work closely with our clients to develop customized solutions
                that meet their unique needs. Our team of experts uses a
                consultative approach, working collaboratively with clients to
                gain a deep understanding of their business, challenges, and
                opportunities. We then develop tailored solutions that deliver
                measurable results.
              </Typography>
            </Grid>
            {/* ...........................................sec-3.............................. */}
            <Grid className="ser-card1">
              <Typography className="ser-head">
                Expertise and Experience
                <div style={{ border: "2px solid blue", width: "90px" }}></div>
              </Typography>
              <Typography className="ser-para">
                Our team of experts has deep expertise and experience in the
                insurance industry. We bring a wealth of knowledge, best
                practices, and proven methodologies to every project we
                undertake. Our commitment to excellence and innovation ensures
                that we stay at the forefront of the industry, delivering
                cutting-edge solutions to our clients.
              </Typography>
            </Grid>
            <Grid className="ser-card1">
              <Typography className="ser-head">
                Client-Centered Approach
                <div style={{ border: "2px solid blue", width: "90px" }}></div>
              </Typography>
              <Typography className="ser-para">
                At Innovon Tech, we put our clients at the center of everything
                we do. We listen carefully to their needs and work
                collaboratively to develop solutions that meet their unique
                requirements. Our commitment to client satisfaction drives
                everything we do, from the initial consultation to the final
                delivery of our solutions.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* .......................................Footer............................ */}
      <Footer />
    </>
  );
}
