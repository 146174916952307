import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  Container,
  LinearProgress,
  IconButton,
  CssBaseline,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CardContent

} from "@mui/material";
// import FileUpload from "./FileUpload";
import { Card, useMediaQuery, useTheme } from '@material-ui/core';
import processclaim from "../assets/processclaim.png";
import { useNavigate } from "react-router-dom";
import FileUpload from "../components/fileupload.js";
import Header from "../components/header.js";
import Footer from "../components/footer.js";
import drops from "../assets/dropsnew.gif";
import Claims from '../assets/claim.png'
import doc_process from '../assets/doc_process.png'
import down_time from '../assets/down-time.png'
import EditIcon from '@mui/icons-material/Edit';
import IDP_video from '../assets/IDP_video.mp4'
import axios from "axios";
import ExtractScanner from "./Extract_Data/Extract_idp_data.js";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Document, Page } from "react-pdf";
import texttract from '../assets/texttract.png'
import { pdfjs } from "react-pdf";

import StyledButtonComponent from "../components/StyledButton.js";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const initialValues = {
  policy_number: "",
  claim_reported_by: "",
  loss_property: "",
  loss_date_and_time: "",
  loss_type: "",
  loss_address: "",
  loss_street: "",
  loss_city: "",
  loss_state: "",
  loss_zip: "",
  loss_country: "",
  police_fire_contacted: false,
  report_number: null,
  loss_damage_description: "",
  claim_witness_document_names: [],
  claim_witness_document_url: [],
  claim_witness_document_name_url: [],
  claim_process_document_name: [],
  claim_process_document_url: [],
  claim_process_document_name_url: [],
};

function AnimatedNumber({ number, start }) {
  const [displayNumber, setDisplayNumber] = useState(number.slice(0, -1) + "0");
  const staticPart = number.slice(0, -1);
  const targetDigit = parseInt(number.slice(-1), 10);
  useEffect(() => {
    if (!start) return;
    let currentDigit = 0;
    let incrementCount = 0;
    const interval = setInterval(() => {
      if (currentDigit === 9) {
        clearInterval(interval);
        setDisplayNumber(staticPart + "9+");
      } else if (incrementCount >= 3) {
        clearInterval(interval);
        if (parseInt(staticPart + targetDigit) >= 991) {
          setDisplayNumber("1000");
        } else {
          setDisplayNumber(staticPart + targetDigit + "+");
        }
      } else {
        currentDigit = (currentDigit + 1) % 10;
        setDisplayNumber(staticPart + currentDigit + "+");
        if (currentDigit === targetDigit) {
          incrementCount++;
        }
      }
    }, 200);
    return () => clearInterval(interval);
  }, [number, start, staticPart, targetDigit]);

  return (
    <Typography variant="h4" component="div" className="fileAnalys-animate-number">
      {displayNumber}
    </Typography>
  );
}

function AnimatedPoints({ points }) {

  return (
    <ul className="points-container">
      {points.map((point, index) => (
        <li key={index}>
          {point}
        </li>
      ))}
    </ul>
  );
}







export default function TextractFileUpload() {
  const Authorization = !!localStorage.getItem("Auth") || !!sessionStorage.getItem("NonInsuredAuth");
  const navigate = useNavigate();

  // const [missingFields, setMissingFields] = useState({});
  const [enableFields, setEnableFields] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [updateDisplay, setUpdateDisplay] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [uploadIn, setUploadIn] = useState("idp")
  const [selectedWitnessFiles, setSelectedWitnessFiles] = useState([]);
  const [filesUploadedInChild, setFilesUploadedInChild] = useState(false);
  const [selectedProcessFile, setSelectedProcessFile] = useState([]);
  const [queryvalues, setQueryvalues] = useState(initialValues);
  const [displayValues, setDisplayValues] = useState({});
  const [afterProcess, setAfterProcess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [processSubmit, setProcessSubmit] = useState(false);
  const [previews, setPreviews] = useState([]);
  const [hasEmptyOrInvalidValues, setHasEmptyOrInvalidValues] = useState(true);
  const [openFileView, setOpenFileView] = useState(false);
  const [fileContent, setFileContent] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileObjectURL, setFileObjectURL] = useState("");
  const [showFile, setShowFile] = useState(null)
  const [filePreview, setFilePreview] = useState(null);

  const displayFile = (file) => {

    console.log(file.type, file.type.startsWith("image/"));
    if (file.type === "application/pdf") {
      const reader = new FileReader();
      reader.onload = function (e) {
        setFileContent(e.target.result); // Optionally store file content if needed
        setFileType(file.type);
        setFileName(file.name);
        setFileObjectURL(null); // Reset previous object URL if exists
        setFilePreview(URL.createObjectURL(file));  // Display PDF preview directly

        setOpenFileView(true); // Open dialog automatically after file upload
      };
      reader.readAsDataURL(file);
    } else if (file.type.startsWith("image/")) {
      setFileContent(null);
      setFileType(file.type);
      setFileName(file.name);

      setFilePreview(URL.createObjectURL(file)); // Display image preview directly
      setOpenFileView(true); // Open dialog automatically after file upload
    }
    // Add more conditions for other file types as needed
  };

  const handleDialogClose = () => {
    setOpenFileView(false);
    setFileContent("");
    setFileType("");
    setFileName("");
    setFilePreview(null);
    if (fileObjectURL !== "") {
      URL.revokeObjectURL(fileObjectURL);
      setFileObjectURL("");
    }
  };
  const handleDownloadFile = () => {
    const link = document.createElement("a");
    link.href = fileContent;
    link.download = fileName;
    link.click();
  };
  //files display
  // const [selectedProcessFile, setSelectedProcessFile] = useState([]);
  //   const [selectedWitnessFiles, setSelectedWitnessFiles] = useState([]);

  const [numPages, setNumPages] = useState(null);


  const handleUploadProcessDocument = async () => {
    setLoader(true);
    try {
      const file = selectedProcessFile[0];
      const formData = new FormData();
      formData.append('file', file);
      const responseData = await getExtractedDocumentJson(formData);
      if (responseData) {
        const extractedResponseData = responseData.extracted_data;
        const insurance_company_claim_storage_point = responseData.insurance_company_data.claim_storage_type;
        setQueryvalues(extractedResponseData);
        setQueryvalues(prevState => ({
          ...prevState,
          claim_storage_type: insurance_company_claim_storage_point,
        }));
        // const mandatoryFields = [
        //   "policy", "address", "loss_date_and_time", "loss_location", "loss_type", "loss_damage_description", "claim_reported_by",
        //   "police_fire_contacted", "report_number",
        // ];

        // function formatFieldName(field) {
        //   return field
        //     .split('_')
        //     .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        //     .join(' ');
        // }

        // const missing = mandatoryFields.reduce((acc, field) => {
        //   if (!extractedResponseData[field]) {
        //     acc[field] = {
        //       value: '',
        //       error: `${formatFieldName(field)} required`
        //     };
        //   }
        //   return acc;
        // }, {});

        // setMissingFields(missing);
        const displayExtractedData = mapResponseToDisplayFields(extractedResponseData);
        setDisplayValues(displayExtractedData);
        setAfterProcess(true);
        setLoader(false);
      } else {
        console.error("API request did not return expected data.");
      }
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  const getExtractedDocumentJson = async (formData) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}AI/process_file/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      localStorage.setItem("user", JSON.stringify(response.data.policy_data));
      localStorage.setItem("company", JSON.stringify(response.data.insurance_company_data));
      return response.data;
    } catch (error) {
      setErrorMessage(error.response.data.message)
      setLoader(false);
      return null;
    }
  };

  const mapResponseToDisplayFields = (extractedResponseData) => {
    return {
      "Policy Number": extractedResponseData.policy_number,
      "Reported By": extractedResponseData.claim_reported_by,
      "Property Address": extractedResponseData.address,
      "Loss Date and Time": extractedResponseData.loss_date_and_time,
      "Type of Loss": extractedResponseData.loss_type,
      "Loss Location": extractedResponseData.loss_location,
      "Police/Fire Department Contacted?": extractedResponseData.police_fire_contacted ? 'True' : 'False',
      "Report Number": extractedResponseData.report_number,
      "Loss Damage Description": extractedResponseData.loss_damage_description,
      "Claim Document": queryvalues.claim_process_document_name
    };
  };

  const handleProcessFileRemove = () => {
    setQueryvalues(initialValues);
    setAfterProcess(false);
    setSelectedProcessFile([]);
    // setSelectedWitnessFiles([]);
    setDisplayValues({});
    setHasEmptyOrInvalidValues(true);
    setErrorMessage("");
    // setMissingFields({})
    setEnableFields(false)
    setIsSaved(false)
    setUpdateDisplay(false)

    setFilePreview(null);

  };

  const handlewitnessFileRemove = (fileName) => {
    console.log(fileName);
    setSelectedWitnessFiles((prevFiles) => [
      ...prevFiles.filter((file) => file.name !== fileName),
    ]);
  }

  const handleWitnessFilesUploadToAWSByIDP = (selectedFiles, previews) => {
    setSelectedWitnessFiles(selectedFiles);
    const claimWitnessDocumentNames = selectedFiles.map(file => file.name);
    setQueryvalues(prevState => ({
      ...prevState,
      claim_witness_document_names: claimWitnessDocumentNames,
    }));
  }

  const handleExtractClaimSubmit = (displayValues, queryvalues) => {
    console.log(displayValues, queryvalues);
    setProcessSubmit(true)
    const formData = new FormData();
    formData.append('policy_number', displayValues["Policy Number"] || queryvalues.policy);
    formData.append('loss_date_and_time', displayValues["Loss Date and Time"] || queryvalues.loss_date_and_time);
    formData.append('loss_type', displayValues["Type of Loss"] || queryvalues.loss_type);
    formData.append('loss_property', displayValues["Property Address"] || queryvalues.address);
    formData.append('loss_damage_description', displayValues["Loss Damage Description"] || queryvalues.loss_damage_description);
    formData.append('loss_address', queryvalues.street_number ?? displayValues["Loss Location"]);
    formData.append('loss_street', queryvalues.street_name);
    formData.append('loss_city', queryvalues.loss_city);
    formData.append('loss_state', queryvalues.loss_state);
    formData.append('loss_zip', queryvalues.loss_zip);
    formData.append('loss_country', queryvalues.loss_country);
    formData.append('police_fire_contacted', displayValues["Police/Fire Department Contacted?"] || queryvalues.police_fire_contacted);
    formData.append('report_number', displayValues["Report Number"] || queryvalues.report_number);
    formData.append('claim_reported_by', displayValues["Reported By"] || queryvalues.reported_by || queryvalues.claim_reported_by);
    formData.append('claim_storage_type', queryvalues.claim_storage_type);

    for (let i = 0; i < selectedProcessFile.length; i++) {
      formData.append('process_document', selectedProcessFile[i]);
    }

    for (let i = 0; i < selectedWitnessFiles.length; i++) {
      formData.append('witness_documents', selectedWitnessFiles[i]);
    }
    submitClaimDetails(formData);
  }

  const submitClaimDetails = async (formData) => {
    await axios.post(`${process.env.REACT_APP_URL}AI/add-idp-claim/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        setProcessSubmit(false);
        localStorage.setItem("claimID", JSON.stringify(response.data));
        navigate('/claimsuccess');
        setSelectedProcessFile([]);
        selectedWitnessFiles([]);
        setQueryvalues(initialValues);
        setDisplayValues({})
        // setMissingFields({})

      }).catch(error => {
        console.log(error);
        setProcessSubmit(false);
        if (error.response) { // Check if there is a response from the server
          setErrorMessage(error.response.statusText); // Access statusText if available
        } else if (error.message) { // If there's no response, check for an error message
          setErrorMessage(error.message);
        } else {
          setErrorMessage("An error occurred. Please try again later.");
        }
      });
  };

  // const handleInputChange = (field, value) => {
  //   console.log(field, value);
  //   setMissingFields(prevFields => ({
  //     ...prevFields,
  //     [field]: { ...prevFields[field], value: value, error: '' }
  //   }));
  //   setQueryvalues(prevValues => ({
  //     ...prevValues,
  //     [field]: value
  //   }));
  // };
  const handleInputChange = (field, value) => {
    console.log(field, value);
    setDisplayValues(prevValues => ({
      ...prevValues,
      [field]: value
    }));
    setQueryvalues(prevValues => ({
      ...prevValues,
      [field.toLowerCase().replace(/ /g, '_')]: value // Ensure field names match 
    }));
  };
  const handleSave = () => {
    console.log(Object.values(displayValues).some(value => !value));
    console.log(displayValues);
    setEnableFields(false);
    // setUpdateDisplay(true);


    // setDisplayValues((prevDisplayValues) => {
    //   const updatedDisplayValues = { ...prevDisplayValues };
    //   for (const field in missingFields) {
    //     const displayKey = field
    //       .split("_")
    //       .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    //       .join(" ");
    //     updatedDisplayValues[displayKey] = missingFields[field].value;
    //   }
    //   check(updatedDisplayValues);
    //   return updatedDisplayValues;
    // });
  };

  const check = (updatedDisplayValues) => {
    if (!(Object.values(updatedDisplayValues).some(value => !value))) {
      setIsSaved(true);
      setEnableFields(false);
      setUpdateDisplay(true);
    }

  };
  // console.log(displayValues, queryvalues);

  const handleFilesUploadToAWSByIDP = (selectedFiles, previews) => {
    setSelectedProcessFile(selectedFiles);
    setQueryvalues((prevValue) => ({ ...prevValue, claim_process_document_name: selectedFiles[0].name }));
    displayFile(selectedFiles[0]);
  }
  useEffect(() => {
    setHasEmptyOrInvalidValues(Object.values(displayValues).some(value => !value));
  }, [updateDisplay]);




  //files...
  // const displayFile = (file) => {
  //   if (file.type === "application/pdf") {
  //     const reader = new FileReader();
  //     reader.onload = function (e) {
  //         setFileContent(e.target.result); // Optionally store file content if needed
  //       setFileType(file.type);
  //       setFileName(file.name);
  //       setFileObjectURL(null); // Reset previous object URL if exists
  //       setFilePreview(e.target.result); // Display PDF preview directly
  //       setOpenFileView(true);
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     setFilePreview(URL.createObjectURL(file));
  //   }
  // };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    return () => {
      if (filePreview && typeof filePreview === "string") {
        URL.revokeObjectURL(filePreview);
      }
    };
  }, [filePreview]);
  //.......................................
  useEffect(() => {
    setHasEmptyOrInvalidValues(Object.values(displayValues).some(value => !value));
  }, [displayValues]);

  const [isVisible, setIsVisible] = useState([false, false, false]);
  const gridRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    const observerOptions = {
      threshold: 0.5,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = parseInt(entry.target.dataset.index);
          setIsVisible((prev) => {
            const newState = [...prev];
            newState[index] = true;
            return newState;
          });
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    gridRefs.forEach((ref, index) => {
      if (ref.current) {
        ref.current.dataset.index = index;
        observer.observe(ref.current);
      }
    });

    return () => {
      gridRefs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [gridRefs]);
  // .....................................
  const [startAnimation, setStartAnimation] = useState(false);
  const [videoWidth, setVideoWidth] = useState(560); // Initial video width
  const [videoHeight, setVideoHeight] = useState(315); // Initial video height

  useEffect(() => {
    // Trigger animation when component mounts
    setStartAnimation(true);
    // Update video dimensions on window resize
    function handleResize() {
      setVideoWidth(window.innerWidth > 768 ? 560 : 320); // Adjust width based on screen size
      setVideoHeight(window.innerWidth > 768 ? 315 : 180); // Adjust height based on screen size
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //render upload

  const [isUploadClicked, setIsUploadClicked] = useState(false);
  const UploadDocument = useRef(null)

  const handleUploadButtonClick = async () => {

    await setIsUploadClicked(true);
    scrollToGrid();
  };

  const scrollToGrid = () => {
    if (UploadDocument.current) {
      UploadDocument.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('Footer reference is null');
    }
  };
  const [progress, setProgress] = useState(0);

  const handleProgress = (value) => {
    setProgress(value);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const allowedFormatsRef = useRef([
    'png', 'jpg', 'jpeg', 'pdf', 'docx', 'doc', 'txt', 'mp4',
    'image/png', 'image/jpeg', 'image/jpg',
    'application/pdf',
    'application/docx',
    'application/msword',
    'text/plain',
    'video/mp4'
  ]);
  return (
    <>
      <Header />
      <Box sx={{ marginTop: "120px", marginBottom: "80px" }}>
        <h1
          className="Nasaliza"
          style={{ textAlign: "center", wordSpacing: "15px" }}
        >
          Intelligent Document Processing
        </h1>
        <Grid md={5.5}></Grid>
        {/* .................................................................FILESA Analysis ........................................................ */}

        <Container maxWidth="lg" className="fileAnalys-container">

          <Grid container spacing={3} justifyContent="center">
            {[
              { img: Claims, title: "Processed Documents", number: "1000" },
              { img: down_time, title: "Hours Reduced", number: "750" },
              { img: doc_process, title: "Processed Claims", number: "500" },
            ].map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} ref={gridRefs[index]}>
                <Box className="fileAnalys-grid">
                  <img src={item.img} alt={item.title} />
                  <Typography variant="h6" component="h5" className="Nasaliza">
                    {item.title}
                  </Typography>
                  {/* <Typography variant="body1" component="p">
                {item.description}
              </Typography> */}
                  <AnimatedNumber number={item.number} start={isVisible[index]} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>





        {/* ................................................................................ */}
        <Grid container className="pv-container" spacing={2} style={{ padding: '16px' }}>
          <Grid item xs={12} className="pv-content">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} className="video-container">
                <video
                  width={isMobile ? '100%' : videoWidth}
                  height={isMobile ? 'auto' : videoHeight}
                  controls
                  style={{ maxWidth: '100%' }}
                >
                  <source src={IDP_video} type="video/mp4" />
                  browser does not support the video tag.
                </video>
              </Grid>
              <Grid item xs={12} md={6} className="points-container" >
                <AnimatedPoints
                  points={[
                    "Achieve zero-touch insurance submission intake, claims, and policy administration with automated workflows.",
                    "Reduce operational costs and document processing cycle times with intelligent automation.",
                    "Boost productivity and efficiency while providing a superior user experience.",
                    "Ensure uninterrupted business process continuity and unclutter insurance software systems.",
                    "Safeguard Personally Identifiable Information (PII) and meet comprehensive compliance requirements effortlessly.",

                  ]}
                  startAnimation={startAnimation}
                />
                <Grid container justifyContent={isMobile ? 'center' : 'flex-start'} style={{ marginTop: '16px', marginLeft: '20px' }}>
                  {
                    Authorization ? (
                      <StyledButtonComponent buttonWidth={250}
                        onClick={handleUploadButtonClick}
                        style={{ marginTop: isMobile ? '16px' : '0' }}
                      >
                        Upload Document
                      </StyledButtonComponent>
                    ) : (
                      <StyledButtonComponent
                        buttonWidth={250}
                        onClick={() => navigate("/requestdemo")}
                      >
                        Request for Demo
                      </StyledButtonComponent>
                    )
                  }

                  {/* <StyledButtonComponent buttonWidth={250}
                    onClick={handleUploadButtonClick}
                    style={{ marginTop: isMobile ? '16px' : '0' }}
                  >
                    Upload Document
                  </StyledButtonComponent> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>








        {/* ........................................... */}

        {isUploadClicked && (
          <div ref={UploadDocument} >

            <Grid container id="grid" >
              <Grid container style={{ display: "flex", justifyContent: "space-between" }}>
                <Grid item md={6} className="floderuplod-box">
                  {!afterProcess && (
                    <>
                      <Typography
                        style={{
                          fontSize: "1.1rem",
                          color: "#010066",
                          fontWeight: "bold",
                          marginTop: "6%",
                          textAlign: 'center'
                        }}
                      >
                        Upload document to file a Claim
                      </Typography>


                      <FileUpload
                        id="idp"
                        onFilesUpload={handleFilesUploadToAWSByIDP}
                        multiple={false}
                        onFileRemove={handleProcessFileRemove}
                        selectedFilesInParent={selectedProcessFile}
                        allowedFormats={allowedFormatsRef.current} // Use allowedFormatsRef.current here
                        filesUploadedInChild={filesUploadedInChild}
                        setIsSubmitDisabled={setIsSubmitDisabled}
                        uploadIn={uploadIn}
                      />
                    </>
                  )}
                  {afterProcess && (


                    <>
                      <Typography
                        style={{
                          fontSize: "1.2rem",
                          color: "#010066",
                          fontWeight: "bold",
                          textAlign: 'center'
                        }}
                      >
                        Uploaded Document.
                      </Typography>
                      <Grid style={{ border: '1px solid blue', maxWidth: 600, margin: "auto", marginTop: "20px", padding: "10px" }}>
                        <Card variant="outlined" sx={{ border: "1px solid blue", height: '250px' }} >
                          <CardContent>
                            <Typography variant="h6" component="h2">
                              {fileName}
                            </Typography>
                            {fileType.startsWith("image/") && (
                              <img
                                src={filePreview}
                                alt={fileName}
                                style={{ maxWidth: "100%", height: "auto" }}
                              />
                            )}
                            {fileType === "application/pdf" && (
                              <iframe
                                title={fileName}
                                src={filePreview}
                                width="100%"
                                height="600px"
                              />
                            )}

                            <Box mt={2}>
                              <StyledButtonComponent buttonWidth={250}
                                onClick={() => {
                                  handleProcessFileRemove()
                                  setAfterProcess(false)
                                }}>
                                Upload Document
                              </StyledButtonComponent>
                            </Box>
                          </CardContent>
                        </Card>

                      </Grid>
                    </>
                  )}

                  <Typography
                    style={{
                      fontSize: "1.1rem",
                      color: "#010066",
                      fontWeight: "bold",
                      marginTop: "6%",
                      textAlign: 'center'

                    }}
                  >
                    Upload claim supporting documents.
                  </Typography>

                  {/* <FileUpload
                    id="portal"
                    onFilesUpload={handleWitnessFilesUploadToAWSByIDP}
                    multiple={true}
                    allowedFormats={[
                      "png",
                      "jpg",
                      "jpeg",
                      "plain",
                      "pdf",
                      "vnd.openxmlformats-officedocument.wordprocessingml.document",
                      "mp4",
                    ]}
                    setIsSubmitDisabled={setIsSubmitDisabled}
                    selectedFilesInParent={selectedWitnessFiles}
                    filePreviews={previews}
                    filesUploadedInChild={filesUploadedInChild}
                    uploadIn={uploadIn}
                    onFileRemove={handlewitnessFileRemove}
                  /> */}
                  <Grid style={{ textAlign: "center" }}>
                    <StyledButtonComponent buttonWidth={200}
                      // backgroundColor="#FF5733"
                      disableColor="#CCCCCC"

                      sx={{ marginTop: "10%", marginBottom: '25px' }}
                      onClick={handleUploadProcessDocument}
                      disabled={
                        selectedProcessFile.length === 0 ||
                        loader === true ||
                        afterProcess === true
                      }
                    >
                      <img
                        src={processclaim}
                        alt="process and claim icon"
                        style={{
                          height: 38,
                          paddingRight: 10,

                        }}
                      />
                      Process
                    </StyledButtonComponent>
                  </Grid>
                </Grid>
                <Grid item md={6} margin={isMobile ? "0rem 1rem " : "0rem 0rem"}>
                  <Grid container>
                    <Grid item md={2.5}></Grid>
                    <Grid className="idp-fetch-container">
                      <Typography
                        style={{
                          fontSize: "1.2rem",
                          color: "#010066",
                          fontWeight: "bold",
                          textAlign: 'center',
                          margin: "0.9rem 0rem"
                        }}

                      >
                        Extracted claim FNOL details.
                      </Typography>
                      {hasEmptyOrInvalidValues && (
                        <Typography style={{ color: "red", marginBottom: "10px", textAlign: 'center' }}>
                          Please provide mandatory details in the document to claim
                          the policy.
                        </Typography>
                      )}
                      {afterProcess === true ? (

                        <Grid className="fetch-idp-data">

                          {!updateDisplay ? (
                            <>

                              <Typography variant="h5" className="ipd-titles Nasaliza">Policy Number</Typography>
                              <Grid container spacing={2} style={{ marginBottom: '7px', marginLeft: isMobile ? '0' : '15px' }}>
                                {Object.entries(displayValues)
                                  .filter(([key]) => key === "Policy Number" || key === "Property Address" || key === "Claim Document")
                                  .map(([key, value]) => (
                                    <React.Fragment key={key}  >
                                      <Grid item xs={5} sm={5} md={5} style={{ fontWeight: 550, fontSize: 13, textAlign: "left" }}>
                                        {key}
                                      </Grid>
                                      <Grid item xs={1} sm={1} md={1.5} style={{ textAlign: "left" }}>
                                        :
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={5.5} style={{ textAlign: "left" }}>
                                        <span style={{ fontWeight: 500, fontSize: 13, }}>
                                          {value ? (
                                            value
                                          ) : (
                                            <span style={{ color: "red", fontWeight: 500, fontSize: 13 }}>
                                              {`${key} required`}
                                            </span>
                                          )}
                                        </span>
                                      </Grid>
                                    </React.Fragment>
                                  ))}
                              </Grid>
                              <Typography variant="h5" className="ipd-titles Nasaliza">Loss Details</Typography>
                              <Grid container spacing={2} style={{ marginBottom: '7px', marginLeft: isMobile ? '0' : '15px' }}>
                                {Object.entries(displayValues)
                                  .filter(([key]) => ["Loss Date and Time", "Loss Location", "Type of Loss", "Loss Damage Description"].includes(key))
                                  .map(([keys, value]) => (
                                    <React.Fragment key={keys}>
                                      <Grid item xs={5} sm={5} md={5} style={{ fontWeight: 550, fontSize: 13, textAlign: "left" }}>
                                        {keys}
                                      </Grid>
                                      <Grid item xs={1} sm={1} md={1.5} style={{ textAlign: "left" }}>
                                        :
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={5.5} style={{ textAlign: "left" }}>
                                        {enableFields ?
                                          (
                                            <TextField
                                              sx={{
                                                '& .MuiOutlinedInput-root': {
                                                  height: '35px',
                                                  backgroundColor: "none"
                                                },
                                              }}
                                              variant="outlined"
                                              required
                                              name={keys}
                                              value={displayValues[keys]}
                                              onChange={(e) => handleInputChange(keys, e.target.value)}
                                            />
                                          ) : (
                                            <span style={{ fontWeight: 500, fontSize: 13 }}>
                                              {value ? (
                                                value
                                              ) : (
                                                <span style={{ color: "red", fontWeight: 500, fontSize: 13 }}>
                                                  {`${keys} required`}
                                                </span>
                                              )
                                              }
                                            </span>
                                          )
                                        }
                                      </Grid>
                                    </React.Fragment>
                                  ))}
                              </Grid>
                              <Typography variant="h5" className="ipd-titles Nasaliza">Report Details</Typography>
                              <Grid container spacing={2} style={{ marginBottom: '7px', marginLeft: isMobile ? '0' : '15px' }}>
                                {Object.entries(displayValues)
                                  .filter(([key]) => ["Reported By", "Report Number", "Police/Fire Department Contacted?"].includes(key))
                                  .map(([key, value]) => (
                                    <React.Fragment key={key}>
                                      <Grid item xs={5} sm={5} md={5} style={{ fontWeight: 550, fontSize: 13, textAlign: "left" }}>
                                        {key}
                                      </Grid>
                                      <Grid item xs={1} sm={1} md={1.5} style={{ textAlign: "left" }}>
                                        :
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={5.5} style={{ textAlign: "left" }}>
                                        {enableFields ? (
                                          <TextField
                                            sx={{
                                              '& .MuiOutlinedInput-root': {
                                                height: '35px',
                                                backgroundColor: "none"
                                              },
                                            }}
                                            variant="outlined"
                                            required
                                            name={key}
                                            value={displayValues[key]}
                                            onChange={(e) => handleInputChange(key, e.target.value)}
                                          />
                                        ) : (
                                          <span style={{ fontWeight: 500, fontSize: 13 }}>
                                            {value ? (
                                              value
                                            ) : (
                                              <span style={{ color: "red", fontWeight: 500, fontSize: 13 }}>
                                                {`${key} required`}
                                              </span>
                                            )}
                                          </span>
                                        )}
                                      </Grid>
                                    </React.Fragment>
                                  ))}
                              </Grid>

                            </>

                          ) : (
                            <></>
                          )}

                          <Grid container justifyContent="center" alignItems="center" style={{ margin: "3rem 1px" }} spacing={1}>
                            {/* Added spacing={1} here */}
                            <Grid item> {/* Wrap "Save/Edit" button in a Grid item */}
                              {enableFields ? (
                                <StyledButtonComponent buttonWidth={100}
                                  onClick={handleSave}
                                >
                                  Save
                                </StyledButtonComponent>
                              ) : (
                                <StyledButtonComponent buttonWidth={100}
                                  onClick={() => setEnableFields(true)}
                                  startIcon={<EditIcon />}
                                >
                                  Edit
                                </StyledButtonComponent>
                              )}
                            </Grid>

                            <Grid item> {/* Wrap "Submit Claim" button in a Grid item */}
                              <StyledButtonComponent buttonWidth={150} disableColor={"#B6E3FF"}
                                onClick={() => handleExtractClaimSubmit(displayValues, queryvalues)}
                                disabled={hasEmptyOrInvalidValues || enableFields}>
                                Submit Claim
                              </StyledButtonComponent>
                            </Grid>

                            {/* Backdrop doesn't need a separate Grid item for spacing control */}
                            <Backdrop
                              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                              open={processSubmit}
                            >
                              <CircularProgress color="inherit" />
                            </Backdrop>
                          </Grid>


                        </Grid>

                      ) : (
                        <Grid style={{ marginTop: "3%" }}>
                          <Typography style={{ marginBottom: "2%", textAlign: "center" }}>
                            Claim details will be visible here...After Document Process!!
                          </Typography>
                          {
                            !loader && !errorMessage && <>
                              <Grid
                                sx={{
                                  border: "0.5px solid blue",
                                  boxShadow: 5,
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                                style={{ width: '100%', margin: "auto" }}>
                                <img src={texttract} alt="img" style={{ width: '100%' }} />
                              </Grid>
                            </>
                          }
                          {loader ? (
                            <Typography style={{ textAlign: "center" }}>
                              {filePreview && (
                                <div className="document-container">
                                  <div className={selectedProcessFile[0]?.type !== "application/pdf" ? "document-wrapper" : "pdf-document-wrapper"}>
                                    {selectedProcessFile[0]?.type === "application/pdf" ? (
                                      <Document
                                        file={filePreview}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        className="pdf-document"
                                      >
                                        <Page pageNumber={1} width={300} />
                                      </Document>
                                    ) : (
                                      <img src={filePreview} alt="File preview" className="document" width={300} height={500} />
                                    )}
                                    {numPages > 1 && (
                                      <Typography style={{ textAlign: "center", fontSize: "0.8rem" }}>
                                        Page 1 of {numPages}
                                      </Typography>
                                    )}
                                    {filePreview &&
                                      <>
                                        <div className="idp-scan-line idp-top"></div>
                                        <div className="idp-scan-line idp-bottom"></div>
                                      </>
                                    }
                                  </div>
                                </div>
                              )}
                            </Typography>
                          ) : (
                            <Typography style={{ textAlign: "center", color: "red" }}>
                              {errorMessage ? errorMessage : null}
                            </Typography>
                          )
                          }
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </Box >
      <Footer />
    </>
  );
}
