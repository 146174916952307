import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Link,
  Button,
  Typography,
  useTheme,
  useMediaQuery, Container, Grid, Card, CardContent, CssBaseline, IconButton
} from "@mui/material";

import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Business, Email, Assistant, Cloud, Settings, Dashboard, } from "@mui/icons-material";
import {
  PlayCircleFilled,
  SmartToy,
  DocumentScanner,
  Web,
  AutoAwesome,
  SupportAgent, Assessment,
  Language,
  Autorenew,
  ChatBubble
} from "@mui/icons-material";
import { styled } from '@mui/system';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from "../components/header.js";
import Footer from "../components/footer.js";
import IDP_IMG from "../assets/IDP_IMG.png";
import Email_To_Fnol_IMG from "../assets/Email_To_Fnol_IMG.png";
import Loss_Run_Report_img from "../assets/Loss_Run_Report_img.png";
import InsurAI_IMG from "../assets/InsurAI_IMG.png";
import StyledButtonComponent from "../components/StyledButton.js";
import { motion } from 'framer-motion';
import Generative_AI_img1 from "../assets/Generative_AI_img1.png";
import LLMS_IMG2 from "../assets/LLMS_IMG2.png";
import Computer_Vision_img3 from "../assets/Computer_Vision_img3.png";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Home_1 from "../assets/Home_1.png";
import { useNavigate } from "react-router-dom";
import Insur_AI_Agent from "../assets/InsurAI_Agent.png";
import Google_AI from "../assets/Google_AI.png";
import Secure_AI from "../assets/Secure_AI.png";
import AWS_AI from "../assets/AWS_AI.png";
import Email_policyIntake from "../assets/Email_policyIntake.png";
import idp_intakePolicy from "../assets/idp_intakePolicy.png";
import IDPFNOL from "../assets/IDPFNOL.png";
import policyIDP from "../assets/policyIDP.png";
import IDPID1 from "../assets/IDP_ID_1.png";
import Loss_Runs from '../assets/Loss_Runs.png'
import MedBill from "../assets/MedBill.png";
import AdminPlatform from "../assets/AdminPlatform.png";
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { AssignmentTurnedIn, CreditCard, ReceiptLong, DescriptionOutlined, BarChart, DataUsage, BusinessCenter } from '@mui/icons-material';


const claims = [
  "Intelligent Document Processing",
  "Email To FNOL",
  "InsurAI",
  "IDP Loss Run",
];
const slides = [
  {
    title: "Unlock the Future of P&C Insurance with InsurAI solutions.",
    description: "Experience the Next Generation of Insurance Innovation!",
    description2: "Our advanced AI solutions are designed to transform your operations and elevate your customer experience.",
    img: Home_1,
    url: "https://www.youtube.com/watch?v=ch8awerMN30&t=3s"
  },
  {
    intro: "Introducing",
    intro2: "IVAN",
    title: "The P & C Insurance AI Virtual Assistant",
    description: "Your 24/7 AI-powered virtual assistant for seamless FNOL, Policy Intake, Claim Management, and Billing Support. Experience insurance reimagined.",
    img: Insur_AI_Agent,
    url: 'https://www.youtube.com/watch?v=BKCuMFq1Vus'
  }
];


const features = [
  {
    title: "InsurAI",
    description:
      "Revolutionize your business with our cutting-edge insurance AI solutions.",
    icon: <SmartToy sx={{ fontSize: 48, color: "#0B70FF" }} />,
  },
  {
    title: "DocAI",
    description:
      "Streamline and automate your document workflows from Claim FNOL to Submission and beyond.",
    icon: <DocumentScanner sx={{ fontSize: 48, color: "#0B70FF" }} />,
  },
  {
    title: "AI-Powered Digital Portals",
    description:
      "Enhance client interactions with seamless, intelligent digital experiences.",
    icon: <Web sx={{ fontSize: 48, color: "#0B70FF" }} />,
  },
  {
    title: "InsurAdmin Platform",
    description:
      "InsurAdmin Platform centralizes policy, claim, and agent management with real-time insights and analytics.",
    icon: <Assessment sx={{ fontSize: 48, color: "#0B70FF" }} />,
  },
  {
    title: "IVAN",
    title2: "The P&C Insurance AI Virtual Assistant",
    description:
      "Leverage the expertise of our AI virtual assistant to provide exceptional service and human-like support.",
    icon: <SupportAgent sx={{ fontSize: 48, color: "#0B70FF" }} />,
  },
  {
    title: "Advanced Language Models (LLMs)",
    description:
      "Utilize sophisticated AI language models to understand and respond to customer needs effectively.",
    icon: <ChatBubble sx={{ fontSize: 48, color: "#0B70FF" }} />,
  },
];

const AnimatedSection = ({ children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 }
      }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};

const AnimatedImage = ({ src, alt }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.img
      ref={ref}
      src={src}
      alt={alt}
      style={{ width: '100%', maxWidth: '800px' }}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0, scale: 0.8 }
      }}
      transition={{ duration: 0.5 }}
    />
  );
};

const FuturisticCard = styled(Card)(({ theme }) => ({
  height: '250px',
  background: 'linear-gradient(135deg, #1e3c72 0%, #2a5298 100%)',
  backdropFilter: "blur(10px)",
  border: "1px solid rgba(11, 112, 255, 0.3)",
  borderRadius: "15px",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "scale3d(1.05, 1.05, 1)",
    boxShadow: "0 8px 32px 0 rgba(11, 112, 255, 0.37)",
  },
}));

const FuturisticButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(45deg, #010066 30%, #0B70FF 90%)",
  borderRadius: "25px",
  boxShadow: "0 3px 5px 2px rgba(11, 112, 255, .3)",
  color: "white",
  height: 50,
  padding: "10px 30px",
  textTransform: "none", // Capitalize the first letter of each word
  "&:hover": {
    background: "linear-gradient(45deg, #0B70FF 30%, #010066 90%)",
  },
}));

const FuturisticTypography = styled(Typography)(({ theme }) => ({
  fontFamily: '"Orbitron", sans-serif',
  color: "#0B70FF",
  textShadow: "0 2px 5px rgba(11, 112, 255, 0.7)",
}));
const solutions = [
  { id: '/InsurAI', name: 'IVAN', description: 'AI-powered virtual assistant for insurance operations', icon: AutoAwesome },
  { id: '/DocAI/FNOL', name: 'DocAI FNOL', description: 'Intelligent Document Processing for FNOL', icon: DocumentScanner },
  { id: '/DocAIQuote', name: 'DocAI Quote', description: 'Streamlined policy submission processing', icon: AssignmentTurnedIn },
  { id: '/Mail2Loss', name: 'Mail2Loss', description: 'Automated email analysis for claims', icon: Email },
  { id: '/Mail2Quote', name: 'Mail2Quote', description: 'Automated email processing for policy submissions', icon: Email },
  { id: '/DocAI/IDCardExtraction', name: 'DocAI ID Card', description: 'Intelligent processing of ID cards', icon: CreditCard },
  { id: '/DocAIMedBill', name: 'DocAI Med Bill', description: 'Automated medical bill processing', icon: ReceiptLong },
  { id: '/DocAIlossrunreport', name: 'DocAI Loss Run', description: 'Streamlined DocAI loss run extraction', icon: BarChart },
  { id: '/InsurAdminPlatform', name: 'InsurAdmin Platform', description: 'Integrated platform for carrier administration and operations.', icon: Dashboard },
];

const Servicetypes = () => {
  document.title = 'Innovon Tech-Home Page'
  const [claimText, setClaimText] = useState(claims[0]);
  const [animation, setAnimation] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isScreen = useMediaQuery('(max-width:880px)');
  // const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  localStorage.setItem('rout', '/')
  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      setAnimation(true);
      setTimeout(() => {
        currentIndex = (currentIndex + 1) % claims.length;
        setClaimText(claims[currentIndex]);
        setAnimation(false);
      }, 500); // Match the duration of the CSS animation
    }, 3000);

    return () => clearInterval(interval);
  }, []);
  const settings = {

    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <ArrowForwardIcon />,
    prevArrow: <ArrowBackIcon />,



  };
  const containerStyle = {
    height: "100vh",
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#010066",
    padding: "0px 32px",

    textAlign: isMobile ? "center" : "left",
    color: "#FFFFFF",
  };

  const contentStyle = {
    maxWidth: isMobile ? "100%" : "50%",
    marginBottom: isMobile ? "32px" : "0",
  };

  const demoButtonStyle = {
    backgroundColor: "#BA68C8",
    color: "#FFFFFF",
    marginTop: "16px",
  };

  const videoContainerStyle = {
    position: "relative",
    borderRadius: "8px",
    overflow: "hidden",
    width: isMobile ? "100%" : "auto",
    maxWidth: "50%", // Added maxWidth to ensure proper alignment
  };

  const videoStyle = {
    width: "100%",
    height: "auto",
    borderRadius: "8px",
  };

  const playIconStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "3rem",
    color: "#FFFFFF",
    cursor: "pointer",
  };

  const handlePlay = () => {
    const video = document.getElementById("videoElement");
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  const HoverCard = ({ children }) => (
    <motion.div
      whileHover={{ scale: 1.05, boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.2)' }}
      transition={{ type: 'spring', stiffness: 300 }}
    >
      {children}
    </motion.div>
  );

  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };
  const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
    },
  }));

  const IconWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  }));

  const ReadMoreLink = styled('span')(({ theme }) => ({
    color: theme.palette.primary.main,
    cursor: 'pointer',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '0',
      height: '2px',
      bottom: '-2px',
      left: '0',
      backgroundColor: theme.palette.primary.main,
      transition: 'width 0.3s ease-in-out',
    },
    '&:hover::after': {
      width: '100%',
    },
  }));
  const [activeDotIndex, setActiveDotIndex] = useState(0); // Track the active dot index

  // Slider settings with custom dot style
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    beforeChange: (oldIndex, newIndex) => setActiveDotIndex(Math.floor(newIndex / 3)),  // Update the active dot index before the slide changes
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: i === activeDotIndex ? "#0B70FF" : "#ccc", // Blue for active dot, grey for inactive dots
          margin: "0 8px",
          boxShadow: i === activeDotIndex ? "0px 0px 10px rgba(11, 112, 255, 0.5)" : "none",
          transition: "background-color 0.3s, transform 0.3s",
          transform: i === activeDotIndex ? "scale(1.2)" : "scale(1)",
        }}
      />
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };



  return (
    <>
      <Header />
      <Box sx={{ backgroundColor: "#010066", color: 'white', minHeight: isScreen ? 'auto' : '700px' }}>
        <Container>
          <Grid paddingTop={9}></Grid>
          <Slider {...settings}>
            {slides.map((slide, index) => (
              <div key={index}>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={12} md={5}>
                    <Typography className='Nasaliza' sx={{ maxWidth: '100%', fontSize: "1.2rem", marginBottom: '2rem' }}>
                      insur<span style={{ color: 'red', fontWeight: 'bold' }}>AI</span>. innovation. intelligence.
                    </Typography>

                    <Box component="img" src={slide.img} alt={slide.title} sx={{ width: '100%', maxWidth: '100%' }} />
                  </Grid>
                  <Grid item xs={12} md={5.5} >
                    {slide.intro && (
                      <>
                        <Typography variant="h2" className="Nasaliza" gutterBottom sx={{ fontSize: { xs: '2rem', md: '2rem' }, fontWeight: 'bold' }}>
                          {slide.intro}
                        </Typography>
                        <Typography variant="h2" className="Nasaliza" gutterBottom sx={{ fontSize: { xs: '2rem', md: '3rem' }, fontWeight: 'bold', color: 'blueviolet' }}>
                          {slide.intro2}
                        </Typography>
                      </>
                    )}
                    <Typography variant="h2" className="Nasaliza" gutterBottom sx={{ fontSize: { xs: '2rem', md: '2rem' }, fontWeight: 'bold' }}>
                      {slide.title}
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '1rem', md: '1.25rem' }, color: 'rgba(255, 255, 255, 0.8)', fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif" }}>
                      {slide.description}
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '1rem', md: '1.25rem' }, color: 'rgba(255, 255, 255, 0.8)' }}>
                      {slide.description2}
                    </Typography>
                    {slide.description2 ?

                      <Box textAlign="center" mt={6}>
                        <a href="/requestdemo" style={{ textDecoration: 'none' }}>
                          <FuturisticButton>
                            Discover How InsurAI Can Revolutionize Your P&C Insurance Experience
                          </FuturisticButton>
                        </a>
                      </Box>
                      :

                      <Box textAlign="center" mt={6}>
                        <a href="/requestdemo" style={{ textDecoration: 'none' }}>
                          <FuturisticButton>
                            Transform Your Insurance Operations with Ivan's AI-Powered Expertise
                          </FuturisticButton>
                        </a>
                      </Box>
                    }
                    <Box mt={3} sx={{ textAlign: 'center' }}>
                      <StyledButtonComponent buttonWidth={200}>
                        <Link href={slide.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                          <PlayCircleFilled sx={{ marginRight: '8px' }} />
                          Watch Video
                        </Link>
                      </StyledButtonComponent>
                      <StyledButtonComponent buttonWidth={200} sx={{ marginLeft:isMobile?"0rem":"2rem" }}>
                        <Link href="/Aboutus" sx={{ color: "white", textDecoration: 'none' }}>Learn More</Link>
                      </StyledButtonComponent>


                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography
                        className="Nasaliza"
                        sx={{
                          fontWeight: 'bold',

                          fontSize: isMobile ? '1rem' : '1rem',  // Adjusts font size for mobile

                          color: 'white',
                        }}
                      >
                        <ContactMailIcon sx={{ marginRight: '10px', fontSize: isMobile ? '1.2rem' : '1.5rem', color: 'white' }} />
                        Contact us for free POC /
                        <Link
                          href="/requestdemo"
                          sx={{
                            marginLeft: isMobile ? "0rem" : '5px',
                            color: 'whitesmoke',
                            fontWeight: 'bold',
                            textDecoration: 'none',
                            '&:hover': { textDecoration: 'none' },
                          }}
                        >
                          Demo
                        </Link>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            ))}
          </Slider>
        </Container>
      </Box>

      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          // alignItems: "center",
          padding: "0rem 2rem",
          // backgroundColor: "#f0f2f5",
          // minHeight: "10vh",
          position: 'relative',
          top: isScreen ? '0' : '-55px', justifyContent: 'center', width: "100%", maxWidth: 1300, margin: 'auto'
        }}
      >
        <Grid container spacing={4}  >
          {features.map((feature, index) => (
            <Grid item xs={12} sm={4} md={4} key={index} >
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.1 * index, duration: 0.5 }}
              >
                <Card
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "white",
                    color: "black",
                    "&:hover": {
                      backgroundColor: "#0B70FF",
                      color: "white",
                      boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                      "& .iconBox": {
                        backgroundColor: "white",
                        color: "#0B70FF",
                      },
                    },
                    "& .iconBox": {
                      // backgroundColor: "white",
                      color: "white",
                    },
                    borderRadius: "8px",
                    boxShadow: 3,
                    overflow: "hidden",
                    height: '200px',
                  }}
                >
                  <Box
                    className="iconBox"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "1rem",
                      backgroundColor: "#0B70FF",
                      transition: "background-color 0.3s, color 0.3s",
                      width: "100px",
                      height: "100%",
                    }}
                  >
                    {React.cloneElement(feature.icon, {
                      sx: {
                        fontSize: 48,
                        // color: "white",
                        // "&:hover": {
                        //   color: "blue",
                        // },
                        transition: "color 0.3s",
                      },
                    })}
                  </Box>
                  <CardContent
                    sx={{
                      textAlign: "left",

                      transition: "background-color 0.3s, color 0.3s",
                      "&:hover": {
                        backgroundColor: "#0B70FF",
                        color: "white",
                      },
                    }}
                  >
                    <Typography variant="h6" className="Nasaliza">
                      {feature.title === 'InsurAI' ? <>
                        Insur<span style={{ color: 'red', fontWeight: 'bold' }}>AI</span>
                      </> : <>
                        {feature.title}
                      </>}

                    </Typography>
                    {feature.title2 && (
                      <Typography variant="h6" gutterBottom>
                        {feature.title2}
                      </Typography>
                    )}
                    <Typography variant="body2">
                      {feature.description}
                    </Typography>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>


      <Box sx={{ py: 8, backgroundColor: '#f5f5f5' }}>
        <Box sx={{ width: "100%", maxWidth: 1280, margin: "auto" }}>
          <Typography
            variant="h3"
            component="h2"
            sx={{ textAlign: "center", mb: 6, color: "#001661", fontWeight: "bold" }}
            className="Nasaliza"
          >
            Insur<span style={{ color: "red" }}>AI </span>Solutions Suite
          </Typography>

          {/* Slider Component */}
          <Slider {...sliderSettings}>
            {solutions.map((solution, index) => (
              <Grid item xs={12} sm={6} md={4} key={solution.id} padding={2} marginBottom={1}>
                <StyledCard
                  sx={{
                    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.15)",
                    },
                    background: "linear-gradient(145deg, #ffffff, #e6e6e6)",
                    borderRadius: "15px",
                    overflow: "hidden",
                    height: '230px'
                  }}
                >
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      padding: "1.5rem",
                    }}
                  >
                    <IconWrapper
                      sx={{
                        backgroundColor: "#0B70FF",
                        padding: "1rem",
                        borderRadius: "50%",
                        marginBottom: "1rem",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <solution.icon sx={{ fontSize: 32, color: "white" }} />
                    </IconWrapper>

                    <Typography variant="h5" component="h3" sx={{ color: "#001661", fontWeight: "bold" }}>
                      {solution.name}
                    </Typography>

                    <Typography variant="body1" sx={{ mb: 2, color: "#333" }}>
                      {solution.description}
                    </Typography>

                    <ReadMoreLink
                      sx={{
                        color: "#0B70FF",
                        fontWeight: "bold",
                        textDecoration: "none",
                        cursor: "pointer",
                        transition: "color 0.3s",
                        "&:hover": {
                          color: "#0040FF"
                        }
                      }}
                      onClick={() => handleNavigation(`${solution.id}`)}
                    >
                      Read more
                    </ReadMoreLink>
                  </CardContent>
                </StyledCard>
              </Grid>
            ))}
          </Slider>
        </Box>
      </Box>
      
      <AnimatedSection>
        <Box
          display="flex"
          justifyContent="center"
          padding={2}
          margin="auto"
          maxWidth="1200px"
        >
          <Grid container spacing={2} alignItems="center">
            {
              isScreen &&
              <Grid item xs={12} md={6}>

                <Box display="flex" justifyContent="center">
                  <AnimatedImage src={AdminPlatform} alt="Document Reader" />
                </Box>
              </Grid>
            }
            <Grid item xs={12} md={6}>
              <Box p={2} textAlign={isScreen ? "center" : "left"}>
              <Typography className='Nasaliza' sx={{ fontSize: '1.8rem', color: '#001066' }}>
              InsurAdmin Platform
                </Typography>
                <Typography variant="body1" gutterBottom
                  sx={{
                    textAlign: 'justify',
                    hyphens: 'auto',
                    wordBreak: 'break-word',
                    '& > span': { display: 'inline-block' }
                  }}
                >
                  The InsurAdmin Platform offers insurance carriers a centralized dashboard to manage policies, claims, agents, and customer interactions. It enables real-time tracking of operations, agent performance, and policy status, with customizable reports, automated alerts, and analytics for data-driven decisions and improved efficiency.
                </Typography>
                <Box mt={3} sx={{ textAlign: 'center' }}>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '-130px' }}>
                    <Link href="https://www.youtube.com/watch?v=j33lYdWUx-o" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                    <PlayCircleFilled sx={{ marginRight: '8px' }} />
                      Watch Video
                    </Link>
                  </StyledButtonComponent>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '15px' }} onClick={() => handleNavigation('/InsurAdminPlatform')}>
                    Read more
                  </StyledButtonComponent>
                </Box>
              </Box>
            </Grid>
            {
              !isScreen &&
              <Grid item xs={12} md={6}>

                <Box display="flex" justifyContent="center">
                  <AnimatedImage src={AdminPlatform} alt="Document Reader" />
                </Box>
              </Grid>
            }
          </Grid>

        </Box>
      </AnimatedSection>

   

      <AnimatedSection>
        <Box
          display="flex"
          justifyContent="center"
          padding={2}
          margin="auto"
          maxWidth="1200px"
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="center">
                <AnimatedImage src={InsurAI_IMG} alt="Document Reader" />
              </Box>
            </Grid>
            <Grid item xs={false} md={0.6} /> {/* This creates the 1 column space */}
            <Grid item xs={12} md={5.4}>
              <Box textAlign={isScreen ? "center" : "left"}>
                <Typography className='Nasaliza' sx={{ fontSize: '1.8rem', color: 'green' }}>
                  <span style={{ color: '#8A2BE2', fontWeight: 'bold' }}>IVAN - Innovon Virtual Assistant </span>
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    textAlign: 'justify',
                    hyphens: 'auto',
                    wordBreak: 'break-word',
                    '& > span': { display: 'inline-block' }
                  }}
                >
                  Experience the future of insurance interaction with <span style={{ color: '#8A2BE2', fontWeight: 'bold' }} className='Nasaliza'>IVAN</span>, our cutting-edge multi-agent virtual assistant for Property & Casualty insurance. Powered by advanced Large Language Models (LLMs) and Generative AI, IVAN revolutionizes how you manage your insurance needs.
                </Typography>
                <Box mt={3} sx={{ textAlign: 'center' }}>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '-100px' }}>
                    <Link href="https://www.youtube.com/watch?v=BKCuMFq1Vus" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                      <PlayCircleFilled sx={{ marginRight: '8px' }} />
                      Watch Video
                    </Link>
                  </StyledButtonComponent>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '15px' }} onClick={() => handleNavigation('/InsurAI')}>
                    Read more
                  </StyledButtonComponent>
                </Box>

              </Box>
            </Grid>
          </Grid>
        </Box>
      </AnimatedSection>

      <AnimatedSection>
        <Box
          display="flex"
          justifyContent="center"
          padding={2}
          margin="auto"
          maxWidth="1200px"
        >
          <Grid container spacing={2} alignItems="center">
            {
              isScreen &&
              <Grid item xs={12} md={6}>

                <Box display="flex" justifyContent="center">
                  <AnimatedImage src={IDP_IMG} alt="Document Reader" />
                </Box>
              </Grid>
            }
            <Grid item xs={12} md={6}>
              <Box p={2} textAlign={isScreen ? "center" : "left"}>
                <Typography className='Nasaliza' sx={{ fontSize: '1.8rem', color: 'orange' }}>
                SmartFNOL
                </Typography>
                <Typography variant="body1" gutterBottom
                  sx={{
                    textAlign: 'justify',
                    hyphens: 'auto',
                    wordBreak: 'break-word',
                    '& > span': { display: 'inline-block' }
                  }}>
                  The SmartFNOL is a user-friendly platform for reporting and tracking insurance claims, offering streamlined incident submission, real-time updates, and easy document uploads.
                </Typography>
                <Box mt={3} sx={{ textAlign: 'center' }}>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '-130px' }}>
                    <Link href="https://www.youtube.com/@InnovonTek" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                      <PlayCircleFilled sx={{ marginRight: '8px' }} />
                      Watch Video
                    </Link>
                  </StyledButtonComponent>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '15px' }} onClick={() => handleNavigation('/SmartFNOL')}>
                    Read more
                  </StyledButtonComponent>
                </Box>
              </Box>
            </Grid>
            {
              !isScreen &&
              <Grid item xs={12} md={6}>

                <Box display="flex" justifyContent="center">
                  <AnimatedImage src={IDP_IMG} alt="Document Reader" />
                </Box>
              </Grid>
            }
          </Grid>

        </Box>
      </AnimatedSection>

      <AnimatedSection>
        <Box
          display="flex"
          justifyContent="center"
          padding={2}
          margin="auto"
          maxWidth="1200px"
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="center" p={2}>
                <AnimatedImage src={idp_intakePolicy} alt="Document Reader" />
              </Box>
            </Grid>
            <Grid item xs={false} md={0.6} /> {/* This creates the 1 column space */}
            <Grid item xs={12} md={5.4}>
              <Box textAlign={isScreen ? "center" : "left"}>
                <Typography className='Nasaliza' sx={{ fontSize: '1.8rem', color: 'green' }}>
                SmartQuote
                </Typography>
                <Typography variant="body1" gutterBottom
                  sx={{
                    textAlign: 'justify',
                    hyphens: 'auto',
                    wordBreak: 'break-word',
                    '& > span': { display: 'inline-block' }
                  }}
                >
                  The SmartQuote is a streamlined platform for submitting and managing insurance policies, allowing for efficient data entry and real-time policy tracking.
                </Typography>
                <Box mt={3} sx={{ textAlign: 'center' }}>
                  <Link href="https://www.youtube.com/@InnovonTek" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                    <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '-100px' }}>
                      <PlayCircleFilled sx={{ marginRight: '8px' }} />
                      Watch Video
                    </StyledButtonComponent>
                  </Link>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '15px' }} onClick={() => handleNavigation('/SmartQuote')}>
                    Read more
                  </StyledButtonComponent>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </AnimatedSection>
      <AnimatedSection>
        <Box
          display="flex"
          justifyContent="center"
          padding={2}
          margin="auto"
          maxWidth="1200px"
        >
          <Grid container spacing={2} alignItems="center">
            {
              isScreen &&
              <Grid item xs={12} md={6}>

                <Box display="flex" justifyContent="center">
                  <AnimatedImage src={IDPFNOL} alt="Document Reader" />
                </Box>
              </Grid>
            }
            <Grid item xs={false} md={0.6} /> {/* This creates the 1 column space */}
            <Grid item xs={12} md={5.4}>
              <Box p={2} textAlign={isScreen ? "center" : "left"}>
                <Typography className='Nasaliza' sx={{ fontSize: '1.8rem', color: 'orange' }}>
                DocAI FNOL
                </Typography>
                <Typography variant="body1" gutterBottom
                  sx={{
                    textAlign: 'justify',
                    hyphens: 'auto',
                    wordBreak: 'break-word',
                    '& > span': { display: 'inline-block' }
                  }}
                >
                 DocAI FNOL utilizes advanced LLMs and Generative AI to instantly process unstructured documents into structured data, automating claims for faster resolutions, reduced errors, and cost savings for insurers and policyholders.
                </Typography>
                <Box mt={3} sx={{ textAlign: 'center' }}>
                  <Link href="https://www.youtube.com/watch?v=Nq_YQzBukN0" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                    <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '-75px' }}>
                      <PlayCircleFilled sx={{ marginRight: '8px' }} />
                      Watch Video
                    </StyledButtonComponent>
                  </Link>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '15px' }} onClick={() => handleNavigation('/DocAI/FNOL')}>
                    Read more
                  </StyledButtonComponent>
                </Box>
              </Box>
            </Grid>
            {
              !isScreen &&
              <Grid item xs={12} md={6}>

                <Box display="flex" justifyContent="center">
                  <AnimatedImage src={IDPFNOL} alt="Document Reader" />
                </Box>
              </Grid>
            }
          </Grid>

        </Box>
      </AnimatedSection>


      <AnimatedSection>
        <Box
          display="flex"
          justifyContent="center"
          padding={2}
          margin="auto"
          maxWidth="1200px"
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="center" p={2}>
                <AnimatedImage src={policyIDP} alt="Document Reader" />
              </Box>
            </Grid>
            <Grid item xs={false} md={0.6} /> {/* This creates the 1 column space */}
            <Grid item xs={12} md={5.4}>
              <Box textAlign={isScreen ? "center" : "left"}>
                <Typography className='Nasaliza' sx={{ fontSize: '1.8rem', color: 'green' }}>
                DocAI Quote
                </Typography>
                <Typography variant="body1" gutterBottom
                  sx={{
                    textAlign: 'justify',
                    hyphens: 'auto',
                    wordBreak: 'break-word',
                    '& > span': { display: 'inline-block' }
                  }}
                >
                  Harness cutting-edge LLMs and Generative AI to revolutionize insurance policy submissions. Our platform automates data extraction, validates information, and integrates with backend/core systems, speeding up policy issuance while enhancing accuracy and efficiency.
                </Typography>
                <Box mt={3} sx={{ textAlign: 'center' }}>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '-100px' }}>
                    <Link href="https://www.youtube.com/watch?v=1Hs5l9rpZlU" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                      <PlayCircleFilled sx={{ marginRight: '8px' }} />
                      Watch Video
                    </Link>
                  </StyledButtonComponent>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '15px' }} onClick={() => handleNavigation('/DocAIQuote')}>
                    Read more
                  </StyledButtonComponent>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </AnimatedSection>







      <AnimatedSection>
        <Box
          display="flex"
          justifyContent="center"
          padding={2}
          margin="auto"
          maxWidth="1200px"
        >
          <Grid container spacing={2} alignItems="center">
            {
              isScreen &&
              <Grid item xs={12} md={6}>

                <Box display="flex" justifyContent="center">
                  <AnimatedImage src={Email_policyIntake} alt="Document Reader" />
                </Box>
              </Grid>
            }
            <Grid item xs={12} md={6}>
              <Box p={2} textAlign={isScreen ? "center" : "left"}>
                <Typography className='Nasaliza' sx={{ fontSize: '1.8rem', color: 'orange' }}>
                Mail2Quote
                </Typography>
                <Typography variant="body1" gutterBottom
                  sx={{
                    textAlign: 'justify',
                    hyphens: 'auto',
                    wordBreak: 'break-word',
                    '& > span': { display: 'inline-block' }
                  }}
                >
                  Mail2Quote is a system that automates the extraction and processing of policy-related information directly from emails, streamlining the intake process by converting email data into actionable policy entries, reducing manual effort, and improving accuracy.
                </Typography>
                <Box mt={3} sx={{ textAlign: 'center' }}>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '-130px' }}>
                    <PlayCircleFilled sx={{ marginRight: '8px' }} />
                    <Link href="https://www.youtube.com/@InnovonTek" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                      Watch Video
                    </Link>
                  </StyledButtonComponent>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '15px' }} onClick={() => handleNavigation('/Mail2Quote')}>
                    Read more
                  </StyledButtonComponent>
                </Box>
              </Box>
            </Grid>
            {
              !isScreen &&
              <Grid item xs={12} md={6}>

                <Box display="flex" justifyContent="center">
                  <AnimatedImage src={Email_policyIntake} alt="Document Reader" />
                </Box>
              </Grid>
            }
          </Grid>

        </Box>
      </AnimatedSection>






      <AnimatedSection>
        <Box
          display="flex"
          justifyContent="center"
          padding={2}
          margin="auto"
          maxWidth="1200px"
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="center" p={2}>
                <AnimatedImage src={Email_To_Fnol_IMG} alt="Document Reader" />
              </Box>
            </Grid>
            <Grid item xs={false} md={0.6} /> {/* This creates the 1 column space */}
            <Grid item xs={12} md={5.4}>
              <Box textAlign={isScreen ? "center" : "left"}>
                <Typography className='Nasaliza' sx={{ fontSize: '1.8rem', color: 'green' }}>
                Mail2Loss
                </Typography>
                <Typography variant="body1" gutterBottom
                  sx={{
                    textAlign: 'justify',
                    hyphens: 'auto',
                    wordBreak: 'break-word',
                    '& > span': { display: 'inline-block' }
                  }}
                >
                  Our EMAIL TO FNOL solution automates claims processing by extracting and organizing critical information from emails and attachments. This speeds up claim initiation, reduces errors, and minimizes manual work, leading to faster, more accurate claims and improved customer satisfaction.
                </Typography>
                <Box mt={3} sx={{ textAlign: 'center' }}>
                  <Link href="https://youtu.be/5nE2aipvMRs" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                    <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '-100px' }}>
                      <PlayCircleFilled sx={{ marginRight: '8px' }} />
                      Watch Video
                    </StyledButtonComponent>
                  </Link>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '15px' }} onClick={() => handleNavigation('/Mail2Loss')}>
                    Read more
                  </StyledButtonComponent>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </AnimatedSection>









      <AnimatedSection>
        <Box
          display="flex"
          justifyContent="center"
          padding={2}
          margin="auto"
          maxWidth="1200px"
        >
          <Grid container spacing={2} alignItems="center">
            {
              isScreen &&
              <Grid item xs={12} md={6}>

                <Box display="flex" justifyContent="center">
                  <AnimatedImage src={IDPID1} alt="Document Reader" />
                </Box>
              </Grid>
            }


            <Grid item xs={false} md={0.6} /> {/* This creates the 1 column space */}
            <Grid item xs={12} md={5.4}>
              <Box p={2} textAlign={isScreen ? "center" : "left"}>
                <Typography className='Nasaliza' sx={{ fontSize: '1.8rem', color: 'orange' }}>
                DocAI ID Card
                </Typography>
                <Typography variant="body1" gutterBottom
                  sx={{
                    textAlign: 'justify',
                    hyphens: 'auto',
                    wordBreak: 'break-word',
                    '& > span': { display: 'inline-block' }
                  }}
                >
                  Our advanced solution uses cutting-edge LLMs and computer vision to automatically extract data from any type of ID card. Eliminate manual entry, reduce errors, and instantly digitize information from driver's licenses, passports, and various government-issued IDs.
                </Typography>
                <Box mt={3} sx={{ textAlign: 'center' }}>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '-75px' }}>
                    <Link href="https://www.youtube.com/watch?v=ckA_59Y2Y3I" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                      <PlayCircleFilled sx={{ marginRight: '8px' }} />
                      Watch Video
                    </Link>
                  </StyledButtonComponent>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '15px' }} onClick={() => handleNavigation('/DocAI/IDCardExtraction')}>
                    Read more
                  </StyledButtonComponent>
                </Box>
              </Box>
            </Grid>

            {
              !isScreen &&
              <Grid item xs={12} md={6}>

                <Box display="flex" justifyContent="center">
                  <AnimatedImage src={IDPID1} alt="Document Reader" />
                </Box>
              </Grid>
            }
          </Grid>
        </Box>
      </AnimatedSection>


      <AnimatedSection>
        <Box
          display="flex"
          justifyContent="center"
          padding={2}
          margin="auto"
          maxWidth="1200px"
        >
          <Grid container spacing={2} alignItems="center">

            <Grid item xs={12} md={6}>

              <Box display="flex" justifyContent="center">
                <AnimatedImage src={MedBill} alt="Document Reader" />
              </Box>
            </Grid>

            <Grid item xs={false} md={0.6} /> {/* This creates the 1 column space */}
            <Grid item xs={12} md={5.4}>
              <Box p={2} textAlign={isScreen ? "center" : "left"}>
                <Typography className='Nasaliza' sx={{ fontSize: '1.8rem', color: 'orange' }}>
                DocAI Med Bill
                </Typography>
                <Typography variant="body1" gutterBottom
                  sx={{
                    textAlign: 'justify',
                    hyphens: 'auto',
                    wordBreak: 'break-word',
                    '& > span': { display: 'inline-block' }
                  }}
                >
                  Our advanced solution leverages state of the art LLM's to automatically extract data from any type of medical bill document. Process PDFs, images, and scanned files effortlessly, capturing critical information to streamline billing workflows and improve accuracy.
                </Typography>
                <Box mt={3} sx={{ textAlign: 'center' }}>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '-70px' }}>
                    <Link href="https://www.youtube.com/@InnovonTek" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                      <PlayCircleFilled sx={{ marginRight: '8px' }} />
                      Watch Video
                    </Link>
                  </StyledButtonComponent>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '15px' }} onClick={() => handleNavigation('/DocAIMedBill')}>
                    Read more
                  </StyledButtonComponent>
                </Box>
              </Box>
            </Grid>

          </Grid>

        </Box>
      </AnimatedSection>

      <AnimatedSection>
        <Box
          display="flex"
          justifyContent="center"
          padding={2}
          margin="auto"
          maxWidth="1200px"
        >
          <Grid container spacing={2} alignItems="center">
            {
              isScreen &&
              <Grid item xs={12} md={6}>

                <Box display="flex" justifyContent="center">
                  <AnimatedImage src={Loss_Runs} alt="Document Reader" />
                </Box>
              </Grid>
            }


            <Grid item xs={false} md={0.6} /> {/* This creates the 1 column space */}
            <Grid item xs={12} md={5.4}>
              <Box textAlign={isScreen ? "center" : "left"}>
                <Typography className='Nasaliza' sx={{ fontSize: '1.8rem', color: 'green' }}>
                DocAI Loss Run
                </Typography>
                <Typography variant="body1" gutterBottom
                  sx={{
                    textAlign: 'justify',
                    hyphens: 'auto',
                    wordBreak: 'break-word',
                    '& > span': { display: 'inline-block' }
                  }}
                >
                  Simplify DocAI Loss Run Data Extraction Our automated solution streamlines the process, effortlessly extracting key information from images or PDFs and organizing it into a clear, easy-to-read table. Save time and reduce effort with our efficient data extraction tool.
                </Typography>
                <Box mt={3} sx={{ textAlign: 'center' }}>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '-100px' }}>
                    <Link href="https://youtu.be/nOPripLzoLc" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                      <PlayCircleFilled sx={{ marginRight: '8px' }} />
                      Watch Video
                    </Link>
                  </StyledButtonComponent>
                  <StyledButtonComponent buttonWidth={200} sx={{ marginLeft: isMobile ? '0px' : '15px' }} onClick={() => handleNavigation('/DocAIlossrunreport')}>
                    Read more
                  </StyledButtonComponent>
                </Box>
              </Box>
            </Grid>

            {
              !isScreen &&
              <Grid item xs={12} md={6}>

                <Box display="flex" justifyContent="center">
                  <AnimatedImage src={Loss_Runs} alt="Document Reader" />
                </Box>
              </Grid>
            }
          </Grid>
        </Box>
      </AnimatedSection>




     

      <Grid marginTop={10}></Grid>
      <Container>
        <Box sx={{ textAlign: 'center', my: 4 }}>
          <Typography variant="h4" component="h2" sx={{ color: '#6C4AE3', fontWeight: 'bold' }} className="Nasaliza">
            Unlocking the Potential of
            Cutting-Edge Technologies
          </Typography>
          <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
            Our company utilizes state-of-the-art technologies to deliver groundbreaking solutions that
            revolutionize industries.
          </Typography>
        </Box>
        <Grid container spacing={4}>

          <Grid item xs={12} md={4} sx={{ textAlign: 'left' }}>
            <HoverCard>
              <CardContent>
                <Box>
                  <img src={Generative_AI_img1} alt="Increase Capacity" style={{ width: '50px', height: '50px', transition: 'background-color 0.3s ease-in-out' }} />
                  <Typography variant="h6" component="h3" sx={{ mt: 2, fontWeight: 'bold' }} className="Nasaliza">
                    Generative AI
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    Unleash innovation with AI-powered solutions that create, adapt, and optimize insurance processes.
                  </Typography>
                </Box>
              </CardContent>
            </HoverCard>
          </Grid>

          <Grid item xs={12} md={4} sx={{ textAlign: 'left' }}>
            <HoverCard>

              <CardContent>

                <Box>
                  <img src={LLMS_IMG2} alt="Reduce Cost" style={{ width: '50px', height: '50px' }} />
                  <Typography variant="h6" component="h3" sx={{ mt: 2, fontWeight: 'bold' }} className="Nasaliza">
                    Large Language Models
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    Elevate communication and analysis with advanced AI that understands and processes complex insurance language.
                  </Typography>
                </Box>
              </CardContent>
            </HoverCard>
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: 'left' }}>
            <HoverCard>



              <CardContent>

                <Box>
                  <img src={Computer_Vision_img3} alt="Rapid ROI" style={{ width: '50px', height: '50px' }} />
                  <Typography variant="h6" component="h3" sx={{ mt: 2, fontWeight: 'bold' }} className="Nasaliza">
                    Computer Vision for DocAI
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    We leverage Computer Vision for DocAI to automate swift and accurate

                    data extraction and analysis from diverse documents.
                  </Typography>
                </Box>
              </CardContent>
            </HoverCard>
          </Grid>
        </Grid>
        <Grid container spacing={4}>

          <Grid item xs={12} md={4} sx={{ textAlign: 'left', marginTop: "1rem" }}>
            <HoverCard>
              <CardContent>
                <Box>
                  <img src={Google_AI} alt="Increase Capacity" style={{ width: '50px', height: '50px', transition: 'background-color 0.3s ease-in-out' }} />
                  <Typography variant="h6" component="h3" sx={{ mt: 2, fontWeight: 'bold' }} className="Nasaliza">
                    Google AI Integration
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    Seamlessly integrate with Google AI for enhanced functionality.
                  </Typography>
                </Box>
              </CardContent>
            </HoverCard>
          </Grid>

          <Grid item xs={12} md={4} sx={{ textAlign: 'left', marginTop: "1rem" }}>
            <HoverCard>

              <CardContent>

                <Box>
                  <img src={AWS_AI} alt="Reduce Cost" style={{ width: '50px', height: '50px' }} />
                  <Typography variant="h6" component="h3" sx={{ mt: 2, fontWeight: 'bold' }} className="Nasaliza">
                    AWS AI Integration
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    Leverage the power of AWS AI for secure and scalable solutions.
                  </Typography>
                </Box>
              </CardContent>
            </HoverCard>
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: 'left', marginTop: "1rem" }}>
            <HoverCard>



              <CardContent>

                <Box>
                  <img src={Secure_AI} alt="Rapid ROI" style={{ width: '50px', height: '50px' }} />
                  <Typography variant="h6" component="h3" sx={{ mt: 2, fontWeight: 'bold' }} className="Nasaliza">
                    Secure Data Management
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    Protect your data with our robust and secure management system.
                  </Typography>
                </Box>
              </CardContent>
            </HoverCard>
          </Grid>
        </Grid>
      </Container>
      <Grid marginTop={10}></Grid>

      <Footer />
    </>
  );
};

export default Servicetypes;

