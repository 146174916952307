import React, { useState, useEffect} from 'react';
import { Grid, Paper, Typography, Box, FormControl, IconButton, Tooltip, TextField, FormHelperText } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import SelectField from '../../Fields/SelectField';
import StyledButtonComponent from '../../../components/StyledButton';
import { NavigateNext as NavigateNextIcon, NavigateBefore as NavigateBeforeIcon } from "@mui/icons-material";


const Coverages = ({ onNext, onBack, formData, setFormData, enableReviewButton, onReviewClick, updateStepValidity }) => {
  console.log("coverages data", formData, setFormData);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const isValid = isFormValid();
    updateStepValidity(isValid);
  }, [formData, errors]);


  const handleCoveragesChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      coverages: {
        ...prevFormData.coverages,
        [name]: value,
      },
    }));

    validateField(name, value);
    // After updating formData, check form validity
    const isValid = isFormValid();
    updateStepValidity(isValid);
  };

  const validateField = (name, value) => {
    const newErrors = { ...errors };

    // Required field check for all fields
    if (value === "") {
      newErrors[name] = 'This field is required';
    } else {
      delete newErrors[name];
    }

    setErrors(newErrors);
  };

  const isFormValid = () => {
    const requiredFields = [
      'dwellingCoverage',
      'personalLiabilityCoverage',
      'personalProperty',
      'deductible',
      'medicalPayments',
    ];

    for (const field of requiredFields) {
      console.log(field, formData[field], errors, errors[field]);

      if (formData[field] === "" || errors[field]) {
        return false;
      }
    }
    return true;
  };

  const handleNext = () => {
    if (isFormValid()) {
      onNext("coverages", formData);
    } else {
      console.log("Form has errors or missing required fields. Please correct them.");
    }
  };

  const renderField = (id, label, tooltipText, type = "text") => (
    <Grid item xs={12} sm={6} key={id}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Typography>{label}</Typography>
        </Grid>
        <Grid item>
          <Tooltip title={tooltipText}>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <TextField // Use TextField instead of CustomTextField
        id={id}
        name={id}
        type={type}
        value={formData[id]} // Access from formData
        onChange={handleCoveragesChange}
        error={!!errors[id]}
        helperText={errors[id] ? <FormHelperText error>{errors[id]}</FormHelperText> : ""}
        fullWidth
        variant="standard"
        InputLabelProps={{ shrink: true }} // Add if needed
      />
    </Grid>
  );

  return (
    <Grid
      container
      sx={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,
        overflowX: 'hidden',
        margin: '3rem auto',
      }}
    >
      <Grid item xs={12} md={8}>
        <Typography
          className='Nasaliza'
          variant="h6"
          sx={{
            fontWeight: 600,
            mb: 2,
            color: '#010066',
            textAlign: 'left',
          }}
        >
          Coverage Details
        </Typography>
        <Paper elevation={2} sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            {/* {renderField("dwellingCoverage", "Dwelling Coverage", "Coverage for your Dwelling & Permanent Fixtures. Contact us for more details/assistance.", "number")} */}
            {/* {renderField("personalProperty", "Personal Property", "Jewelry, Fine Arts, Antiques, etc.", "number")} */}
            {/* {renderField("personalLiabilityCoverage", "Personal Liability Coverage", "We recommend a minimum of $500,000 or $1,000,000.", "number")} */}



            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <SelectField
                  name="dwellingCoverage"
                  label="Dwelling Coverage"
                  options={[
                    { value: '100000', label: '$100,000' },
                    { value: '200000', label: '$200,000' },
                    { value: '500000', label: '$500,000' },
                    { value: '1000000', label: '$1,000,000' }
                  ]}
                  value={formData.dwellingCoverage} // Access from formData
                  onChange={handleCoveragesChange}
                  error={!!errors.dwellingCoverage}
                  helperText={errors.dwellingCoverage ? <FormHelperText error>{errors.dwellingCoverage}</FormHelperText> : ""}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <SelectField
                  name="personalLiabilityCoverage"
                  label="Personal Liability Coverage"
                  options={[
                    { value: '5000', label: '$5,000' },
                    { value: '10000', label: '$10,000' },
                    { value: '20000', label: '$20,000' },
                    { value: '50000', label: '$50,000' }
                  ]}
                  value={formData.personalLiabilityCoverage} // Access from formData
                  onChange={handleCoveragesChange}
                  error={!!errors.personalLiabilityCoverage}
                  helperText={errors.personalLiabilityCoverage ? <FormHelperText error>{errors.personalLiabilityCoverage}</FormHelperText> : ""}
                />
              </FormControl>
            </Grid>


            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <SelectField
                  name="personalProperty"
                  label="Personal Property"
                  options={[
                    { value: '5000', label: '$5,000' },
                    { value: '10000', label: '$10,000' },
                    { value: '20000', label: '$20,000' },
                    { value: '50000', label: '$50,000' }
                  ]}
                  value={formData.personalProperty} // Access from formData
                  onChange={handleCoveragesChange}
                  error={!!errors.personalProperty}
                  helperText={errors.personalProperty ? <FormHelperText error>{errors.personalProperty}</FormHelperText> : ""}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <SelectField
                  name="deductible"
                  label="Deductible"
                  options={[
                    { value: '500', label: '$500' },
                    { value: '1000', label: '$1,000' },
                    { value: '2000', label: '$2,000' },
                    { value: '5000', label: '$5,000' }
                  ]}
                  value={formData.deductible} // Access from formData
                  onChange={handleCoveragesChange}
                  error={!!errors.deductible}
                  helperText={errors.deductible ? <FormHelperText error>{errors.deductible}</FormHelperText> : ""}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControl fullWidth variant="outlined">
                <SelectField
                  name="medicalPayments"
                  label="Medical Payments to Others Coverage"
                  options={[
                    { value: '5000', label: '$5,000' },
                    { value: '10000', label: '$10,000' },
                    { value: '20000', label: '$20,000' },
                    { value: '50000', label: '$50,000' }
                  ]}
                  value={formData.medicalPayments} // Access from formData
                  onChange={handleCoveragesChange}
                  error={!!errors.medicalPayments}
                  helperText={errors.medicalPayments ? <FormHelperText error>{errors.medicalPayments}</FormHelperText> : ""}
                />
              </FormControl>
            </Grid>

            {/* {renderField("medicalPayments", "Medical Payments to Others Coverage", "We recommend a minimum of $5,000.", "number")} */}
          </Grid>
        </Paper>
        {/* "Next" and "Back" Buttons */}
        <Box mt={3} display="flex" justifyContent="space-between">
          <Box>

            <StyledButtonComponent // "Back" button
              buttonWidth={100}
              variant="contained"
              onClick={onBack}
              startIcon={<NavigateBeforeIcon />}
            >
              Back
            </StyledButtonComponent>
          </Box>
          <Box sx={{ textAlign: 'right', mt: 2 }}>
            <StyledButtonComponent
              buttonWidth={100}
              variant="outlined"
              sx={{ mr: 2 }}
              disableColor={"#B6E3FF"}
              disabled={!enableReviewButton}  // Correctly use the prop here 
              onClick={onReviewClick} // Add the onClick handler     
            >
              Review
            </StyledButtonComponent>
            <StyledButtonComponent
              buttonWidth={100}
              variant="outlined"
              onClick={handleNext}
              endIcon={<NavigateNextIcon />}
              disableColor={"#B6E3FF"}
              disabled={!isFormValid()}
            >
              Next
            </StyledButtonComponent>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Coverages;