import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import contact from '../assets/contact.png';
import Header from '../components/header.js';
import Footer from '../components/footer.js';

const Success = () => {
  const claimid = JSON.parse(localStorage.getItem('claimID'));
  const claimID = localStorage.getItem('claimID');
  const PolicyIntakeAfterSubmitDetails = JSON.parse(localStorage.getItem('PolicyIntakeAfterSubmitDetails'));
  console.log(PolicyIntakeAfterSubmitDetails);
  const [showPolicIntakeData, setShowPolicyIntakeData] = useState(false);

  let userData, companyData, imageSrc;
  
  // Use useEffect to update state based on PolicyIntakeAfterSubmitDetails
  useEffect(() => {
    if (PolicyIntakeAfterSubmitDetails) {
      setShowPolicyIntakeData(true);
    }
  }, [PolicyIntakeAfterSubmitDetails]);

  // Check if claimID or claimid exists, then get user and company data
  if (claimID || claimid) {
    userData = JSON.parse(localStorage.getItem('user'));
    companyData = JSON.parse(localStorage.getItem('company'));
    if (companyData) {
      imageSrc = `data:image/${companyData.image_type};base64,${companyData.image_data}`;
    }
  }



  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  console.log(isMobile);

  return (
    <Box>
      <Header />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* Adjust height for header and footer */}
        <Card
          sx={{
            width: { xs: '90%', md: '60%', lg: '50%' },
            margin: '5rem 0rem',
          }}
        >
          <CardContent>
            {!showPolicIntakeData && (
              <Box
                sx={{
                  width: '100%',
                  margin: 'auto 1rem auto',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {companyData && companyData.ic_logo_path && (
                  <img
                    src={imageSrc}
                    alt="Insurance Company Logo"
                    style={{ width: isMobile ? '80%' : '35%' }}
                  />
                )}
              </Box>
            )}
            <Grid container justifyContent='center' >
              <Grid item xs={12} md={10} lg={6}>
                <Box sx={{ p: 1, textAlign: 'left' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <CheckCircleIcon
                      sx={{ fontSize: isMobile ? 30 : 40, mr: 2, color: 'green', marginLeft: isMobile ? '0rem' : '-3rem' }}
                    />
                    <Typography
                      className='Nasaliza'
                      style={{ fontSize: isMobile ? '1.2rem' : '1.5rem', whiteSpace: isMobile ? 'wrap' : 'nowrap', }}
                    >
                      {showPolicIntakeData ? "Quote created successfully" : "Claim Submitted Successfully!"}
                    </Typography>
                  </Box>
                  <Typography variant='h6' sx={{ mb: 2 }} className='Nasaliza'>
                    {showPolicIntakeData ? 'Quote ID : ' : 'Claim ID : '}
                    <span
                      style={{
                        color: companyData?.ic_primary_color || '#0B70FF',
                      }}
                    >
                      {showPolicIntakeData
                        ? PolicyIntakeAfterSubmitDetails.quote_number
                        : claimid?.data ? claimid.data : claimID}
                    </span>
                  </Typography>
                  {showPolicIntakeData && (
                    <Typography variant='h6' sx={{ mb: 2 }} className='Nasaliza'>
                      Quote Amount :
                      <span style={{ color: '#0B70FF' }}>
                        ${PolicyIntakeAfterSubmitDetails.quote_amount}
                      </span>
                    </Typography>
                  )}

                  <Typography
                    variant='h6'
                    sx={{ fontWeight: 'bold', mb: 1 }}
                    className='Nasaliza'
                  >
                    Next Steps:
                  </Typography>
                  <ul style={{ listStyleType: 'none', padding: 0, paddingLeft: "1rem" }}>
                    {[
                      <span style={{ display: 'flex', alignItems: 'center', color: 'black', fontSize: "1.5rem", marginRight: "1rem" }}>
                        ➞  {/* Right arrow */}
                        <Typography
                          className='Nasaliza'
                          style={{
                            fontWeight: 'lighter',
                            fontSize: '0.8rem',
                            marginLeft: '0.5rem', // Add some space between arrow and text
                            whiteSpace: 'nowrap', 
                          }}
                        >
                          Check your email for confirmation{' '}
                          <a
                            href={`mailto:${userData?.pol_email}`}
                            style={{ color: 'blue' }}
                          >
                            {showPolicIntakeData ? "" : claimID || claimid ? userData.pol_email : ""}
                          </a>
                        </Typography>
                      </span>,
                      <span style={{ display: 'flex', alignItems: 'center', color: 'black', fontSize: "1.5rem", marginRight: "1rem" }}>
                        ➞  {/* Right arrow */}
                        <Typography
                          className='Nasaliza'
                          style={{
                            fontWeight: 'lighter',
                            fontSize: '0.8rem',
                            marginLeft: '0.5rem', // Add some space between arrow and text
                            whiteSpace: 'nowrap', 
                          }}
                        >
                          {claimID || claimid ? " Our team will review your claim" : " Our team will review your Policy"}
                        </Typography>
                      </span>,
                      <span style={{ display: 'flex', alignItems: 'center', color: 'black', fontSize: "1.5rem", marginRight: "1rem" }}>
                        ➞  {/* Right arrow */}
                        <Typography
                          className='Nasaliza'
                          style={{
                            fontWeight: 'lighter',
                            fontSize: '0.8rem',
                            marginLeft: '0.5rem', // Add some space between arrow and text
                            whiteSpace: 'nowrap', 
                          }}
                        >
                          We'll contact you if additional information is needed
                        </Typography>
                      </span>,
                    ].map((step, index) => (
                      <li
                        key={index}
                        style={{
                          marginBottom: '0.5rem',
                        }}
                      >
                        {step}
                      </li>
                    ))}
                  </ul>
                  <Box sx={{ mt: 4 }}>
                    <Typography
                      variant='h6'
                      sx={{ fontWeight: 'bold', mb: 1 }}
                      className='Nasaliza'
                    >
                      For support, contact us at:
                    </Typography>
                    <Typography
                      className='Nasaliza'
                      style={{ fontSize: '0.9rem', paddingLeft: "2rem", whiteSpace: isMobile ? 'wrap' : 'nowrap', }}
                    >
                      <img
                        src={contact}
                        width='4%'
                        alt='email-mobile logo'
                      />{' '}
                      {showPolicIntakeData
                        ? "info@innovontek.com,  +5124561199"
                        : companyData ? `${companyData.ic_email} ${companyData.ic_mobile}` : ""}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Footer />
    </Box>
  );
};

export default Success;
