import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { Grid,Typography} from '@mui/material';
import carrieradminbgimage from '../assets/carrieradminbgimage.png'
import Carrier_Admin from '../assets/Carrier_Admin.png'
class Company extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
             
           

            <Grid className="carrier-containr">
      <Grid className="carrier-grid">
        <Grid className="carrier-card">
            {/* <img src={Carrier_Admin} alt='Carrier_Admin' /> */}
        </Grid>
        <Grid className="carrier-card" >
        {/* <div  className="carrier-container"> */}
                        <Grid className="carrier-title carrier-Admin billy">Carrier Admin</Grid>
                        <Typography className="carrier-text">Carrier Admin streamlines insurance company registration with easy data input and branding options.Integrated with InnoClaimFNOL for efficient claims processing.
                        Tailored support ensures operational optimization and success in the competitive insurance industry.</Typography>
                    {/* </div> */}
        </Grid>
      </Grid>
      <Grid className="carrier-full-width-card">
      <Grid className='listOfCompanies'>
                    {this.props.children}
                </Grid>
      </Grid>
    </Grid>
           
            <Grid container direction="column" style={{marginTop:'50px'}}>
                <Grid item style={{marginBottom:"2.5%"}}>
                    <Header />
                </Grid>
                <div style={{display:'flex'}}>

                <div container  md={12} >
                 </div>
               
                </div>
                <Grid item>
                    <Footer />
                </Grid>
            </Grid>
            </>
        );
    }
}

export default Company;
