// ClaimsRowsViewCard.js
import PropTypes from "prop-types";

import MDBox from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDBox";
import MDTypography from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDTypography";

// Material Dashboard 2 React context
import { useMaterialUIController } from "../../../CompanyDashboardChartsCardsLayouts/context";

function ClaimsRowsViewCard({ 
  claimnumber, 
  claimCreatedAt, 
  policyHolderName, 
  policyNumber, 
  lossDateAndTime, 
  lossLocation, 
  noGutter 
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <table style={{ width: "100%", tableLayout: "fixed" }}>
        <thead>
          <tr>
            <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Claim Number</th>
            <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Claim Created At</th>
            <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Policy Holder Name</th>
            <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Policy Number</th>
            <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Loss Date and Time</th>
            <th style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>Loss Location</th>
          </tr>
        </thead>
        <tbody>
          <tr> {/* Single row for data */}
            <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claimnumber}</td>
            <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{claimCreatedAt}</td>
            <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{policyHolderName}</td>
            <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{policyNumber}</td>
            <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{lossDateAndTime}</td>
            <td style={{ padding: "8px", textAlign: "left", fontSize: "12px" }}>{lossLocation}</td>
          </tr>
        </tbody>
      </table>
    </MDBox>
  );
}
// ... (defaultProps and propTypes - Update to match new props)

ClaimsRowsViewCard.defaultProps = {
  noGutter: false,
};

ClaimsRowsViewCard.propTypes = {
  claimnumber: PropTypes.string.isRequired,
  claimCreatedAt: PropTypes.string.isRequired,
  policyHolderName: PropTypes.string.isRequired,
  policyNumber: PropTypes.string.isRequired,
  lossDateAndTime: PropTypes.string.isRequired,
  lossLocation: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default ClaimsRowsViewCard;