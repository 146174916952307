import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import { styled } from '@mui/system';
import ITlogo from "../assets/ITlogo.png";
import "./header.css";
import { FaPhoneAlt } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { Grid } from '@mui/material';
import StyledButtonComponent from "../components/StyledButton";
import telephone from '../assets/telephone.png'
import {
  Box, Typography, SvgIcon, useMediaQuery,
  useTheme,
} from '@mui/material';
 
const StyledNavbar = styled(Navbar)(({ theme }) => `
  backgroundColor: 'white',
  
  .navbar-brand {
    display: flex;
    align-items: center;
  }
  .nav-link, .navbar-brand, .nav-dropdown {
    color: #007bff !important;
  }
 
  @media (max-width: 768px) {
    height: auto;
    padding: 1rem;
 
    .navbar-brand img {
      width: 100px;
    }
 
    .nav-link, .nav-dropdown {
      font-size: 0.9rem;
    }
  }
`);
 
const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent:'center',
  alignItems: 'center',
  backgroundColor: '#f8f9fa',
  borderRadius: '50px',
  padding: '8px 16px',
  width: 'fit-content',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: '#e9ecef',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '4px 8px',
  },
}));
 
const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '12px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.3s',
  [theme.breakpoints.down('sm')]: {
    width: '30px',
    height: '30px',
    marginRight: '8px',
  },
}));
 
const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Arial, sans-serif',
  fontSize: '1.2rem',
  lineHeight: '1.2',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}));
 
const PhoneIcon = ({ isHovered }) => (
  <SvgIcon viewBox="0 0 24 24">
    <path
      d="M6.62 10.79c1.44 2.83 3.76 5.15 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"
      fill="#2196f3"
      stroke={isHovered ? "#2196f3" : "none"}
      strokeWidth={isHovered ? "1" : "0"}
    />
  </SvgIcon>
);
 
function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
 
  const isScreen = useMediaQuery('(max-width:900px)');
  const location = useLocation();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState("");
  const Authorization = !!localStorage.getItem("Auth") || !!sessionStorage.getItem("NonInsuredAuth");
  const Privilege = localStorage.getItem("privilege");
 
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);
 
  const checked = (value) => {
    document.title = "policyIntake-signIn"
    localStorage.setItem("rout", value);
    updateTitle(value);
    if (Authorization) {
      window.scrollTo(0, 0);
    }
  };
 
  const updateTitle = (val) => {
    // ... (keep the existing updateTitle function)
  };
 
  useEffect(() => {
    let inactivityTimeoutId;
 
    const resetInactivityTimeout = () => {
      clearTimeout(inactivityTimeoutId);
      inactivityTimeoutId = setTimeout(handleSignOut, 15 * 60 * 1000);
    };
 
    const handleUserActivity = () => {
      resetInactivityTimeout();
    };
 
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('click', handleUserActivity);
 
    resetInactivityTimeout();
 
    return () => {
      clearTimeout(inactivityTimeoutId);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
    };
  }, []);
 
  const handleSignOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("isInsured", "yes");
    navigate('/');
  };
  const [isHovered, setIsHovered] = useState(false);
 const rout = localStorage.getItem('rout')
  return (
    <Box  sx={{
      backgroundColor: 'white',
      height: "13vh",
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 1200, // Ensure the navbar stays on top of other content
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' // Optional: adds shadow for better visibility
    }} >

    <StyledNavbar  fixed="top" expand="lg" sx={{  width: '100%', maxWidth: 1200,margin:'auto',backgroundColor:"white" }}>
    <Navbar.Brand className="logo-my">
      <Link to="/">
        <img src={ITlogo} alt="ITlogo" className="ITlogo" />
      </Link>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="navbarScroll" />
    <Navbar.Collapse id="navbarScroll">
      
      <Nav
        navbarScroll
        className="ms-auto my-5 my-lg-0"
        style={{ alignItems: "center",marginRight:'3rem', padding: '0.5rem 1rem' }}
      >
        <Nav.Link
          as={Link}
          to="/"
          onClick={() => checked("/")}
          active={activeLink === "/"}
          className="Nasaliza color-navlink"
          id="basic-nav-dropdown"
          style={{ marginRight: '1rem' }}
        >
          HOME
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/signin"
          onClick={() => checked("/signin")}
          active={activeLink === "/signin"}
          className="Nasaliza color-navlink"
          id="basic-nav-dropdown"
          style={{ marginRight: '1rem' }}
        >
          Signin
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/policysignIn"
          onClick={() => checked("/policysignIn")}
          active={activeLink === "/policysignIn"}
          className="Nasaliza color-navlink"
          id="basic-nav-dropdown"
          style={{ marginRight: '1rem' }}
        >
          Policy Intake
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/"
          onClick={() => checked("/")}
          active={activeLink === "/"}
          className="Nasaliza color-navlink"
          id="basic-nav-dropdown"
          style={{ marginRight: '1rem' }}
        >
          Claim Intake
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/requestdemo"
          onClick={() => checked("/requestdemo")}
          active={activeLink === "/requestdemo"}
          style={{ marginLeft: '1rem', marginRight: '1rem' }}
        >
          <StyledButtonComponent style={{ margin: "0rem", width: '150px' }} >
            Let’s Connect
          </StyledButtonComponent>
        </Nav.Link>
        <Box
          component="a"
          href="tel:+15134561199"
          sx={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: 'inherit',
            '&:hover': { '& .phoneIcon': { color: 'primary.main' } }
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <PhoneIcon className="phoneIcon" sx={{ mr: 1, transition: 'color 0.3s' }} />
          <Box>
            <Typography variant="caption" display="block">
              Call to Our Experts
            </Typography>
            <Typography variant="body2" fontWeight="bold" color="primary">
              +1 513 456 1199
            </Typography>
          </Box>
        </Box>
      </Nav>
    </Navbar.Collapse>
  </StyledNavbar>
    </Box>
 
  );
}
 
export default Header;