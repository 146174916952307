import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import { styled } from '@mui/system';
import ITlogo from "../assets/ITlogo.png";
import "./header.css";
import { FaPhoneAlt } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { Grid } from '@mui/material';
import StyledButtonComponent from "../components/StyledButton";
import telephone from '../assets/telephone.png'
import {
  Box, Typography, SvgIcon, useMediaQuery,
  useTheme,
} from '@mui/material';

const StyledNavbar = styled(Navbar)(({ theme }) => `
 
 
 
`);

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#f8f9fa',
  borderRadius: '50px',
  padding: '8px 16px',
  width: 'fit-content',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: '#e9ecef',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '4px 8px',
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '12px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.3s',
  [theme.breakpoints.down('sm')]: {
    width: '30px',
    height: '30px',
    marginRight: '8px',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Arial, sans-serif',
  fontSize: '1.2rem',
  lineHeight: '1.2',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}));

const PhoneIcon = ({ isHovered }) => (
  <SvgIcon viewBox="0 0 24 24">
    <path
      d="M6.62 10.79c1.44 2.83 3.76 5.15 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"
      fill="#2196f3"
      stroke={isHovered ? "#2196f3" : "none"}
      strokeWidth={isHovered ? "1" : "0"}
    />
  </SvgIcon>
);

function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isScreen = useMediaQuery('(max-width:996px)');
  const location = useLocation();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState("");
  const Authorization = !!localStorage.getItem("Auth") || !!sessionStorage.getItem("NonInsuredAuth");

  const Privilege = localStorage.getItem("privilege");
  const policyIntakeToken = localStorage.getItem("policyIntakeToken")
  const gettingSignin = localStorage.getItem("rout")
  const userAccess = Authorization ? localStorage.getItem('userAccess') : []

  console.log(userAccess);


  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const checked = (value) => {
    localStorage.setItem("rout", value);
    updateTitle(value);
    if (Authorization) {
      window.scrollTo(0, 0);
    }
  };

  const updateTitle = (val) => {
    // ... (keep the existing updateTitle function)
  };

  useEffect(() => {
    let inactivityTimeoutId;

    const resetInactivityTimeout = () => {
      clearTimeout(inactivityTimeoutId);
      inactivityTimeoutId = setTimeout(handleSignOut, 15 * 60 * 1000);
    };

    const handleUserActivity = () => {
      resetInactivityTimeout();
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('click', handleUserActivity);

    resetInactivityTimeout();

    return () => {
      clearTimeout(inactivityTimeoutId);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
    };
  }, []);

  const handleSignOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("isInsured", "yes");
    navigate('/');
  };
  const [isHovered, setIsHovered] = useState(false);
  const rout = localStorage.getItem('rout')
  const Headerreturn = () => {

  }
  const [showDropdown, setShowDropdown] = useState(false);

  const userCompany = JSON.parse(localStorage.getItem('signinUserDetails'))
  const [showIdpSubmenu, setShowIdpSubmenu] = useState(false);
  console.log(showIdpSubmenu);

  return (

    <Box sx={{
      backgroundColor: 'white',
      height: isScreen ? "auto" : '70px',
      position: 'fixed',
      top: 0,
      width: '100%',
       margin: "auto",
      zIndex: 1200, // Ensure the navbar stays on top of other content
     
    }} >


      <StyledNavbar fixed="top" expand="lg" sx={{ width: '100%', maxWidth: 1500, margin: 'auto', height: isScreen ? "auto" : '70px', backgroundColor: "white" }} >



        <Navbar.Brand className="logo-my">
          <Link to="/">
            <img src={ITlogo} alt="ITlogo" className="ITlogo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">

          <Nav
            navbarScroll
            className="ms-auto my-5 my-lg-0"
            style={{ alignItems: "center", marginRight: '3rem', padding: '0.5rem 1rem' }}
          >
           
            <NavDropdown

              title={
                <span
                  onClick={() => window.location.href = 'https://innovontek.com/'}
                  style={{ cursor: 'pointer' }}
                  className="Nasaliza color-navlink"
                >
                  INNOVONTEK.COM
                </span>
              }
              className="Nasaliza color-navlink"
              id="basic-nav-dropdown"
              style={{ marginRight: '1rem' }}
              show={showDropdown}
              onMouseEnter={() => setShowDropdown(true)}  // Open dropdown on hover
              onMouseLeave={() => setShowDropdown(false)} // Close dropdown when mouse leaves
              onToggle={() => setShowDropdown(!showDropdown)}  // Allow toggle on click
            >
              {/* Guidewire Practice Submenu */}
              <NavDropdown
                title={
                  <span
                    onClick={() => window.location.href = 'https://innovontek.com/guidewire-practice/'}
                    style={{ cursor: 'pointer' }}
                    className="Nasaliza color-navlink"
                  >
                    Guidewire Practice
                  </span>
                }
                id="gw-dropdown"
                drop="end"
                className="Nasaliza color-navlink"
                style={{ color: '#000' }}
                onMouseEnter={(e) => e.target.click()} // To trigger the dropdown on hover
                onMouseLeave={(e) => e.target.blur()}  // To close the dropdown on mouse leave

              >
                <NavDropdown.Item as={Link} className="Nasaliza color-navlink" to="https://innovontek.com/guidewire-practice-services/" style={{ color: '#00' }}>
                  GW SERVICES
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} className="Nasaliza color-navlink" to="https://innovontek.com/offerings/" style={{ color: '#00' }}>
                  GW OFFERINGS
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} className="Nasaliza color-navlink" to="https://innovontek.com/about-guidewire-practice/" style={{ color: '#00' }}>
                  ABOUT GW PRACTICE
                </NavDropdown.Item>
              </NavDropdown>

              {/* Mainframe Practice Submenu */}
              <NavDropdown
                title={
                  <span
                    onClick={() => window.location.href = 'https://innovontek.com/mainframe-practice/'}
                    style={{ cursor: 'pointer' }}
                    className="Nasaliza color-navlink"
                  >
                    Mainframe Practice
                  </span>
                }

                id="mf-dropdown"
                drop="end" // Opens the dropdown to the right
                className="Nasaliza color-navlink"
                style={{ color: '#000' }}
                onMouseEnter={(e) => e.target.click()} // To trigger the dropdown on hover
                onMouseLeave={(e) => e.target.blur()}  // To close the dropdown on mouse leave
              >
                <NavDropdown.Item as={Link} to="https://innovontek.com/services-mainframe-practice/" className="Nasaliza color-navlink" style={{ color: '#00' }}>
                  MF SERVICES
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="https://innovontek.com/offerings-mainframe-practice/" className="Nasaliza color-navlink" style={{ color: '#00' }}>
                  MF OFFERINGS
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="https://innovontek.com/about-mainframe-practice/" className="Nasaliza color-navlink" style={{ color: '#00' }}>
                  ABOUT MF PRACTICE
                </NavDropdown.Item>
              </NavDropdown>

              {/* Services Submenu */}
              <NavDropdown

                title={
                  <span
                    // onClick={() => window.location.href = 'https://innovontek.com/services/'} 
                    style={{ cursor: 'pointer' }}
                    className="Nasaliza color-navlink"
                  >
                    Services
                  </span>
                }
                id="services-dropdown"
                drop="end" // Opens the dropdown to the right
                className="Nasaliza color-navlink"
                style={{ color: '#000' }}
                onMouseEnter={(e) => e.target.click()} // To trigger the dropdown on hover
                onMouseLeave={(e) => e.target.blur()}  // To close the dropdown on mouse leave
              >
                <NavDropdown.Item as={Link} to="https://innovontek.com/services/systems-integration/" className="Nasaliza color-navlink" style={{ color: '#00' }}>
                  SYSTEMS INTEGRATION
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="https://innovontek.com/services/application-services/" className="Nasaliza color-navlink" style={{ color: '#00' }}>
                  APPLICATION SERVICES
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="https://innovontek.com/services/managed-it-services/" className="Nasaliza color-navlink" style={{ color: '#00' }}>
                  MANAGED IT SERVICES
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="https://innovontek.com/services/quality-assurance/" className="Nasaliza color-navlink" style={{ color: '#00' }}>
                  QUALITY ASSURANCE
                </NavDropdown.Item>
              </NavDropdown>

              {/* Solutions Submenu */}
              <NavDropdown

                title={
                  <span
                    onClick={() => window.location.href = 'https://innovontek.com/solutions/'}
                    style={{ cursor: 'pointer', }}
                    className="Nasaliza color-navlink"
                  >
                    Solutions
                  </span>
                }
                id="solutions-dropdown"
                drop="end" // Opens the dropdown to the right
                className="Nasaliza color-navlink"
                style={{ color: '#000' }}
                onMouseEnter={(e) => e.target.click()} // To trigger the dropdown on hover
                onMouseLeave={(e) => e.target.blur()}  // To close the dropdown on mouse leave
              >
                <NavDropdown.Item as={Link} to="https://innovontek.com/solutions/p-c-insurance-solutions/" className="Nasaliza color-navlink" style={{ color: '#00' }}>
                  P&C INSURANCE SOLUTIONS
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="https://innovontek.com/solutions/digital-transformation/" className="Nasaliza color-navlink" style={{ color: '#00' }}>
                  DIGITAL TRANSFORMATION
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="https://innovontek.com/solutions/it-cloud-modernization/" className="Nasaliza color-navlink" style={{ color: '#00' }}>
                  IT & CLOUD MODERNIZATION
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="https://innovontek.com/solutions/intelligent-automation/" className="Nasaliza color-navlink" style={{ color: '#00' }}>
                  INTELLIGENT AUTOMATION
                </NavDropdown.Item>
              </NavDropdown>

              {/* About Submenu */}
              <NavDropdown

                title={
                  <span
                    onClick={() => window.location.href = 'https://innovontek.com/about/'}
                    style={{ cursor: 'pointer', }}
                    className="Nasaliza color-navlink"
                  >
                    About
                  </span>
                }
                id="about-dropdown"
                drop="end" // Opens the dropdown to the right
                className="Nasaliza color-navlink"
                style={{ color: '#000' }}
                onMouseEnter={(e) => e.target.click()} // To trigger the dropdown on hover
                onMouseLeave={(e) => e.target.blur()}  // To close the dropdown on mouse leave
              >
                <NavDropdown.Item as={Link} className="Nasaliza color-navlink" to="https://innovontek.com/careers/" style={{ color: '#00' }}>
                  CAREERS
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} className="Nasaliza color-navlink" to="https://innovontek.com/blog/" style={{ color: '#00' }}>
                  BLOG
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} className="Nasaliza color-navlink" to="https://innovontek.com/contact/" style={{ color: '#00' }}>
                  CONTACT
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} className="Nasaliza color-navlink" to="https://innovontek.com/faqs/" style={{ color: '#00' }}>
                  FAQS
                </NavDropdown.Item>
              </NavDropdown>
            </NavDropdown>




            <NavDropdown
              title="PORTAL"
              className="Nasaliza color-navlink"
              id="basic-nav-dropdown"
              active={activeLink === "/SmartFNOL" || activeLink === "/customercompany"}
              style={{ marginRight: '1rem' }}
            >
              {(userAccess.includes('claim_intake') || !Authorization) && (
                <NavDropdown.Item
                  className="Nasaliza color-navlink"
                  as={Link}
                  to="/SmartFNOL"
                  onClick={() => checked("/claimcapture")}
                  active={activeLink === "/claimcapture"}
                >
                  SmartFNOL
                </NavDropdown.Item>
              )}



              {(userAccess.includes('policy_intake') || !Authorization) && (

                <NavDropdown.Item
                  className="Nasaliza color-navlink"
                  as={Link}
                  to={"/SmartQuote"}
                  onClick={() => checked("/SmartQuote")}
                  active={activeLink === "/SmartQuote"}
                  style={{ marginRight: '1rem' }}
                >
                  SmartQuote
                </NavDropdown.Item>



              )}



            </NavDropdown>


            <NavDropdown
              title="DocAI"
              className="Nasaliza color-navlink"
              id="basic-nav-dropdown"
              active={activeLink === "/DocAI/FNOL" || activeLink === "/login"}
              style={{ marginRight: '1rem' }}
            >
              {(userAccess.includes('claim_intake') || !Authorization) && (
                <NavDropdown.Item
                  className="Nasaliza color-navlink"
                  as={Link}
                  to="/DocAI/FNOL"
                  onClick={() => checked("/fileextract")}
                  active={activeLink === "/fileextract"}
                  style={{ marginRight: '1rem' }}
                >
                  DocAI FNOL
                </NavDropdown.Item>
              )}
              {(userAccess.includes('policy_intake') || !Authorization) && (
                <NavDropdown.Item
                  as={Link}
                  to="/DocAIQuote"
                  onClick={() => checked("/DocAIQuote")}
                  active={activeLink === "/DocAIQuote"}
                  className="Nasaliza color-navlink"
                >
                  DocAI Quote
                </NavDropdown.Item>
              )}


              <NavDropdown.Item
                as={Link}
                to="/DocAI/IDCardExtraction"
                onClick={() => checked("/DocAI/IDCardExtraction")}
                active={activeLink === "/DocAI/IDCardExtraction"}
                className="Nasaliza color-navlink"
              >
                DocAI ID Card
              </NavDropdown.Item>

              {(userAccess.includes('loss_runs') || !Authorization) && (
                <NavDropdown.Item
                  as={Link}
                  to="/DocAIlossrunreport"
                  onClick={() => checked("/DocAIlossrunreport")}
                  active={activeLink === "/DocAIlossrunreport"}
                  className="Nasaliza color-navlink"
                >
                  DocAI Loss Run
                </NavDropdown.Item>
              )}
              <NavDropdown.Item
                as={Link}
                to="/DocAIMedBill"
                onClick={() => checked("/DocAIMedBill")}
                active={activeLink === "/DocAIMedBill"}
                className="Nasaliza color-navlink"
              >
                DocAI Med Bill
              </NavDropdown.Item>
              {(userAccess.includes('claim_intake') || !Authorization) && (
                <NavDropdown.Item
                  as={Link}
                  to="/Mail2Loss"
                  onClick={() => checked("/Mail2Loss")}
                  active={activeLink === "/Mail2Loss"}
                  className="Nasaliza color-navlink"
                >
                  Mail2Loss
                </NavDropdown.Item>
              )}
              {(userAccess.includes('policy_intake') || !Authorization) && (
                <NavDropdown.Item
                  as={Link}
                  to="/Mail2Quote"
                  onClick={() => checked("/Mail2Quote")}
                  active={activeLink === "/Mail2Quote"}
                  className="Nasaliza color-navlink"
                >
                  Mail2Quote
                </NavDropdown.Item>
              )}
            </NavDropdown>



            {(userAccess.includes('claim_intake') || !Authorization) && (
              <Nav.Link
                as={Link}
                to="/InsurAI"
                onClick={() => checked("/InsurAI")}
                active={activeLink === "/InsurAI"}
                id="basic-nav-dropdown"
                className="Nasaliza color-navlink"

              >
                IVAN
              </Nav.Link>
            )}
            {(!Authorization || userCompany.company_name) && (
              <Nav.Link
                as={Link}
                to="/InsurAdminPlatform"
                onClick={() => checked("/InsurAdminPlatform")}
                active={activeLink === "/InsurAdminPlatform"}
                id="basic-nav-dropdown"
                className="Nasaliza color-navlink"
                style={{ marginLeft: '0.5rem', marginRight: "1rem" }}

              >
                InsurAdmin Platform
              </Nav.Link>
            )}
            {(userAccess.includes('user_administration') || userAccess.includes('company_Dashboard') || userAccess.includes('companies_administration')) && (
              <NavDropdown
                title="Admin"
                className="Nasaliza color-navlink"
                id="basic-nav-dropdown"

                style={{ marginRight: '0.5rem', marginLeft: '1rem' }}
              >
                {(userAccess.includes('user_administration')) && (
                  <NavDropdown.Item
                    as={Link}
                    to="/UserDashboard"
                    onClick={() => checked("/UserDashboard")}
                    active={activeLink === "/UserDashboard"}
                    className="Nasaliza color-navlink"
                  >
                    User Admin
                  </NavDropdown.Item>

                )}
                <NavDropdown.Item
                  className="Nasaliza color-navlink"
                  as={Link}
                  to={
                    Authorization
                      ? userAccess.includes('companies_administration')
                        ? "/InsurAdminPlatform"
                        : userAccess.includes('company_Dashboard')
                          ? "/dashboard/*"
                          : "/requestdemo"
                      : "/requestdemo"
                  }
                  onClick={() => {
                    const path = Authorization
                      ? userAccess.includes('companies_administration')
                        ? "/InsurAdminPlatform"
                        : userAccess.includes('company_Dashboard')
                          ? "/dashboard/*"
                          : "/requestdemo"
                      : "/requestdemo";
                    checked(path);
                  }}
                  active={
                    activeLink === (
                      Authorization
                        ? userAccess.includes('companies_administration')
                          ? "/InsurAdminPlatform"
                          : userAccess.includes('company_Dashboard')
                            ? "/dashboard/*"
                            : "/requestdemo"
                        : "/requestdemo"
                    )
                  }
                >
                  {userAccess.includes('company_Dashboard') ? "Insurance Companies Admin" : "InsurAdmin Platform"}
                </NavDropdown.Item>


              </NavDropdown>
            )}
            {(Authorization || policyIntakeToken) && (
              <Nav.Link

                onClick={() => {
                  handleSignOut();
                  checked("/");
                }}
                id="basic-nav-dropdown"
                active={activeLink === "/"}
                className="Nasaliza color-navlink"
                style={{ marginRight: '1rem' }}
              >
                SIGN OUT
              </Nav.Link>
            )}
            <Nav.Link
              as={Link}
              to="/requestdemo"
              onClick={() => checked("/requestdemo")}
              active={activeLink === "/requestdemo"}
              style={{ marginLeft: '1rem', marginRight: '1rem' }}
            >
              <StyledButtonComponent style={{ margin: "0rem", width: '150px' }} >
                Let’s Connect
              </StyledButtonComponent>
            </Nav.Link>
            <Box
              component="a"
              href="tel:+15134561199"
              sx={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                color: 'inherit',
                '&:hover': { '& .phoneIcon': { color: 'primary.main' } }
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <PhoneIcon className="phoneIcon" sx={{ mr: 1, transition: 'color 0.3s' }} />
              <Box>
                <Typography variant="caption" display="block">
                  Call Our Experts
                </Typography>
                <Typography variant="body2" fontWeight="bold" color="primary">
                  +1 513 456 1199
                </Typography>
              </Box>
            </Box>
          </Nav>
        </Navbar.Collapse>


      </StyledNavbar>
    </Box>


  );
}

export default Header;