import React, { useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Upload } from 'lucide-react';
import { GoogleGenerativeAI } from "@google/generative-ai";
import { Await, Link, useNavigate } from "react-router-dom";
import axios from 'axios';
 
import * as XLSX from 'xlsx';
 
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  Container,
  CardContent,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Grid, Card
} from '@mui/material';
 
import { styled } from '@mui/system';
 
import FileUpload from '../../components/fileupload';
import processclaim from "../../assets/processclaim.png";
import { css } from "@emotion/css";
 
 
function ExtractedDataDisplay({ displayValues, subTotalsPolicyData, subTotalsData }) {
  console.log("displyvalues", displayValues, subTotalsPolicyData);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const formatValue = (key, value) => {
    if (key === "Total Claims") return value;
    if (value === null) return 'N/A';
    if (value === 0 || value === '0') return '$0';
    return `$${value}` || 'N/A';
  };

  const tableVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const rowVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <TableContainer component={Paper} style={{ boxShadow: 'none', background: 'transparent', height: '100%', overflow: 'auto' }}>
      {subTotalsPolicyData && Object.keys(subTotalsPolicyData).length > 0 && (
        <div style={{ marginTop: '2rem', textAlign: "left" }}>
          {Object.entries(subTotalsPolicyData).map(([key, value]) => (
            value !== null && value !== "" && (!Array.isArray(value) || value.length > 0) && (
              <Typography key={key} variant="body2" >
                <strong>{key} : </strong> {Array.isArray(value) ? value.join(', ') : value}<br />
              </Typography>
            )
          ))}
        </div>
      )}
      <Table size={isMobile ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold', color: '#1976D2', borderBottom: '2px solid #1976D2' }}>Attribute</TableCell>
            <TableCell style={{ fontWeight: 'bold', color: '#1976D2', borderBottom: '2px solid #1976D2' }}>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody component={motion.tbody} variants={tableVariants} initial="hidden" animate="visible">
          {Object.entries(displayValues).map(([key, value]) => (
            <AnimatedTableRow key={key} variants={rowVariants}>
              <TableCell>{key}</TableCell>
              <TableCell>{formatValue(key, value)}</TableCell>
            </AnimatedTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ExtractedDataDisplay1({ subTotalsData }) {
  console.log("displyvalues", subTotalsData);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const formatValue = (key, value) => {
    if (key === "Total Claims") return value;
    if (value === null) return 'N/A';
    if (value === 0 || value === '0') return '$0';
    return value ? `$${value}` : 'N/A';
  };



  const tableVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const rowVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <TableContainer component={Paper} style={{ boxShadow: 'none', background: 'transparent', height: '100%', overflow: 'auto' }}>
      <Table size={isMobile ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold', color: '#1976D2' }}>Type of LOB</TableCell>
            <TableCell style={{ fontWeight: 'bold', color: '#1976D2' }}>Total Claims</TableCell>
            <TableCell style={{ fontWeight: 'bold', color: '#1976D2' }}>Total Loss</TableCell>
            <TableCell style={{ fontWeight: 'bold', color: '#1976D2' }}>Total Reserved Amounts</TableCell>
          </TableRow>
        </TableHead>
        <TableBody component={motion.tbody} variants={tableVariants} initial="hidden" animate="visible">
          {Object.entries(subTotalsData).map(([lob, data]) => (
            console.log("lob", lob, "data", data, data['Total_Loss'], data['Total_Reserved_Amounts']),
            typeof data === 'object' && (
              <AnimatedTableRow key={lob} variants={rowVariants}>
                <TableCell>{lob}</TableCell>
                <TableCell>{data['No.of.Claims'] || 0}</TableCell>
                <TableCell>{formatValue(null, data['Total_Loss'])}</TableCell>
                <TableCell>{formatValue(null, data['Total_Reserved_Amounts'])}</TableCell>
              </AnimatedTableRow>
            )
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
const GlassyCard = styled(motion.div)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.8)',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(3),
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 48px 0 rgba(31, 38, 135, 0.3)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  justifyContent: "center",
  textAlign: "center",
  width: 200,
  background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
  border: 0,
  borderRadius: 20,
  boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  color: 'white',
  height: 40,
  padding: '0 2px',
  margin: "2rem auto",
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  fontSize: '0.875rem',
  '&:hover': {
    background: 'linear-gradient(45deg, #1976D2 30%, #00BCD4 90%)',
    transform: 'scale(1.05)',
    boxShadow: '0 6px 10px 4px rgba(33, 203, 243, .4)',
  },
}));


const AnimatedTableRow = motion(TableRow);
const styles = {
  container: css`
      padding: 2rem;
      text-align: center;
      height: 70vh;
     
    `,
  fadeIn: css`
      animation: fadeIn 2s ease-in-out;
    `,
  uploading: css`
      margin-top: 2rem;
      animation: fadeIn 2s ease-in-out;
    `,
  scanningContainer: css`
      margin-top: 2rem;
      position: relative;
      width: 300px;
      height: 400px;
      background-color: #e0e0e0;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
    `,
  scanningEffect: css`
      position: relative;
      width: 100%;
      height: 100%;
    `,
  scanLine: css`
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: green;
      animation: scanAnimation 2s linear infinite;
    `,
  jsonData: css`
      margin-top: 2rem;
      padding: 1rem;
      background-color: #e0f7fa;
      text-align: left;
    `,
};

export default function LossRunReportsFun() {

  const [scanning, setScanning] = useState(false);
  const [aiResponse, setAiResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [correctData, setCorrectData] = useState({});
  const [displayValues, setDisplayValues] = useState({});
  const [subTotalsPolicyData, setSubTotalsPolicyData] = useState({});
  const [subTotalsData, setSubTotalsData] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [afterProcess, setAfterProcess] = useState(false);
  const [previews, setPreviews] = useState([]);
  const [uploadIn] = useState("portal");
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const genAI = new GoogleGenerativeAI(API_KEY);
  const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });
  const [selectedFile, setSelectedFile] = useState(null);

  const [uploading, setUploading] = useState(false);
  const [jsonData, setJsonData] = useState(null);
  const Authorization = !!localStorage.getItem("Auth") || !!sessionStorage.getItem("NonInsuredAuth");
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
 
 
 
  const BeforeProcessDisplayValues = {
    "Policy holder Name":"name",
    "List of Unique Policy Numbers":'123455',
    "SAI_Number":'2333',
    "Total Claims": 0,
    "Total Incurred Claim Amount": "0",
    "Total Incurred Medical Losses": "0",
    "Total Incurred Expenses": "0",
    "Total Loss Amount": "0",
    "Average Loss Per Claim": "0",
    "Total Reserved Amount": "0",
  };
  useEffect(() => {
    setIsSubmitDisabled(!selectedFile);
  }, [selectedFile]);
 
  const handleFilesUploadToLossRunReport = (selectedFiles, previews) => {
    setSelectedFile(selectedFiles[0]);
    setPreviews(previews);
    setIsSubmitDisabled(false);
  };
 
  const handleFileRemove = (fileName) => {
    setAfterProcess(false);
    setLoading(false);
    setSelectedFile(null);
    setPreviews([]);
    setIsSubmitDisabled(true);
  };
  const checked = (val) => {
    if (!Authorization) {
      window.scrollTo(0, 0);
      localStorage.setItem("rout", val);
    } else {
      window.scrollTo(0, 0);
    }
  };
  const processImage = async () => {  
    if (!selectedFile) {
      alert("Please select a file first.");
      return;
    }
 
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      const uploadResponse = await fetch(`${process.env.REACT_APP_URL}AI/process_files/`, { 
        method: 'POST',
        body: formData,
      });


      if (!uploadResponse.ok) {
        throw new Error(`HTTP error! status: ${uploadResponse.status}`);
      }
 
      const uploadData = await uploadResponse.json();
      const base64Image = uploadData.image;
      const extractedFileData = uploadData.extracted_text;
      let prompt, requestData, mimeType, geminiText, geminiText1, geminiText2, prompt1, prompt2;
      console.log(formData,uploadData,'format');
      if (base64Image) {

        prompt = `Extract Total No.of Claims(integer), list of Claim Amounts (Dont Include Total), and list of Reserved amounts (Dont Include Total),list of Medical Losses (Dont Include Total),list of Additional Expenses not claim Amounts (Dont Include Total),  if there or else just respond as 0`;
        requestData = base64Image;
        mimeType = selectedFile.type;
        const geminiResult = await model.generateContent([
          prompt,
          {
            inlineData: {
              data: requestData,
              mimeType: mimeType,
            }
          }
        ]);
 
        const geminiResponse = await geminiResult.response;
        geminiText = geminiResponse.text();
 
        prompt2 = `Please extract the following from the given extracted text:
            1. Policy holder Name: (If available)
            2. List of Unique Policy Numbers: [x1,x2,x3...] (if available)
            3. SAI_Number: (If available)
            Only provide the output if the values are explicitly provided please dont assume things by yourself and provide wrong results, read the whole extracted atleast 5 times before providing answers, accuracy is important here!
 
            Extracted text:${extractedFileData}`;
        requestData = base64Image;
        mimeType = selectedFile.type;
        const geminiResult2 = await model.generateContent([
          prompt2,
          {
            inlineData: {
              data: requestData,
              mimeType: mimeType,
            }
          }
        ]);
 
        const geminiResponse2 = await geminiResult2.response;
        geminiText2 = geminiResponse2.text();
 
        const structureFormData = new FormData();
        structureFormData.append("Grand_totals", geminiText);
        structureFormData.append("Sub_totals", geminiText2);
        const structureResponse = await axios.post(`${process.env.REACT_APP_URL}AI/parse_text/`, structureFormData);
        console.log(structureResponse, structureResponse.data);

        setCorrectData(structureResponse.data.output);
 
        setDisplayValues({
         
          "Total Claims": structureResponse.data.grand_totals_json['No.of.claims'],
          "Total Incurred Claim Amount": structureResponse.data.grand_totals_json.Total_Incurred_Claim_Amount,
          "Total Incurred Medical Losses": structureResponse.data.grand_totals_json.Total_Incurred_Medical_Losses,
          "Total Incurred Expenses": structureResponse.data.grand_totals_json.Total_Incurred_Expenses,
          "Total Loss Amount": structureResponse.data.grand_totals_json.Total_Loss_Amount,
          "Average Loss Per Claim": structureResponse.data.grand_totals_json.Average_Loss_per_claim,
          "Total Reserved Amount": structureResponse.data.grand_totals_json.Total_Reserved_Amounts,
        });
 
        // Extract relevant data for sub-totals display
        const policyHolderName = structureResponse.data.policy_holder_name;
        const saiNumber = structureResponse.data.sai_number;
        const policyNumbers = structureResponse.data.policy_numbers_list;
 
        setSubTotalsPolicyData({
          'Policy Holder Name': policyHolderName,
          'SAI Number': saiNumber,
          'Policy Numbers List': policyNumbers,
        });
 
        setSubTotalsData(structureResponse.data.sub_totals_json);
        setLoading(false);
        setAfterProcess(true);
        setLoading(false);
        setAfterProcess(true);
      }
      else if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        selectedFile.type === 'application/vnd.ms-excel') {
 
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const excelText = XLSX.utils.sheet_to_txt(worksheet);
 
          prompt1 = `Analyze the following extracted text and provide a structured response with these specific details:
 
        1. Number of Claims
        2. Total Incurred Loss Amount (Total Loss)
        3. Total Incurred Claim Amount
        4. Total Incurred Medical Losses
        5. Total Incurred Expenses
        6. Total Reserved Amounts
 
        Instructions:
        - Check for Grand Totals in the extracted Text and extract in this format:
          1. Number of Claims: [value]
          2. Total (INCURRED/INC): [value]
          3. Total Claim (INCURRED/INC): [value]
          4. Total Medical (INCURRED/INC): [value]
          5. Total Expenses (INCURRED/INC): [value]
          6. Total Reserved Amounts (O/S): [value]
 
        - Only Provide the values under Grand Totals, Please dont provide any values above grand totals and if you dont
        find any value Just provide "0" inplace of it.
        - If you got all these five details (Number of Claims,Total Incurred Claim Amount,Total Incurred Medical Losses,Total Incurred Expenses, Total Reserved Amounts) dont provide anything else!
        We just need these details! please dont provide anything else.
 
        Extracted_data:
        ${extractedFileData}`;
 
          const result1 = await model.generateContent(prompt1);
          const response1 = result1.response;
          geminiText1 = response1.text();
 
          console.log("text 1", geminiText1);
 
          prompt2 = `Please extract the following details from the provided text. Only include values that are explicitly mentioned. Ensure that no assumptions are made, and review the text thoroughly for accuracy before providing the output. Be sure to identify keys even if they appear in uppercase, lowercase, or have gaps between characters. Accuracy is critical.

1. Policy Holder Name: value (if available)
2. List of Unique Policy Numbers: value in the format [x1, x2, x3, ...] (if available)
3. SAI Number: value (if available)

Ensure that you can recognize and match keys even if they are written in different formats, such as:
- Uppercase or lowercase (e.g., POLICY HOLDER NAME or policy holder name,Policyholder Name)
- With gaps or extra spaces (e.g., P O L I C Y H O L D E R N A M E)

Make sure to read the entire document at least 5 times before extracting the details.

Extracted Text: ${extractedFileData}`;
 
          const result2 = await model.generateContent(prompt2);
          const response2 = result2.response;
          geminiText2 = response2.text();
          console.log("text 2", geminiText2);
 
          const structureFormData = new FormData();
          structureFormData.append("Grand_totals", geminiText1);
          structureFormData.append("Sub_totals", geminiText2);
          const structureResponse = await axios.post(`${process.env.REACT_APP_URL}AI/parse_text/`, structureFormData);
          console.log("structureResponse", structureResponse.data);
          setCorrectData(structureResponse.data.grand_totals_json);
 
          setDisplayValues({
            "Policy holder Name":structureResponse.data.policy_holder_name,
           "Policy Numbers": structureResponse.data.policy_numbers_list,
           "SaiNumber" : structureResponse.data.sai_number,
            "Total Claims": structureResponse.data.grand_totals_json['No.of.claims'],
            "Total Incurred Claim Amount": structureResponse.data.grand_totals_json.Total_Incurred_Claim_Amount,
            "Total Incurred Medical Losses": structureResponse.data.grand_totals_json.Total_Incurred_Medical_Losses,
            "Total Incurred Expenses": structureResponse.data.grand_totals_json.Total_Incurred_Expenses,
            "Total Loss Amount": structureResponse.data.grand_totals_json.Total_Loss_Amount,
            "Average Loss Per Claim": structureResponse.data.grand_totals_json.Average_Loss_per_claim,
            "Total Reserved Amount": structureResponse.data.grand_totals_json.Total_Reserved_Amounts,
          });
 
          // Extract relevant data for sub-totals display
          const policyHolderName = structureResponse.data.policy_holder_name;
          const saiNumber = structureResponse.data.sai_number;
          const policyNumbers = structureResponse.data.policy_numbers_list;
 
          setSubTotalsPolicyData({
            'Policy Holder Name': policyHolderName,
            'SAI Number': saiNumber,
            'Policy Numbers List': policyNumbers,
          });
 
          setSubTotalsData(structureResponse.data.sub_totals_json);
          setLoading(false);
          setAfterProcess(true);
 
        }
 
        reader.readAsArrayBuffer(selectedFile);
 
      }
      else {
        prompt1 = `Analyze the following extracted text and provide a structured response with these specific details:
 
        1. Number of Claims
        2. Total Incurred Loss Amount (Total Loss)
        3. Total Incurred Claim Amount
        4. Total Incurred Medical Losses
        5. Total Incurred Expenses
        6. Total Reserved Amounts
 
        Instructions:
        - Check for Grand Totals in the extracted Text and extract in this format:
          1. Number of Claims: [value]
          2. Total (INCURRED/INC): [value]
          3. Total Claim (INCURRED/INC): [value]
          4. Total Medical (INCURRED/INC): [value]
          5. Total Expenses (INCURRED/INC): [value]
          6. Total Reserved Amounts (O/S): [value]
 
        - Only Provide the values under Grand Totals, Please dont provide any values above grand totals and if you dont
        find any value Just provide "0" inplace of it.
        - If you got all these five details (Number of Claims,Total Incurred Claim Amount,Total Incurred Medical Losses,Total Incurred Expenses, Total Reserved Amounts) dont provide anything else!
        We just need these details! please dont provide anything else.
 
        Extracted_data:
        ${extractedFileData}`;
 
        const result1 = await model.generateContent(prompt1);
        const response1 = result1.response;
        geminiText1 = response1.text();
 
        console.log("text 1", geminiText1);
 
        prompt2 = `From the provided extracted text, please extract the following information:
            For each year where subtotal data is explicitly present:
            Policy_holder Name (Don't Repeat just in the starting)
            List of all the policy numbers (Don't repeat just in the starting) (Only If available and explicitly mentioned) (Please dont provide wrong policy numbers, only provide it they are mentioned explicitly or else just respond with [])
            SAI Number (Only If available and explicitly mentioned)
            List the subtotals for each line of insurance, including:
                a. Number of claims
                b. Total Amount (INCURRED/INC)(Aggregation of all losses(Total INC) Not loss with out all the expenses included) please dont include Claim Amount, only Total.
                c. Total Reserved Amount (O/S)
            Critical instructions:
            Only include years and data that are explicitly stated in the extracted text.
            Do not generate, infer, calculate, or estimate any values not directly provided in the text.
            If subtotal data is missing for any year or line of insurance, do not include it.
            Do not provide data for any years beyond what is explicitly mentioned in the text.
            If a piece of information (number of claims, total amount, or reserved amount) is missing for a subtotal, omit that item entirely.
            If no years with complete subtotal information are found, clearly state that no qualifying data is available in the extracted text.
            Presentation format:
            Organize the information by year.
            Use a list format, not a table format.
            Only provide subtotals based on the line of insurance within each year, not year-wise totals.
            Start each year's data with a clear heading (e.g., "Year: 2015").
            Example format (use only if data is present):
            - Policy_holder Name (Don't Repeat just in the starting)
            - SAI Number (Only If available and explicitly mentioned)
            - List of all the unique policy numbers: [x1,x2..etc] (Don't repeat just in the starting) (Only If available and explicitly mentioned)(Please dont provide wrong policy numbers, only provide it they are mentioned explicitly or else just respond with [])
            Then :
            Year: [YYYY]
            [Line of Insurance]:
            Number of claims: [X] (if available)
            Total Amount (INCURRED/INC)(Aggregation of all losses(Total INC) Not loss with out all the expenses included)
            Total Reserved Amount (O/S): [Z] (if available)
            Remember: Your response should only contain information explicitly stated in the extracted text. Do not make any assumptions or provide any data that isn't clearly present in the given text.
 
            extracted_text: ${extractedFileData}`;
 
        const result2 = await model.generateContent(prompt2);
        const response2 = result2.response;
        geminiText2 = response2.text();
        console.log("text 2", geminiText2);
 
        const structureFormData = new FormData();
        structureFormData.append("Grand_totals", geminiText1);
        structureFormData.append("Sub_totals", geminiText2);
        const structureResponse = await axios.post(`${process.env.REACT_APP_URL}AI/parse_text/`, structureFormData);
        console.log("structureResponse", structureResponse.data);
        setCorrectData(structureResponse.data.grand_totals_json);
 
        setDisplayValues({
          "Total Claims": structureResponse.data.grand_totals_json['No.of.claims'],
          "Total Incurred Claim Amount": structureResponse.data.grand_totals_json.Total_Incurred_Claim_Amount,
          "Total Incurred Medical Losses": structureResponse.data.grand_totals_json.Total_Incurred_Medical_Losses,
          "Total Incurred Expenses": structureResponse.data.grand_totals_json.Total_Incurred_Expenses,
          "Total Loss Amount": structureResponse.data.grand_totals_json.Total_Loss_Amount,
          "Average Loss Per Claim": structureResponse.data.grand_totals_json.Average_Loss_per_claim,
          "Total Reserved Amount": structureResponse.data.grand_totals_json.Total_Reserved_Amounts,
        });
 
        // Extract relevant data for sub-totals display
        const policyHolderName = structureResponse.data.policy_holder_name;
        const saiNumber = structureResponse.data.sai_number;
        const policyNumbers = structureResponse.data.policy_numbers_list;
 
        setSubTotalsPolicyData({
          'Policy Holder Name': policyHolderName,
          'SAI Number': saiNumber,
          'Policy Numbers List': policyNumbers,
        });
 
        setSubTotalsData(structureResponse.data.sub_totals_json);
        setLoading(false);
        setAfterProcess(true);
 
      }
 
 
 
    } catch (error) {
      console.error("Error processing image:", error);
      setAiResponse("An error occurred while processing the image.");
    }
  };
 
  const handleReset = () => {
    setAfterProcess(false);
    setDisplayValues({});
    setSelectedFile(null);
    setPreviews([]);
    setAiResponse('');
    setCorrectData({});
    setIsSubmitDisabled(true);
  };
 
  const renderPreview = (file) => {
    console.log("file in render preview",file) 
    const actualFile = file[0] ? file[0].file : null;
    console.log(actualFile);
    const fileUrl = URL.createObjectURL(file);
    if (file.type.includes('image')) {
      return (
        <img
          src={fileUrl}
          alt="Preview"
          style={{ width: '100%', height: '600px', objectFit: 'contain' }}
        />
      );
    } else if (file.type === 'application/pdf') {
      return (
        <iframe
          src={fileUrl}
          style={{ width: '100%', height: '600px', objectFit: 'contain' }}
          frameBorder="0"
        />
      );
    } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel') {
      return <ExcelPreview file={file} />;
    } else {
      return <Typography variant="body2">Preview not available</Typography>;
    }
  };
  const preview = useMemo(() => {
    return selectedFile ? renderPreview(selectedFile) : null;
  }, [selectedFile]);
  function ExcelPreview({ file }) {
    const [excelData, setExcelData] = useState(null);
 
    useEffect(() => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        setExcelData(json);
      };
      reader.readAsArrayBuffer(file);
    }, [file]);
 
    return (
      // Removed Tilt and motion.div
      <div style={{ width: '100%', height: '600px', overflow: 'auto' }}>
        {excelData && (
          <Table>
            <TableBody>
              {excelData.map((row, index) => (
                <TableRow key={index}>
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex}>{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    );
  }

  return <>


    <Grid container spacing={4} style={{ marginTop: '2rem' }}>
      <AnimatePresence>
        {
          Authorization ? (
            afterProcess ? (
              <>
                <Grid container spacing={3} style={{ marginTop: "2rem" }}>
                  <Grid item xs={12} md={1}></Grid>
                  <Grid item xs={12} md={5} style={{ margin: isMobile ? "0rem 2rem" : "0rem" }}>
                    <GlassyCard
                      initial={{ opacity: 0, x: -100 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -100 }}
                      transition={{ duration: 0.5 }}
                    >
                      {preview}
                      <StyledButton onClick={handleReset} startIcon={<Upload />}>
                        Reupload
                      </StyledButton>
                    </GlassyCard>
                  </Grid>
                  <Grid item xs={12} md={5} style={{ margin: isMobile ? "0rem 2rem" : "0rem" }}>
                    <GlassyCard
                      initial={{ opacity: 0, x: 100 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: 100 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Typography className="Nasaliza" variant="h6" style={{ color: "#010066" }}>
                        Extracted Loss run Data
                      </Typography>
                      <ExtractedDataDisplay
                        displayValues={displayValues}
                        subTotalsPolicyData={subTotalsPolicyData}
                        subTotalsData={subTotalsData}
                      />
                    </GlassyCard>
                  </Grid>
                  <Grid item xs={12} md={1}></Grid>

                  <Grid item xs={12} md={1}></Grid>
                  {Object.keys(subTotalsData).length > 0 && (
                    <Grid item xs={12} md={10}>
                      <GlassyCard
                        initial={{ opacity: 0, x: 100 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: 100 }}
                        transition={{ duration: 0.5 }}
                      >

                        <ExtractedDataDisplay1 subTotalsData={subTotalsData} />

                      </GlassyCard>
                    </Grid>
                  )
                  }
                  <Grid item xs={12} md={1}></Grid>
                </Grid>
              </>
            ) : (

              <>
                <Grid item xs={12} md={1}></Grid>
                <Grid item xs={12} md={5}>
                  <GlassyCard
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Typography className='Nasaliza' variant="h6" style={{ color: "#010066" }}>Upload Loss run Report</Typography>
                    <FileUpload
                      id="portal"
                      multiple={false}
                      allowedFormats={['png', 'jpg', 'jpeg', 'plain', 'pdf', 'docx', 'mp4', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'vnd.ms-excel']}
                      setIsSubmitDisabled={setIsSubmitDisabled}
                      selectedFilesInParent={selectedFile ? [selectedFile] : []}
                      filePreviews={previews}
                      uploadIn={uploadIn}
                      onFilesUpload={handleFilesUploadToLossRunReport}
                      onFileRemove={handleFileRemove}
                    />
                    <StyledButton
                      onClick={processImage}
                      disabled={isSubmitDisabled || loading}
                      startIcon={loading ? <CircularProgress size={24} color="inherit" /> : <img src={processclaim} alt="process and claim icon" style={{ height: 24 }} />}
                    >
                      {loading ? 'Processing...' : 'Process file'}
                    </StyledButton>
                  </GlassyCard>
                </Grid>
                <Grid item xs={12} md={5}>
                  <GlassyCard
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 100 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Typography className='Nasaliza' variant="h6" style={{ color: "#010066" }}>
                      Extracted Loss run Data
                    </Typography>
                    {loading ? (
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress size={40} color="inherit" />
                      </div>
                    ) : (
                      <ExtractedDataDisplay displayValues={BeforeProcessDisplayValues} />
                    )}
                  </GlassyCard>
                </Grid>

              </>
            )) :
            (
              <Grid container justifyContent="center" >
              
              </Grid>

            )
        }
      </AnimatePresence>
    </Grid >
  </>
}
 