import React, { useEffect } from 'react';
import ReactGA4 from 'react-ga4';
import { Grid, Typography, Box, Button, Container, useMediaQuery, useTheme } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import linkedinnew from "../assets/linkedinnew.png";
import twittericon from "../assets/twittericon.png";
import facebookicon from "../assets/facebookicon.png";
import location from "../assets/Location.png";
import "../components/componentstyles.css";
import "../components/Styles.css"
import { Link, NavLink } from "react-router-dom";
import YouTubeIcon from '../assets/Youtube.png';
import instagram from '../assets/instagram.png';
import StyledButtonComponent from "./StyledButton";
import { makeStyles } from '@mui/styles';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#220238',
    padding: theme.spacing(6, 0),
    borderRadius: "20px"
  },
  contactBox: {
    backgroundColor: '#4C2FF4',
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    color: 'white',
    marginBottom: theme.spacing(4),
  },
  contactButton: {
    backgroundColor: '#76FF8A',
    color: '#4C2FF4',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    '&:hover': {
      backgroundColor: '#5AD469',
    },
  },
  linkGrid: {
    marginTop: theme.spacing(2),
  },
  linkHeader: {
    color: 'white',
    marginBottom: theme.spacing(1),
  },
  link: {
    color: 'white',
    display: 'block',
    marginBottom: theme.spacing(0.5),
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
export default function Footer() {
  // const Authorization = localStorage.getItem("Auth");
  const Authorization = !!localStorage.getItem("Auth") || !!sessionStorage.getItem("NonInsuredAuth");
  const classes = useStyles();
  const theme = useTheme();
  const GA4_MEASUREMENT_ID = 'G-L241J2GEC3';  // Replace with your actual ID
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const userAccess = Authorization ? localStorage.getItem('userAccess') : ['claim_intake', 'policy_intake', 'loss_runs', 'med_bills', 'companies_administration']

  useEffect(() => {
    const initializeGA4 = () => {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () { window.dataLayer.push(arguments); };
      window.gtag('js', new Date());
      window.gtag('config', GA4_MEASUREMENT_ID);
    };

    if (typeof window.gtag === 'undefined') {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA4_MEASUREMENT_ID}`;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        initializeGA4();
      };
    } else {
      initializeGA4();
    }
  }, []);


  return (
    <>
      <Box className="footermain">
        <Container maxWidth="lg">
          <Grid className="footermain" style={{ textAlign: 'left' }} >
            <Grid className="footer-responsive"  >
              <Typography variant="h6" gutterBottom className="footerheading billy">About</Typography>
              <Grid marginBottom={"15px"}></Grid>
              <Typography variant="body2" gutterBottom className="footer-heading About_text"  >
                Innovon Technologies revolutionizes insurance management with
                cutting-edge SmartFNOL & DocAI document processing, streamlining
                claims for efficient resolution and providing seamless experiences
                for insurers and insured parties <Link to={'/Aboutus'} onClick={() => {
                  document.title = `About us-Innovon Technologies`
                  window.scrollTo(0, 0)
                }} class="link_more" style={{ color: "#0B70FF" }}>...Read more</Link>
              </Typography>
            </Grid>

            <Grid className="footer-responsive service">
              <div variant="h6" gutterBottom className="billy">
                Services
              </div>
              <Grid marginBottom="15px"></Grid>
              <Typography className="footerservices">
                <NavLink
                  to="/InsurAI"
               
                  onClick={() => window.scrollTo(0, 0)}>
                  <span>-</span>IVAN [ Innovon Virtual Assistant ]
                </NavLink>
              </Typography>
              <Typography className="footerservices">
                <NavLink
                  to="/SmartFNOL"
           
                  onClick={() => {
                    document.title = `${!Authorization ? 'Confirm Insured' : 'signin'} -Innovon Technologies`
                    window.scrollTo(0, 0)
                  }}
                >
                  <span>-</span>SmartFNOL
                </NavLink>
              </Typography>
              <Typography className="footerservices">
                <NavLink
                  to="/SmartQuote"
             
                  onClick={() => {
                    document.title = `${!Authorization ? '/SmartQuote' : 'signin'} -Innovon Technologies`
                    window.scrollTo(0, 0)
                  }}
                >
                  <span>-</span>SmartQuote
                </NavLink>
              </Typography>

              <Typography className="footerservices">
                <NavLink
                  to="/DocAI/FNOL"
             
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <span>-</span>DocAI FNOL
                </NavLink>
              </Typography>
              <Typography className="footerservices">
                <NavLink
                  to="/DocAIQuote"
                
                  onClick={() => window.scrollTo(0, 0)}>
                  <span>-</span>DocAI Quote
                </NavLink>
              </Typography>
              <Typography className="footerservices">
                <NavLink
                  to="/DocAI/IDCardExtraction"
              
                  onClick={() => window.scrollTo(0, 0)}>
                  <span>-</span>DocAI ID Card
                </NavLink>
              </Typography>
             


              <Typography className="footerservices">
                <NavLink
                  to="/DocAIlossrunreport"
                
                  onClick={() => window.scrollTo(0, 0)}>
                  <span>-</span>DocAI Loss Run
                </NavLink>
              </Typography>
              <Typography className="footerservices">
                <NavLink
                  to="/DocAIMedBill"
                 
                  onClick={() => window.scrollTo(0, 0)}>
                  <span>-</span>DocAI Med Bill
                </NavLink>
              </Typography>
              <Typography className="footerservices">
                <NavLink
                  to="/Mail2Loss"
                
                  onClick={() => window.scrollTo(0, 0)}>
                  <span>-</span>Mail2FNOL
                </NavLink>
              </Typography>
              <Typography className="footerservices">
                <NavLink
                  to="/Mail2Quote"
                 
                  onClick={() => window.scrollTo(0, 0)}>
                  <span>-</span>Mail2Quote
                </NavLink>
              </Typography>
              <Typography className="footerservices">
                <NavLink
                  to="/InsurAdminPlatform"
                  // to={Authorization ? "/InsurAI" : "/signin"}
                  onClick={() => window.scrollTo(0, 0)}>
                  <span>-</span>InsurAdmin Platform
                </NavLink>
              </Typography>


              <Typography className="footerservices">
                <NavLink
                  to="/TermsofUse"
                  
                  onClick={() => window.scrollTo(0, 0)}>
                  <span>-</span>Terms of Use
                </NavLink>
              </Typography>
              <Typography className="footerservices">
                <a
                  href="https://innovontek.com/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>-</span>Privacy Policy
                </a>
              </Typography>


              <Grid item xs={12} sm={6} md={4} lg={2}>



              </Grid>
            </Grid>
            <Grid className="footer-responsive" marginBottom={"50px"}>
              <Typography variant="h6" gutterBottom className="footerheading  billy">
                Get In Touch
              </Typography>
              <Typography className="footercontact">
                <a href="mailto:info@innovontek.com" className="linkto"> <EmailIcon className="fotter-icon" href="mailto:info@innovontek.com" /> <span className="GT_content">info@innovontek.com</span></a>

              </Typography>
              <Grid marginBottom="8px"></Grid>
              <Typography className="footercontact">
                <a href="tel:+15134561199" className="linkto" ><PhoneIcon className="fotter-icon" /><span className="GT_content"> +1 513 456 1199</span>  </a>

              </Typography>
              <Grid marginBottom="8px"></Grid>
              <Typography className="footercontact">
                <a href="tel:+918008673672" className="linkto" >  <PhoneIcon className="fotter-icon" /> <span className="GT_content">+91 8008 673 672</span> </a>

              </Typography>
              <Grid marginBottom="8px"></Grid>
              <Typography className="footercontact">
                <LocationOnIcon className="fotter-icon" /><span className="GT_content"> USA< br /><span style={{ marginLeft: '29px', fontSize: ' 0.9rem', color: 'white' }}>254 Chapman Rd, Ste 208 #12287   </span> </span>

                <br /><span style={{ marginLeft: '29px', fontSize: ' 0.9rem', color: 'white' }} >Newark DE 19702, US</span>
              </Typography>
              <Grid marginBottom="8px"></Grid>
              <Typography className="footercontact">
                <Typography className="footercontact">
                  <LocationOnIcon className="fotter-icon" /> <span className="GT_content">INDIA<br /><span style={{ marginLeft: '29px', fontSize: ' 0.9rem', color: 'white' }}>1008, 10th Floor, DSL Abacus IT Park,</span> </span>

                  <br /> <span style={{ marginLeft: '29px', fontSize: ' 0.9rem', color: 'white' }} >Uppal, Hyderabad, Telangana 500039</span>
                </Typography>
              </Typography>
              <Grid marginBottom="8px"></Grid>
              <Grid marginLeft={"40px"}>


                <Box display="flex" alignItems="center">
                  <a
                    href="https://www.facebook.com/innovontech"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={facebookicon}
                      alt="facebookicon"
                      className="me-2"
                      style={{
                        width: "25px",
                        height: "auto",
                        margin: "20px 10px 0px 0px ",
                      }}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/innovontech"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={linkedinnew}
                      alt="linkedinnew"
                      className="footericon"
                      style={{
                        width: "25px",
                        height: "auto",
                        margin: "20px 10px 0px 0px ",
                      }}
                    />
                  </a>
                  <a
                    href="https://twitter.com/innovontech"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={twittericon}
                      alt="twittericon"
                      className="footericon"
                      style={{
                        width: "25px",
                        height: "auto",
                        margin: "20px 10px 0px 0px ",
                      }}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/innovontech/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={instagram}
                      alt="Instagram"
                      className="footericon"
                      style={{
                        width: '25px',
                        height: 'auto',
                        margin: '20px 10px 0px 0px',
                      }}
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/@InnovonTek" // Replace with your actual YouTube link
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={YouTubeIcon}
                      alt="YouTube"
                      className="footericon"
                      style={{
                        width: '25px',
                        height: 'auto',
                        margin: '20px 10px 0px 0px',
                      }}
                    />
                  </a>
                </Box>
              </Grid>
              <Box
                style={{


                  marginTop: "3rem",
                }}
              >
                <Grid container style={{ marginLeft: '29px' }}>
                  <Grid item>
                    <StyledButtonComponent buttonWidth={200}
                      href="https://innovontek.com/contact/"
                      target="_blank"
                    >
                      Let's Connect &rarr;
                    </StyledButtonComponent>
                  </Grid>
                </Grid>
              </Box>

            </Grid>

          </Grid>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              padding: 2,

            }}
          >
            <Typography
              variant="body2"
              sx={{
                textAlign: 'center',
                fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' },
                color: '#FFFFFF',  // Set text color to white
                marginTop: '1rem', // Add some space on top
              }}
            >
              <Link
                to="https://innovontek.com"
                style={{
                  color: '#FFFFFF',  // White color for the link
                  textDecoration: 'none',  // Remove text decoration (underline)
                  fontWeight: 'bold',  // Make the text bold
                }}
              >
                © Copyright 2024 Innovon Technologies, All Rights Reserved.
              </Link>
            </Typography>
            
            <span style={{marginTop:"0.6rem",marginLeft:'0.5rem'}} >|</span>
            
            <Typography
              variant="body2"
              sx={{
                textAlign: 'center',marginTop:"0.95rem",marginLeft:'0.5rem',
                fontSize: { xs: '0.8rem', sm: '0.8rem', md: '0.8rem', lg: '0.8rem' },
                color: '#FFFFFF',  // Set text color to white
              }}
            >
              <Link
                to="https://innovontek.com"
                style={{
                  color: '#FFFFFF',  // White color for the link
                  textDecoration: 'none',  // Remove text decoration (underline)
                  fontWeight: 'bold',  // Make the text bold
                }}
              >
                www.innovontek.com
              </Link>
            </Typography>
            </Box>
            




      

        </Container>

      </Box>

    </>
  );
}