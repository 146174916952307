import { useState } from "react";
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDBox";
import MDTypography from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDTypography";
import MDButton from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDButton";
import { CircularProgress } from "@mui/material"; // Import CircularProgress

// Material Dashboard 2 React context
import { useMaterialUIController } from "../../../CompanyDashboardChartsCardsLayouts/context";
import FNOLClaimsView from "./FNOLClaimsView";
import axios from "axios";

function FNOLCard({ title }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [expanded, setExpanded] = useState(false);
  const [claims, setClaims] = useState([]); // Store fetched claims
  const [claimsCount, setClaimsCount] = useState(0); // Add state for claims count
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const ic_id = localStorage.getItem("ic_id_for_dashboard")


  const handleFetchClaims = async () => {
    setIsLoading(true); // Set loading to true when API call starts
    try {
      const company = JSON.parse(localStorage.getItem("carrier_admin_company"));
      const formData = new FormData();
      formData.append("ic_id", ic_id || company.ic_id);
      formData.append("channel", "FNOL");

      const response = await axios.post(
        `${process.env.REACT_APP_URL}get_all_channels_claims/`,
        formData
      );

      console.log("Claims Data:", response.data);
      const claimsArray = Object.values(response.data.claims_details);
      setClaims(claimsArray);
      setClaimsCount(claimsArray.length); // Update claims count

    } catch (error) {
      console.error("Error fetching claims:", error);
      // Handle error, e.g., show an error message to the user
    }
    finally {
      setIsLoading(false); // Set loading to false after API call completes
    }
  };

  return (
    <Card id="delete-account">
      <MDBox pt={2} pb={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          {title}
        </MDTypography>
        <MDBox display="flex" alignItems="center">
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleFetchClaims}
            sx={{ mr: 2,ml: 1, display: 'flex', alignItems: 'center' }} // Add display: 'flex' to align content
            disabled={isLoading}
          >
            {isLoading && <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />} {/* Add spacing */}
            {isLoading ? 'Fetching...' : 'Fetch Claims'}
          </MDButton>
          <MDTypography variant="body2" color="textSecondary" fontWeight="medium">
            Success Claims: {claimsCount} {/* Display claims count */}
          </MDTypography>
        </MDBox>
        <MDButton variant="gradient" color="dark" onClick={toggleExpand}>
          <Icon sx={{ fontWeight: "bold" }}>{expanded ? "expand_less" : "expand_more"}</Icon>
          &nbsp;{expanded ? "Hide FNOL Claims" : "View FNOL Claims"}
        </MDButton>
      </MDBox>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <MDBox p={2}>
          <FNOLClaimsView claimsData={claims} /> {/* Pass claims data to FNOLClaimsView */}
        </MDBox>
      </Collapse>
    </Card>
  );
}

// Setting default values for the props of FNOLCard
FNOLCard.defaultProps = {
  title: "Success Claims",
};

// Typechecking props for the FNOLCard
FNOLCard.propTypes = {
  title: PropTypes.string,
};

export default FNOLCard;
