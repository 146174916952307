import React, { useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { motion } from "framer-motion";
import { Grid, Typography, Box, CircularProgress, Paper, useTheme, useMediaQuery, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@mui/material";

import FileUpload from "../../components/fileupload";
import axios from "axios";
import StyledButtonComponent from "../../components/StyledButton";
import processclaim from "../../assets/processclaim.png";
import { GoogleGenerativeAI } from "@google/generative-ai";
import * as XLSX from 'xlsx';
import { Upload } from 'lucide-react';
import { HarmBlockThreshold, HarmCategory } from "@google/generative-ai";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import idCard from "../../assets/id-card.png"





export default function IDPIdCardfun() {
    const [extractedData, setExtractedData] = useState(null);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [displayValues, setDisplayValues] = useState(
        {
            Persons__Info: {
                first_name: 'First Name',
                last_name:'Last Name',
                date_of_birth: 'Date of Birth',
               
                sex: 'Sex',
                height: 'Height',
                Eye_Color: 'Eye Color',
                Weight: 'Weight',
                hair_color:'Hair Color',
                address:'Address',
 
            },
            ID_Details: {
                real_id:'Real ID',
                document_type: 'ID Type',
                document_number: 'ID Number',
                expiration_date: 'Expiration Date',
                issuance_date:'Issuance Date',
                expiration_date: 'Expiration Date',
               
                class_type:'Driving Class',
                endorsements:'Endorsements',
                restrictions:'Restrictions'
            },

        });
    const [uploadedImage, setUploadedImage] = useState(null); // State to store the uploaded image
    const [documentType, setDocumentType] = useState("");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [selectedFile, setSelectedFile] = useState(null);
    const [previews, setPreviews] = useState([]);
    const [previewImage, setPrevieImage] = useState(false)
    const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
    const genAI = new GoogleGenerativeAI(API_KEY);

    const safetySettings = [
        {
            category: HarmCategory.HARM_CATEGORY_HARASSMENT,
            threshold: HarmBlockThreshold.BLOCK_NONE,
        },
        {
            category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
            threshold: HarmBlockThreshold.BLOCK_NONE,
        },
        {
            category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
            threshold: HarmBlockThreshold.BLOCK_NONE,
        },
        {
            category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
            threshold: HarmBlockThreshold.BLOCK_NONE,
        }
    ];

    const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash", safetySettings });

    const handleFileRemove = (fileToRemove) => {
        const updatedFiles = selectedFiles.filter(file => file !== fileToRemove);
        setSelectedFiles(updatedFiles);
        if (updatedFiles.length === 0) {
            setIsSubmitDisabled(true);
        }
    };
    const toTitleCase = (str) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase()).toLowerCase();
    };
    const convertToTitleCase = (str) => {
        return str
            .replace(/([A-Z])/g, ' $1')   // Add space before capital letters (camelCase)
            .replace(/_/g, ' ')            // Replace underscores with spaces (snake_case)
            .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()) // Capitalize first letter
            .trim();                       // Remove leading/trailing spaces
    };
    const handleFilesUpload = (selectedFiles, previews) => {
        setSelectedFile(selectedFiles[0]);
        setPreviews(previews);
        setIsSubmitDisabled(false);
    };
    const Reupload = async () => {
        setIsSubmitDisabled(true)
        setLoading(false);
        setPrevieImage(false)
        setDisplayValues('')
    }
    const processImage = async () => {
        if (!selectedFile) {
            alert("Please select a file first.");
            return;
        }
        setDisplayValues('')

        try {
            setLoading(true);
            const reader = new FileReader();
            reader.onload = async (e) => {
                let imageData = e.target.result;

                setUploadedImage(imageData);

                if (imageData.startsWith('data:image/')) {
                    imageData = imageData.split(',')[1];
                }

                let combinedPrompt = `Extract the following details from the original document only if the document has a star mark on the right top corner:

real_id: value (carefully examine the card for the presence of a star icon located in the top right corner. This icon signifies that the ID has enhanced security features as mandated by the Real ID Act. If the star icon is present, indicate compliance by responding with "Yes," confirming that the ID card is a Real ID. Conversely, if the star icon is absent, respond with "No," indicating that the ID card does not meet Real ID standards. This assessment process is crucial for ensuring that identification documents adhere to the necessary security protocols)
First Name (FName): value (Name of the cardholder)
Last Name (LName): value (Name of the cardholder)
ID Type (DocType): value (e.g., Driver License,Operator License, Green Card, SSN, State Identity card(if the identity card was issued by the states then it is a State Identity card Type))
ID Number (DocNum): value (e.g., License number, Green Card number, SSN)
Date of Birth (DOB): value
Expiration Date (ExpDate): value (if available)
Address (Addr): value (Street number,Street Name,City,state,country,Zip(only 5 digits))
Height (Ht): value (e.g., 5'06")
Eye Color (EyeClr): value (e.g., BLK for Black)
Weight (Wt): value (if available)
Sex (Sex): value (e.g., M for Male)
Restrictions (Restrict/RES/REST/R): value (if applicable)
Issuance Date (IssueDate/issued Date): value (Date the license was issued)
Driving Class: value (e.g., Class D for passenger vehicles)
Endorsements (Endorse/END/E): value (if available)
Hair Color (HairClr): value (if available, e.g., BLK for Black)` 
 
                const result = await model.generateContent([
                    combinedPrompt,
                    {
                        inlineData: {
                            data: imageData,
                            mimeType: selectedFile.type,
                        }
                    }
                ]);

                const geminiText = await result.response.text();
                console.log("Generated text:", geminiText);

                setPrevieImage(true);
                setLoading(false);

                try {
                    const response = await axios.post(`${process.env.REACT_APP_URL}AI/id_card_extraction/`, { extracted_text: geminiText });
                    console.log('Backend response:', response.data);

                    const data = response.data.extracted_json || {};

                    // Define mappings for data keys
                    const mappings = {
                        Persons__Info: {
                            'first_name': 'First Name',
                            'last_name':'Last Name',
                            'date_of_birth': 'Date of Birth',
                           
                            'Sex': 'Sex',
                            'Height': 'Height',
                            'Eye_Color': 'Eye Color',
                            'Weight': 'Weight',
                            'hair_color':'Hair Color',
                            'address': 'Address',
                        },
                     
                        ID_Details: {
                            'real_id':'Real ID',
                            'document_type': 'ID Type',
                            'document_number': 'ID Number',
                            'issuance_date':'Issuance Date',
                            'expiration_date': 'Expiration Date',
                         
                            'class_type':'Driving Class',
                            'endorsements':'Endorsements',
                            'restrictions':'Restrictions'
                        },

                    };

                    // Helper function to map values
                    const mapValues = (mapping, data) =>
                        Object.fromEntries(
                            Object.entries(mapping).map(([key, value]) => [value, data[key] || ''])
                        );

                    // Map extracted data to structured values
                    const displayValues = {
                        Persons__Info: mapValues(mappings.Persons__Info, data),
                        ID_Details: mapValues(mappings.ID_Details, data),
 
                    };

                    console.log("Formatted values:", displayValues);

                    setDisplayValues(displayValues);


                } catch (err) {
                    console.error('Error sending data to backend:', err);
                    setError('Error sending data to the server. Please try again later.');
                }
            };
            reader.readAsDataURL(selectedFile);
        } catch (error) {
            console.error("There was an error processing the image:", error);
            setLoading(false);
        }
    };

    const titleText = `Extracted ${toTitleCase(documentType)} Details`;
    return (
        <Box sx={{ padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    {previewImage ? (
                        <>
                            <Paper
                                elevation={3} // Adds box shadow
                                sx={{
                                    width: "100%",
                                    maxWidth: 1000,
                                    margin: "auto",
                                    mt: 9,
                                    borderRadius: 3,
                                    p: 4,
                                    backdropFilter: "blur(10px)", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",// Blur effect for glass look
                                    border: "1px solid rgba(255, 255, 255, 0.2)", // Custom box shadow
                                    background: 'transparent', height: '550px'
                                }}
                            >

                                {previewImage && (
                                    <>

                                        <Typography sx={{ color: '#001660', fontSize: '1.3rem' }} className="Nasaliza">Uploaded ID</Typography>
                                        <Box sx={{ marginTop: 4, textAlign: 'center' }}>
                                            <img src={uploadedImage} alt="Uploaded Document" style={{ width: '350px', maxWidth: '100%', height: 'auto' }} />
                                        </Box>
                                    </>
                                )}
                                <Box sx={{ textAlign: 'center', marginTop: 2 }}>
                                    <StyledButtonComponent
                                        variant="contained"
                                        color="primary"
                                        onClick={Reupload}
                                        buttonWidth={150}

                                        startIcon={loading ? <CircularProgress size={24} color="inherit" /> : <img src={processclaim} alt="process and claim icon" style={{ height: 24 }} />}
                                    >
                                        {loading ? 'Uploading...' : 'ReUpload'}
                                    </StyledButtonComponent>
                                </Box>
                            </Paper>
                        </>
                    ) : (
                        <>


                            <Paper
                                elevation={3} // Adds box shadow
                                sx={{
                                    width: "100%",
                                    maxWidth: 1000,
                                    margin: "auto",
                                    mt: 9,
                                    borderRadius: 3,
                                    p: 4,
                                    backdropFilter: "blur(10px)", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",// Blur effect for glass look
                                    border: "1px solid rgba(255, 255, 255, 0.2)", // Custom box shadow
                                    background: 'transparent',
                                }}
                            >
                                <Typography sx={{ color: '#001660', fontSize: '1.3rem' }} className="Nasaliza">Upload IDP ID Card Document</Typography>

                                <FileUpload
                                    id="portal"
                                    onFilesUpload={handleFilesUpload}
                                    multiple={false}
                                    allowedFormats={[
                                        'png',
                                        'jpg',
                                        'jpeg',


                                    ]}
                                    setIsSubmitDisabled={setIsSubmitDisabled}
                                    onFileRemove={handleFileRemove}
                                />
                                <Box sx={{ textAlign: 'center', marginTop: 2 }}>
                                    <StyledButtonComponent
                                        variant="contained"
                                        color="primary"
                                        onClick={processImage}
                                        buttonWidth={150}
                                        disabled={isSubmitDisabled || loading}
                                        startIcon={loading ? <CircularProgress size={24} color="inherit" /> : <img src={processclaim} alt="process and claim icon" style={{ height: 24 }} />}
                                    >
                                        {loading ? 'Processing...' : 'Process'}
                                    </StyledButtonComponent>
                                </Box>
                            </Paper>



                        </>
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    {!previewImage && <Paper
                        elevation={3} // Adds box shadow
                        sx={{
                            width: "100%",
                            maxWidth: 1000,
                            margin: "auto",
                            mt: 9,
                            borderRadius: 3,
                            p: 4,
                            backdropFilter: "blur(10px)", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",// Blur effect for glass look
                            border: "1px solid rgba(255, 255, 255, 0.2)", // Custom box shadow
                            background: 'transparent',
                            //   height:selectedFile?'380px':'200px'
                        }}
                    >
                        <Typography sx={{ color: '#001660', fontSize: '1.3rem' }} className="Nasaliza">Extracted ID Details will be displayed</Typography>
                        <img src={idCard} alt={"id"} height={"215px"} />
                    </Paper>}
                    {previewImage && displayValues && (<>
                        <Paper
                            elevation={3} // Adds box shadow
                            sx={{
                                width: "100%",
                                maxWidth: 1000,
                                margin: "auto",
                                mt: 9,
                                borderRadius: 3,
                                p: 4,
                                backdropFilter: "blur(10px)",
                                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)", // Blur effect for glass look
                                border: "1px solid rgba(255, 255, 255, 0.2)", // Custom box shadow
                                background: 'transparent',

                            }}
                        >
                            <Typography sx={{ color: '#001660', fontSize: "1.5rem", textTransform: 'none' }} className="Nasaliza">
                                Extracted ID Details
                            </Typography>
                            <TableContainer sx={{ margin: 'auto', border: '1px solid blue', height: '450px', overflowY: 'auto',paddingBottom:'1.3rem' }}>
                                <Table>
                                    <TableBody>
                                        {Object.entries(displayValues).map(([sectionKey, sectionData]) => (
                                            <React.Fragment key={sectionKey} >


                                                <TableRow sx={{ borderBottom: '2px solid #1976D2' }}>
                                                    <TableCell style={{ fontWeight: 'bold', color: '#001660', fontSize: '1rem', borderBottom: '2px solid #1976D2', width: '40%', marginBottom: '1rem' }} className="Nasaliza">
                                                        {sectionKey === 'ID_Details' ? 'ID Details' : convertToTitleCase(sectionKey)}
                                                    </TableCell>
                                                </TableRow>



                                                 {Object.entries(sectionData)
                                                    .map(([key, value]) => (
                                                        <TableRow key={key} sx={{ borderBottom: '1px solid #1976D2' }}>
                                                            <TableCell sx={{ fontWeight: 'bold', paddingBottom: '0px', paddingLeft: '1.3rem' }}>{key}</TableCell>
                                                            <TableCell sx={{ textAlign: 'left', paddingBottom: '0px' }}>:</TableCell>
                                                            <TableCell sx={{ textAlign: 'left', paddingBottom: '0px' }}>
                                                                {value ? value : "Not Found"} {/* Show 'None' if value is empty */}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }

                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                    </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}
