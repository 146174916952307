import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate, Navigate, useLocation} from 'react-router-dom'
import './App.css';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './pages/login.js';
import Claimcapture from './pages/claimcapture.js';
import Claimsuccess from './pages/success.js';
import CustomerInsuranceCompany from './pages/custometinsurancecompany.js';
import Companysuccess from './pages/companysuccess.js';
import FileUpload from './pages/fileextract.js';
import Servicetypes from './pages/servicetypes.js';
import Company from './pages/company.js';
import Signin from "./pages/signin.js";
import Aboutus from './pages/About.js';
import Fnol from './pages/FNOL/Fnol.js';
import IDP_Fnol from './pages/IDP_FNOL/IDP_Fnol.js';
import InsurAI from './pages/InsurAI/InsurAI.js';
import InsurAI_Agent from './pages/InsurAI/InsurAI_Agent.js';
import AllServices from './pages/AllServices.js';
import EmailToFnol from './pages/IDP_FNOL/Email_to_FNOL.js';
import InsuredSignup from './pages/InsuredSignup.js';
import { Backdrop } from "@mui/material";
import CustomDialog from "./pages/PopupBox.js"; 
import LossRunReports from "./pages/LossRunReports.js";
import ScrollToTop from "./components/ScrollToTop.js";
import PolicySignUp from "./pages/FNOL/PolicyIntake/PolicySignUp.js";
import PolicyIntake from "./pages/FNOL/PolicyIntake/PolicyIntake.js"
import DemoPage from "./pages/RequestDemo.js";
import DashboardApp from "../src/pages/CompanyDashboardLayout/DashboardApp.js";
import { MaterialUIControllerProvider } from './CompanyDashboardChartsCardsLayouts/context'; // Adjust the path as needed
import PolicySignin from "./pages/FNOL/PolicyIntake/PolicySignIn.js";
import TermsOfUse from "./pages/TermsOfUse.js";
import InsurAIDemo from "./pages/DemoPages/InsurAIDemo.js";
import EmailTOFnolDemo from "./pages/DemoPages/EmailToFnolDemo.js";
import LossRunReportsDemo from "./pages/DemoPages/LossRunReportsDemo.js";
import IdpDemo from "./pages/DemoPages/IdpDemo.js";
import IdpPolicyIntake from "./pages/IDP_FNOL/IDP_Policy_Intake.js";
import IdpPolicyIntakeDemo from "./pages/DemoPages/IDP_Policy_Intake_DEMO.js";
import AddUser from "./pages/NewRegister/AddUser.js";
import UserDashboard from "./pages/NewRegister/Dashboard.js";
import EmailToPolicyIntake from "./pages/IDP_FNOL/Email_to_PolicyIntake.js";
import EmailToPolicyIntakeDemo from "./pages/DemoPages/EmailTo_PolicyIntakeDemo.js";
import IDCardExtraction from "./pages/IDP_FNOL/ID_Card_Extraction.js";
import IDCardExtractionDemo from './pages/DemoPages/IDP_ID_Card_Demo.js';
import MedBill from "./pages/IDP_FNOL/MedBill.js";
import MedBillDemo from "./pages/DemoPages/MedBillDemo.js";
import SubmissionPortal from "./pages/FNOL/PolicyIntake/SubmissionPortal.js";
import InnovonAdminDashboard from "./pages/Innovon_Company_Admin/InnovonAdminDashboard.js";
import AgentPerformance from "./pages/CompanyDashboardLayout/AgentPerformance.js";
import InsurerPlatform from "./pages/Innovon_Company_Admin/InsurerPlatform.js";
import SuccessPage from "./components/SucessMessage.js";
import routeTitles from "./pages/RoutesTitle.js";
const theme = createTheme();

const TitleUpdater = () => {
  const location = useLocation();

  React.useEffect(() => {
    const path = location.pathname;
    const title = Object.keys(routeTitles).find(route => path.startsWith(route));
    document.title = routeTitles[title] || 'Innovon Tech';
  }, [location]);

  return null;
};
function App() {
  

  return (
    <ThemeProvider theme={theme}>
      <MaterialUIControllerProvider>
      <BrowserRouter>
      <ScrollToTop /> {/* Add ScrollToTop component here */}
      <TitleUpdater /> 
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <ToastContainer position="top-center" />
          <Routes>
            <Route exact path="/login" element={<Login />} />
            {/* <Route element={<PersistLogin />}> */}
            <Route exact path="/claimcapture" element={<Claimcapture />} />
            <Route exact path="/claimsuccess" element={<Claimsuccess />} />          
            <Route exact path="/companysuccess" element={<Companysuccess />} />
            <Route exact path="/fileextract" element={<FileUpload />} />
            <Route index element={<Servicetypes />} />
            <Route exact path="/Aboutus" element={<Aboutus />} />
            <Route exact path="/signin" element={<Signin />} />
            <Route exact path="/SmartFNOL" element={<Fnol />} />
            <Route exact path="/DocAI/FNOL" element={<IDP_Fnol />} />
            <Route exact path="/Mail2Loss" element={<EmailToFnol />} />
            <Route exact path="/DocAIMedBill" element={<MedBill />} />
            <Route exact path="/InsurAI" element={<InsurAI />} />
            <Route exact path="/InsurAI/Agent" element={<InsurAI_Agent />} />
            <Route exact path="/all/services" element={<AllServices />} />
            <Route exact path="/DocAIlossrunreport" element={<LossRunReports />} />
            <Route exact path="/insuredsignup" element={<InsuredSignup />} />
            <Route exact path="/policysignup" element={<PolicySignUp/>}/>
            <Route exact path="/policysignIn" element={<PolicySignin/>}/>
            <Route exact path="/Demo/SmartQuote" element={<PolicyIntake/>}/>
            <Route exact path="/DocAIQuote" element={<IdpPolicyIntake/>}/>
            <Route exact path="/requestdemo" element={<DemoPage/>}/>
            <Route exact path="/TermsofUse" element={<TermsOfUse/>}/>
            <Route exact path="/Demo/InsurAI" element={<InsurAIDemo/>}/>
            <Route exact path="/Demo/Mail2Loss" element={<EmailTOFnolDemo/>}/>
            <Route exact path="/Demo/DocAIlossrunreport" element={<LossRunReportsDemo/>}/>
            <Route exact path="/Demo/DocAI" element={<IdpDemo/>}/> 
            <Route exact path="/Demo/DocAIMedBill" element={<MedBillDemo />} />
            <Route exact path="/addUser" element={<AddUser/>}/>
            <Route exact path="/Mail2Quote" element={<EmailToPolicyIntake/>}/>
            <Route exact path="/Demo/Mail2Quote" element={<EmailToPolicyIntakeDemo/>}/>
            <Route exact path="/UserDashboard" element={<UserDashboard/>}/>
            <Route exact path="/DocAI/IDCardExtraction" element={<IDCardExtraction/>}/>
            <Route exact path="/IDCardExtraction/Demo" element={<IDCardExtractionDemo/>}/>  
            <Route exact path="/SmartQuote" element={<SubmissionPortal/>}/> 
            <Route exact path="/Demo/DocAIQuote" element={<IdpPolicyIntakeDemo/>}/>
            <Route exact path="/InsurAdminPlatform" element={<InsurerPlatform/>}/>
            <Route exact path="/SucessMessage" element={<SuccessPage/>}/>
            <Route path="/dashboard/*" element={<DashboardApp />} />  
            <Route exact path="/customercompany" element={<Company><CustomerInsuranceCompany /></Company>} />
             <Route path="/innovonadmindashboard"  element={<InnovonAdminDashboard />} />  
 

             
            </Routes>
          <PopupHandler />
        </div>
      </BrowserRouter>
      </MaterialUIControllerProvider>
    </ThemeProvider>
  );
}

function PopupHandler() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const auth = localStorage.getItem('Auth');
  const NonInsuredAuth = sessionStorage.getItem('NonInsuredAuth');
  const isInsured = localStorage.getItem('isInsured');

  useEffect(() => {
    const shouldShowPopup = localStorage.getItem("showPopup") === "true";
    if (shouldShowPopup) {
      setOpen(true);

      if (!auth) {
        const timer = setTimeout(() => {
          localStorage.clear();
          setOpen(false);
          navigate('/');
        }, 5 * 60 * 1000); // 10 seconds for testing

        return () => clearTimeout(timer); // Cleanup timer if component unmounts
      }

      if (NonInsuredAuth) {
        const timer = setTimeout(() => {
          sessionStorage.clear();
          sessionStorage.removeItem('dummy');
          setOpen(false);
          navigate('/');
        }, 5 * 60 * 1000); // 10 seconds for testing

        return () => clearTimeout(timer);
      }
    }
  }, [auth, NonInsuredAuth, navigate]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleBackdropClick = () => {
    // Do nothing when clicking on the backdrop
  };

  return (
    <>
      <Backdrop
        open={open}
        onClick={handleBackdropClick}
        style={{ zIndex: 1, color: "#fff" }}
      />
      <CustomDialog open={open} handleClose={handleClose} />
    </>
  );
}

export default App;