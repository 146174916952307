import React, { useState, useEffect, useRef, Suspense, lazy } from "react";
import {
  Grid, Typography, useMediaQuery, useTheme, Card, Container,
  CardMedia,
  CardContent,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';

import { Button } from "@mui/material";
import rightarrow1 from "../../assets/rightarrow1.png";
import StyledButtonComponent from "../../components/StyledButton";
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Ensure to import the carousel styles

import "../pagesstyles.css";
import "../services.css";
import ContactMailIcon from '@mui/icons-material/ContactMail';

import FNOL_img2 from '../../assets/FNOL_img2.png'

import FNOL_img1 from '../../assets/FNOL_img1.png'
import FNOL_img3 from '../../assets/FNOL_img3.png'
import File_claims from '../../assets/File_claims.png'
import Header from "../../components/header";
import Footer from "../../components/footer";

import FNOL_Flowchart from '../../assets/FNOL_Flowchart.png'
import FNOL_top from '../../assets/FNOL_top.png'
import FNOL_bottom from '../../assets/FNOL_bottom.png'
import FNOL_right from '../../assets/FNOL_right.png'
import FNOL_ALL from '../../assets/FNOL_ALL.png'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Visibility from "@mui/icons-material/Visibility";
import Timer from "@mui/icons-material/Timer";
import Face from "@mui/icons-material/Face";
import { color } from "framer-motion";
import { AssignmentTurnedIn, Speed, PeopleAlt, PlayCircleFilled } from "@mui/icons-material";
import { keyframes } from "@mui/material";
import axios from "axios";
const slideInFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const zoomIn = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;
const features = [
  {
    title: "Streamline Claims Process Efficiently",
    description:
      "Our user-friendly FNOL service streamlines claims reporting with an intuitive interface for quick, accurate data collection and a seamless experience.",
    icon: <AssignmentTurnedIn sx={{ fontSize: 50, color: "#1976d2" }} />, // Checkmark for efficient processing
  },
  {
    title: "Accelerate Claim Settlement",
    description:
      "Accelerate claim processing with our FNOL service, which uses structured data collection and organized workflows for faster resolutions and higher customer satisfaction.",
    icon: <Speed sx={{ fontSize: 50, color: "#1976d2" }} />, // Speedometer for acceleration
  },
  {
    title: "Enhance Customer Experience",
    description:
      "Enhance customer experience with our easy-to-use FNOL platform, offering straightforward claim reporting and clear status updates for effective communication and support.",
    icon: <PeopleAlt sx={{ fontSize: 50, color: "#1976d2" }} />, // Group of people for customer focus
  },
];


const images = [
  { id: 1, src: FNOL_ALL, alt: "Image 1" },
  { id: 2, src: FNOL_bottom, alt: "Image 2" },
  { id: 3, src: FNOL_right, alt: "Image 3" },
];
export default function Fnol() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [isHovered, setHovered] = useState(false);

  // const Authorization = localStorage.getItem("Auth");
  const Authorization = !!localStorage.getItem("Auth") || !!sessionStorage.getItem("NonInsuredAuth");


  const [typedText, setTypedText] = useState("");
  const text = "InnoClaimFNOL";
  const getTextAlign = () => {
    if (isMobile) return 'left';
    if (isTablet) return 'justify';
    return 'justify';
  };


  useEffect(() => {
    // Function to start the typing effect
    const startTyping = () => {
      let index = 0;
      const typingInterval = setInterval(() => {
        if (index <= text.length) {
          setTypedText(text.substring(0, index));
          index++;
        } else {
          clearInterval(typingInterval);
        }
      }, 300); // Adjust typing speed as needed
    };

    // Start typing immediately
    startTyping();

    // Set interval to repeat typing every 5 seconds
    const intervalId = setInterval(startTyping, 10000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  const [nonInsured, setNonInsured] = useState(false);
  const [error, setError] = useState(null);
  const [verifyOTPLoader, setverifyOTPLoader] = useState(false);
  const [nonInsuredEmailOTPVerified, setNonInsuredEmailOTPVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifyNonInsured, setVerifyNonInsured] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [iconUnlocked, setIconUnlocked] = useState(false);
  const token = ''; // Initialize the token properly

  const checked = (val) => {


    if (!Authorization) {
      localStorage.setItem("rout", val);
    } else {
      window.scrollTo(0, 0);
    }
  };





  return <>
    <Header />

    <Box
      sx={{
        position: 'relative',
        backgroundImage: `url(${require('../../assets/fnolBG.png')})`, // replace with your image path
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: "white",

        padding: "2rem 0", // Adds padding for top and bottom
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black overlay with transparency
          zIndex: 1, width: "100%", maxWidth: 'auto', margin: 'auto'
        }}
      />
      <Box
        sx={{
          position: 'relative',
          zIndex: 2, // Ensures the content is above the overlay
          maxWidth: '1400px',
          margin: '0 auto',
          padding: '0 1rem',

        }}
      >
        <Typography
          sx={{
            fontSize: "2.2rem",
            color: 'white',
            paddingTop: '2rem',
            //  fontFamily:'Georgia, Times, serif',
            textAlign: 'center',
          }}
          className="Nasaliza"
        >
         SmartFNOL Portal
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
            height: isMobile ? "auto" : "auto",
            width: '100%',
            margin: 'auto',
          }}
        >
          {isMobile && (
            <Box
              sx={{
                maxWidth: "95%",
                mt: "2rem",
                animation: `${zoomIn} 1.5s ease-out`,
              }}
            >
              <Carousel
                showArrows={false}
                autoPlay
                infiniteLoop
                showThumbs={false}
                showStatus={false}
                swipeable
                emulateTouch
              >
                {images.map((image) => (
                  <div key={image.id}>
                    <img
                      src={image.src}
                      alt={image.alt}
                      style={{
                        width: "100%",
                        borderRadius: '8px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                      }}
                    />
                  </div>
                ))}
              </Carousel>
            </Box>
          )}
          <Box
            sx={{
              maxWidth: isMobile ? "100%" : "50%",
              textAlign: isMobile ? "center" : "left",
              marginLeft: isMobile ? "0" : "5rem",
              animation: `${slideInFromLeft} 1s ease-out`,
            }}
          >
            <Typography
              variant={isMobile ? "h4" : "h2"}
              component="h1"
              gutterBottom
              className="Nasaliza"
              sx={{
                animation: `${slideInFromLeft} 1s ease-out`,
              }}
            >
              Streamline your claims process with FNOL
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                animation: `${slideInFromLeft} 1s ease-out`,
                fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                  textAlign: 'justify',
                  hyphens: 'auto',
                  wordBreak: 'break-word',
                  '& > span': { display: 'inline-block' }
              }}
            >
              Our FNOL service transforms the insurance claims process, prioritizing efficiency, speed, and customer satisfaction. With advanced technology and expert support, we streamline claims processing, accelerate settlement, and enhance transparency. Policyholders report claims easily through our user-friendly platform, receiving prompt support and updates throughout their journey.
            </Typography>
            {!Authorization ?
              <Box sx={{ marginTop: "2rem",textAlign:isMobile?"center": 'left' }}>
                <StyledButtonComponent
                  buttonWidth={250}
                  onClick={() => navigate("/requestdemo")}
                >
                  Request for Demo
                </StyledButtonComponent>
                <StyledButtonComponent buttonWidth={200} sx={{marginLeft:'20px'}}>
                          <PlayCircleFilled sx={{ marginRight: '8px' }} />
                          <a href="https://www.youtube.com/@InnovonTek" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                          Watch Video
                          </a>
                        </StyledButtonComponent>
              </Box>
              :
              <Box sx={{ marginTop: "2rem", textAlign:isMobile?"center": 'left' }}>
                <Link
                  style={{ color: 'black' }}
                  onClick={() => checked("/login")}
                  to={Authorization ? "/claimcapture" : "/signin"}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                >

                  <StyledButtonComponent buttonWidth={200} >
                    Demo
                  </StyledButtonComponent>
                  <StyledButtonComponent buttonWidth={200} sx={{marginLeft:isMobile?"0rem":'20px'}}>
                          <PlayCircleFilled sx={{ marginRight: '8px' }} />
                          <a href="https://www.youtube.com/@InnovonTek" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                          Watch Video
                          </a>
                        </StyledButtonComponent>
                </Link>
              </Box>
            }
              <Box sx={{textAlign:isMobile?"center": 'left',marginRight:isMobile?'0rem':'12rem'}}>
              <Typography
                        className="Nasaliza"
                        sx={{
                          fontWeight: 'bold',
                          paddingTop: '5px',
                          fontSize: '1.1rem',
                         textAlign:'center',
                          color: 'white',
                        }}
                      >
                        <ContactMailIcon sx={{ marginRight: '10px', fontSize: '1.5rem', color: 'white' }} />
                        Contact us for free POC
                        </Typography>

              </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "4rem",
                gap: "1rem",
              }}
            >
              {/* Additional content or buttons */}
            </Box>
          </Box>
          {!isMobile && (
            <Box
              sx={{
                maxWidth: "50%",
                animation: `${zoomIn} 1.5s ease-out`,
              }}
            >
              <Carousel
                showArrows={false}
                autoPlay
                infiniteLoop
                showThumbs={false}
                showStatus={false}
                swipeable
                emulateTouch
              >
                {images.map((image) => (
                  <div key={image.id}>
                    <img
                      src={image.src}
                      alt={image.alt}
                      style={{
                        width: "80%",
                        height: '50vh',
                        borderRadius: '8px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                      }}
                    />
                  </div>
                ))}
              </Carousel>
            </Box>
          )}
        </Box>
      </Box>
    </Box>

    {/* ............................................................. */}


    < Box sx={{ padding: { xs: 2, md: 4 }, width: '100%', maxWidth: 1200, margin: 'auto' }}>
      <Grid
        container
        spacing={4}
        justifyContent="space-between"
        alignItems="center"
      >
        {/* Left Side: Text Content */}
        <Grid item xs={12} md={5}>
          <Typography variant="h4" component="h1" gutterBottom className="Nasaliza" textAlign={"left"}>
            Streamlined Claims Management with Our User-Friendly <span className="Nasaliza" style={{ color: 'blue', fontSize: '2rem', textAlign: 'left' }}>FNOL Solution</span>
          </Typography>
          <Typography variant="subtitle1" component="p" gutterBottom sx={{fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                  textAlign: 'justify',
                  hyphens: 'auto',
                  wordBreak: 'break-word',
                  '& > span': { display: 'inline-block' }
                }}>
            Our FNOL service simplifies the insurance claims process, focusing on user-friendliness, efficiency, and exceptional customer service. With an intuitive interface and step-by-step guidance, we make claims reporting easy and accessible. Our streamlined approach helps expedite claim handling, improve data accuracy, and enhance transparency. Policyholders can effortlessly report claims through our user-centric platform, receiving clear support and timely updates throughout their journey.
          </Typography>
        </Grid>

        {/* Right Side: Cards */}
        <Grid item xs={12} md={7}>
          <Grid container spacing={4}>
            {/* First and Second Cards */}
            {features.slice(0, 2).map((feature, index) => (
              <Grid item key={index} xs={12} sm={6}>
                <Card
                  sx={{
                    height: "auto",
                    margin: "0 auto",
                    textAlign: "center",
                    padding: 2,
                    borderRadius: "12px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    "&:hover": { boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.3)" },
                  }}
                >
                  <CardContent>
                    <Box sx={{ mb: 2 }}>{feature.icon}</Box>
                    <Typography variant="h6" component="h2" className="Nasaliza" color="#010066">
                      {feature.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif" }}>
                      {feature.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
            {/* Third Card */}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Card
                  sx={{
                    height: "auto",
                    textAlign: "center",
                    padding: 2,
                    borderRadius: "12px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    "&:hover": { boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.3)" },
                  }}
                >
                  <CardContent>
                    <Box sx={{ mb: 2 }}>{features[2].icon}</Box>
                    <Typography variant="h6" component="h2" className="Nasaliza" color="#010066">
                      {features[2].title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif" }}>
                      {features[2].description}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
    {/*...............................................................FNOL......................... section -2 */}


    {/* ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,file claims............................................................ */}
    <Grid className="container-idp" sx={{
      width: "100%",
      maxWidth: 1200, margin: 'auto'
    }}>
      <Grid className="card-idp card-idp-img card1-margin"  >
        <img src={FNOL_Flowchart} alt="FNOL_Flowchart" className="Fnol_flowchart" />
      </Grid>
      {/* <Grid className="side-Fnol-btn "> */}

      <Grid className=" card-idp card2-margin" >
        <div className="img_box">


          <img src={File_claims} alt="File_claims" className="imghandel" />
        </div>

        <Typography variant="h4" className="card-titleIDP text_hover Nasaliza">
          File Your Claims Online Today
        </Typography>
        <Typography className="text_hover" >
          log in to SmartFNOL and easily file your claims and upload necessary documents.
        </Typography>

        {
          Authorization ? (
            <Link
              style={{ color: 'black' }}
              onClick={() => checked("/login")}
              to={Authorization ? "/claimcapture" : "/signin"}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              <Box>
              <StyledButtonComponent buttonWidth={100} >
                Demo
              </StyledButtonComponent>
              <StyledButtonComponent buttonWidth={200} sx={{marginLeft:'20px'}}>
                            <PlayCircleFilled sx={{ marginRight: '8px' }} />
                            <a href="https://www.youtube.com/@InnovonTek" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                            Watch Video
                            </a>
                          </StyledButtonComponent>
              </Box>
            </Link>
          ) : (
            <Link
              style={{ color: 'black' }}
              onClick={() => checked("/login")}
              to="/requestdemo"
              // to={Authorization ? "/claimcapture" : "/signin"}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              <Box>
              <StyledButtonComponent buttonWidth={250} >
                Request for Demo
              </StyledButtonComponent>
              <StyledButtonComponent buttonWidth={200} sx={{marginLeft:'20px'}}>
                            <PlayCircleFilled sx={{ marginRight: '8px' }} />
                            <a href="https://www.youtube.com/@InnovonTek" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                            Watch Video
                            </a>
                          </StyledButtonComponent>
                          </Box>
            </Link>
          )
        }

      </Grid>
      {/* </Grid> */}
    </Grid>
    {/* .................................................footer...................... */}
    <Footer />
  </>
}
