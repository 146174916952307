import {
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  Button, useMediaQuery, useTheme
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import About_Us from "../assets/About_Us.webp";
import Header from "../components/header";
import Footer from "../components/footer";
import CustomerRating from '../assets/CustomerRating.png'
import Innovatiive from '../assets/Innovative.png'
import ClaimsHandeled from '../assets/ClaimsHandeled.png'
import CustomPlans from '../assets/CustomPlans.png'
import aibrain from '../assets/ai-brain.png'
import Support247 from '../assets/Support247.png'
import UserFrd from '../assets/UserFRD.png'
import SecureData from '../assets/SecureData.png'
import CustomAI from '../assets/CustomAI.png'
 
export default function Aboutus() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
 
  const getTextAlign = () => {
    if (isMobile) return 'left';
    if (isTablet) return 'justify';
    return 'justify';
  };
 
 
  return (
    <>
      <Header />
 
      <Box sx={{ marginTop: { xs: "60px", sm: "80px", md: "120px" }, padding: { xs: '0 20px', sm: '0 40px', md: '0 60px' } }} id="section1">
        <Grid container justifyContent="center" sx={{ textAlign: 'center' }}>
          <Typography variant="h3" className="Nasaliza" component="h1" sx={{ fontWeight: 'bold', fontSize: { xs: '20px', sm: '30px', md: '36px' }, color: 'blue' }}>
            About Us
          </Typography>
        </Grid>
        <Grid container spacing={4} sx={{ marginTop: { xs: "40px", sm: "80px", md: "155px" } }} className="about_box">
          <Grid item xs={12} md={6} className="about_content" sx={{ marginTop: { xs: "20px", sm: "40px", md: "0" } }}>
            <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', fontSize: { xs: '16px', sm: '24px', md: '28px' } }} className="Nasaliza">
              Innovon's Insurance Solutions
              <Box component="span" sx={{ display: 'block', borderBottom: "3px solid blue", width: { xs: "50%", sm: "100px", md: "170px" }, marginTop: 1 }} />
            </Typography>
            <Typography variant="body1" sx={{ textAlign: getTextAlign(), marginTop: { xs: "10px", sm: "20px", md: "40px" }, fontSize: { xs: '14px', sm: '16px', md: '18px' } }}>
              Innovontek is a leading provider of innovative insurance solutions. We offer cutting-edge technology solutions to streamline insurance processes and provide you with unparalleled customer service. Our team of experts is dedicated to delivering the best insurance experience possible for our clients.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className="about_image" sx={{ textAlign: 'center' }}>
            <img src={About_Us} alt="About_Us" style={{ width: '100%', maxWidth: '400px', height: 'auto' }} />
          </Grid>
        </Grid>
 
        <ThemeProvider theme={theme}>
          <Box sx={{ textAlign: "center", p: 2 }}>
            <Grid marginTop={"35px"}>
 
              <Typography variant="h4" className="Nasaliza" style={{ color: 'orangered' }}>
                Success Stats
              </Typography>
              <Typography variant="body1" style={{ marginTop: '30px' }} sx={{ fontWeight: '400', fontSize: { xs: '14px', sm: '16px', md: '18px' } }}>
                InsurAI is more than insurance—we're changing the game with numbers
                that speak volumes.
                <Typography sx={{ fontWeight: '400', fontSize: { xs: '14px', sm: '16px', md: '18px' } }}>
 
                  Join the revolution and be part of our success story.
                </Typography>
              </Typography>
            </Grid>
            <Grid container spacing={2} justifyContent="center" sx={{ my: 3 }}>
              <Grid item xs={12} sm={4}>
                <Card style={{ boxShadow: "5px 5px 2px  rgba(0, 0, 0, 0.1)", border: '1px solid blue', height: '180px' }}>
                  <CardContent>
                    <img src={CustomerRating} alt="CustomerRating" style={{ width: '70px', height: '70px' }} />
                    <Typography variant="h6">98 %</Typography>
                    <Typography variant="body2" className="Nasaliza">Client Satisfaction</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
 
                <Card style={{ boxShadow: "5px 5px 2px rgba(0, 0, 0, 0.1)", border: '1px solid blue', height: '180px' }}>
                  <CardContent>
                    <img src={Innovatiive} alt="Innovatiive" style={{ width: '70px', height: '70px' }} />
                    <Typography variant="h6">35</Typography>
                    <Typography variant="body2" className="Nasaliza">Innovative Plans</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card style={{ boxShadow: "5px 5px 2px rgba(0, 0, 0, 0.1)", border: '1px solid blue', height: '180px' }}>
                  <CardContent>
                    <img src={ClaimsHandeled} alt="ClaimsHandeled" style={{ width: '70px', height: '70px' }} />
                    <Typography variant="h6">50,000</Typography>
                    <Typography variant="body2" className="Nasaliza">Claims Handled</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid marginTop={'100px'}></Grid>
            <Typography variant="h4" className="Nasaliza" style={{ color: 'green' }}>
            Core Features
            </Typography>
            <Typography variant="body1" style={{ marginTop: '30px' }} sx={{ fontWeight: '400', fontSize: { xs: '14px', sm: '16px', md: '18px' } }}>
            Unique, intuitive, and powerful—our AI-driven features transform how insurance is managed, experienced, and enjoyed.
              <Typography sx={{ fontWeight: '400', fontSize: { xs: '14px', sm: '16px', md: '18px' } ,marginBottom:"35px"}}>
 
              </Typography>
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
                <Card style={{ boxShadow: "5px 5px 2px rgba(0, 0, 0, 0.1)", border: '1px solid green', height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingBottom: '18px' }}>
                  <CardContent style={{ textAlign: 'center' }}>
                    <img src={CustomPlans} alt="CustomPlans" style={{ width: '70px', height: '70px' }} />
                    <Typography variant="h6" className="Nasaliza">Custom Plans</Typography>
                    <Typography variant="body2">
                      Tailored insurance strategies designed to fit your individual
                      needs, ensuring comprehensive coverage and peace of mind.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
                <Card style={{ boxShadow: "5px 5px 2px rgba(0, 0, 0, 0.1)", border: '1px solid green', height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <CardContent>
                    <img src={aibrain} alt="ai-brain" style={{ width: '70px', height: '70px' }} />
                    <Typography variant="h6" className="Nasaliza">AI-Powered Insurance </Typography>
                    <Typography variant="body2">
                    Accelerate your claim process with our cutting-edge AI agents and Intelligent Document Processing (IDP), ensuring rapid and efficient handling of your insurance claims.
                    </Typography>
 
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
                <Card style={{ boxShadow: "5px 5px 2px rgba(0, 0, 0, 0.1)", border: '1px solid green', height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingBottom: '18px' }}>
                  <CardContent>
                    <img src={Support247} alt="Support247" style={{ width: '70px', height: '70px' }} />
                    <Typography variant="h6" className="Nasaliza">Support 24/7</Typography>
                    <Typography variant="body2">
                      Our dedicated support team is available around the clock,
                      ready to assist you with any inquiries or claims you may have.
                    </Typography>
 
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
 
{/* /////////////// Why Choose Innovon Tech */}
<Grid marginTop={'100px'}></Grid>
            <Typography variant="h4" className="Nasaliza" style={{ color: 'blue' }}>
            Why Choose Innovon Tech
            </Typography>
            <Typography variant="body1" style={{ marginTop: '30px' }} sx={{ fontWeight: '400', fontSize: { xs: '14px', sm: '16px', md: '18px' } }}>
            Engage with a technology that understands you. At InsurAISphere, we ensure a future-proof insurance service that evolves with your needs.
 
 
              <Typography sx={{ fontWeight: '400', fontSize: { xs: '14px', sm: '16px', md: '18px' } ,marginBottom:"35px"}}>
 
              </Typography>
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
                <Card style={{ boxShadow: "5px 5px 2px rgba(0, 0, 0, 0.1)", border: '1px solid blue', height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingBottom: '18px' }}>
                  <CardContent style={{ textAlign: 'center' }}>
                    <img src={UserFrd} alt="UserFrd" style={{ width: '70px', height: '70px' }} />
                    <Typography variant="h6" className="Nasaliza">User-Friendly</Typography>
                    <Typography variant="body2">
                    Our platform is designed for ease of use, making insurance management accessible to everyone.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
                <Card style={{ boxShadow: "5px 5px 2px rgba(0, 0, 0, 0.1)", border: '1px solid blue', height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <CardContent>
                    <img src={CustomAI} alt="CustomAI" style={{ width: '70px', height: '70px' }} />
                    <Typography variant="h6" className="Nasaliza">Custom AI </Typography>
                    <Typography variant="body2">
                    Personalized AI that adapts to your insurance preferences for a tailored experience.
                    </Typography>
 
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
                <Card style={{ boxShadow: "5px 5px 2px rgba(0, 0, 0, 0.1)", border: '1px solid blue', height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingBottom: '18px' }}>
                  <CardContent>
                    <img src={SecureData} alt="SecureData" style={{ width: '70px', height: '70px' }} />
                    <Typography variant="h6" className="Nasaliza">Secure Data</Typography>
                    <Typography variant="body2">
                    Paramount security measures to protect your sensitive information within our AI systems.
                    </Typography>
 
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </ThemeProvider>
      </Box><br/>

 
      <Footer />
    </>
  );
}