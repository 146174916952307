import { useState } from "react";
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDBox";
import MDTypography from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDTypography";
import MDButton from "../../../CompanyDashboardChartsCardsLayouts/CompanyDashboardComponents/MDButton";
import EmailtoFNOLClaimsView from "./EmailtoFNOLClaimsView";

// Material Dashboard 2 React context
import { useMaterialUIController } from "../../../CompanyDashboardChartsCardsLayouts/context";
import axios from "axios";
import { CircularProgress } from "@mui/material"; // Import CircularProgress

function EmailtoFNOLBatchCard({ title }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [expanded, setExpanded] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const [processCount, setProcessCount] = useState({ success: 0, failure: 0 });
  const [claims, setClaims] = useState({ success: [], failure: [], editedSuccess: [] });
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  // const handleFetchClaims = () => {
  //   // Simulate fetching claims
  //   const fetchedClaims = Math.floor(Math.random() * 100) + 1;
  //   setFetchCount(fetchedClaims);
  //   // Simulate processing claims based on fetched claims
  //   const success = Math.floor(Math.random() * fetchedClaims);
  //   const failure = fetchedClaims - success;
  //   setProcessCount({ success, failure });
  // };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleFetchClaims = async () => {
    setIsLoading(true); // Start loading indicator

    try {

      const response = await axios.post(
        `${process.env.REACT_APP_URL}get_email_to_fnol_claims/`
      );

      console.log("Claims Data: Email to FNOL", response.data);
      setClaims({
        success: Object.values(response.data.success_data),
        failure: Object.values(response.data.failure_data),
        editedSuccess: [], // You can add edited success data here if your API provides it
      });
      setProcessCount({
        success: response.data.no_of_success_mails,
        failure: response.data.no_of_failure_mails,
      });
    } catch (error) {
      console.error("Error fetching claims:", error);
    }
    finally {
      setIsLoading(false); // Stop loading regardless of success or failure
    }
  };
  return (
    <Card id="delete-account">
      <MDBox pt={2} pb={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          {title}
        </MDTypography>
        <MDBox display="flex" alignItems="center">
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleFetchClaims}
            sx={{ mr: 2, ml: 4, display: 'flex', alignItems: 'center' }}
            disabled={isLoading}
          >
            {isLoading && <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />}
            {isLoading ? "Fetching..." : "Fetch Claims"}
          </MDButton>
          <MDTypography variant="h6" fontWeight="medium" color="textSecondary">
            Success: {processCount.success}, Failure: {processCount.failure}
          </MDTypography>
        </MDBox>
        <MDButton variant="gradient" color="dark" onClick={toggleExpand}>
          <Icon sx={{ fontWeight: "bold" }}>{expanded ? "expand_less" : "expand_more"}</Icon>
          &nbsp;{expanded ? "Hide Email Claims" : "View Email Claims"}
        </MDButton>
      </MDBox>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <MDBox p={2}>
          <EmailtoFNOLClaimsView claimsData={claims} />
        </MDBox>
      </Collapse>
    </Card>
  );
}

// Setting default values for the props of EmailtoFNOLBatchCard
EmailtoFNOLBatchCard.defaultProps = {
  title: "Email Claims",
};

// Typechecking props for the EmailtoFNOLBatchCard
EmailtoFNOLBatchCard.propTypes = {
  title: PropTypes.string,
};

export default EmailtoFNOLBatchCard;
