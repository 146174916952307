import React, { useState } from 'react';
import FileUpload from '../../components/fileupload';
import { styled } from '@mui/system';
import processclaim from "../../assets/processclaim.png";
import axios from 'axios';
import { CircularProgress, Button, Typography, Grid } from '@mui/material';
import {
    useTheme, useMediaQuery, Table, TableBody, TableCell, TableRow, TableContainer,
    TableHead,
    Paper, Box
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { css } from "@emotion/css";
import { GoogleGenerativeAI } from "@google/generative-ai";



// Styled components
const StyledButton = styled(Button)(({ theme, marginTop }) => ({
    justifyContent: "center",
    textAlign: "center",
    width: 200,
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    border: 0,
    borderRadius: 20,
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
    color: 'white',
    height: 40,
    padding: '0 2px',
    margin: `2rem auto ${marginTop || '0'}`, // Use the marginTop prop or default to 0
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    fontSize: '0.875rem',
    '&:hover': {
        background: 'linear-gradient(45deg, #1976D2 30%, #00BCD4 90%)',
        transform: 'scale(1.05)',
        boxShadow: '0 6px 10px 4px rgba(33, 203, 243, .4)',
    },
}));

const GlassyCard = styled(motion.div)(({ theme, isFlex = true, flexDirection = 'column', justifyContent = 'space-between', bgColor = 'rgba(255, 255, 255, 0.8)', blur = '10px' }) => ({
    background: bgColor,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
    backdropFilter: `blur(${blur})`,
    border: '1px solid rgba(255, 255, 255, 0.18)',
    height: '100%',
    display: isFlex ? 'flex' : 'block',// Use the display prop
    flexDirection: flexDirection, // Use the flexDirection prop
    justifyContent: justifyContent, // Use the justifyContent prop
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 12px 48px 0 rgba(31, 38, 135, 0.3)',
    },
}));

const labelMapping = {
    "guarantor_name": "Guarantor Full Name",
    "guarantor_address": "Guarantor Address",
    "guarantor_number": "Guarantor Number",
    "patient_name": "Patient Full Name",
    "account_number": "Patient ID",
    "patient_address": "Patient Address",
    "hospital_address": "Hospital Location",
    "service_doctor": "Attending Physician",
    "date_of_service": "Date of Treatment",
    "payments_adjustments": "Insurance Payment",
    "patient_balance": "Patient Balance",
    "charges": "Total Billed Amount",
    "insurance_payments_adjustments": "Insurance payments/adjustments",
    "statement_date": "Statement Date",
    "billing_support_contact": "Billing Support Contact"
};


const ExtractedDataDisplay = ({ displayValues, dataSource }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const formatValue = (value) => {
        if (value === null || value === undefined || value === '') return 'Not Found';
        if (value === 0 || value === '0') return '$0';
        return `${value}`;
    };

    return (
        <TableContainer
            component={Paper}
            style={{
                boxShadow: 'none',
                background: 'transparent',
                height: '72%',
                overflow: 'auto',
                scrollbarWidth: 'thin',
                overflowY: 'scroll',
                marginTop: dataSource === 'image' ? '40px' : '20px' // Conditional margin
            }}
        >
            <style>
                {`
                    /* For Firefox */
                    ::-webkit-scrollbar {
                        width: 0px; /* Width of the scrollbar */
                    }
                    ::-webkit-scrollbar-track {
                        background: transparent; /* Background of the scrollbar track */
                    }
                    ::-webkit-scrollbar-thumb {
                        background-color: #1976D2; /* Color of the scrollbar thumb */
                        border-radius: 10px; /* Rounded corners for the thumb */

                    }
                    ::-webkit-scrollbar-thumb:hover {
                        background-color: #155A8A; /* Darker color on hover */
                    }
                `}
            </style>
            <Table size={isMobile ? 'small' : 'medium'} padding="normal">
                <TableHead>
                    <TableRow>
                        <TableCell align="left" style={{ fontWeight: 'bold', color: '#1976D2', borderBottom: '1px solid #1976D2', padding: '8px' }}>
                            Properties
                        </TableCell>
                        <TableCell align="left" style={{ fontWeight: 'bold', color: '#1976D2', borderBottom: '1px solid #1976D2', padding: '8px', width: '300px' }}>
                            Values
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(displayValues).map(([originalLabel, value]) => (
                        <TableRow key={originalLabel}>
                            <TableCell align="left" component="th" scope="row" style={{ padding: '12px' }}>
                                {labelMapping[originalLabel] || originalLabel}
                            </TableCell>
                            <TableCell align="left" style={{ padding: '12px' }}>
                                {typeof value === 'object' && value !== null ? (
                                    <ul style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
                                        {Object.entries(value).map(([key, val]) => (
                                            <li key={key}>{`${key}: ${formatValue(val)}`}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    formatValue(value)
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
// Default values for display
const Guarantor_BeforeProcessDisplayValues = {
    "Guarantor Name": " ",
    "Guarantor Address": " ",
    "Guarantor Number": " ",
};

const Patient_BeforeProcessDisplayValues = {
    "Patient Name": " ",
    "Patient Account Number": " ",
    "Patient Address": " "
};

const Service_BeforeProcessDisplayValues = {
    "Hospital Address": " ",
    "Service Doctor Name": " ",
    "Date Of Service": " ",
    "Insurance Money Received": " ",
    "Patient Payment Due": " ",
    "Total Charges": " ",
    "Insurance payments/adjustments": " ",
    "Statement Issued Date": " ",
    "Customer Billing Support": " "
};
const AnimatedTableRow = motion(TableRow);
const styles = {
    container: css`
      padding: 1rem;
      text-align: center;
      height: 70vh;

    `,
    fadeIn: css`
      animation: fadeIn 2s ease-in-out;
    `,
    uploading: css`
      margin-top: 2rem;
      animation: fadeIn 2s ease-in-out;
    `,
    scanningContainer: css`
      margin-top: 2rem;
      position: relative;
      width: 300px;
      height: 400px;
      background-color: #e0e0e0;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
    `,
    scanningEffect: css`
      position: relative;
      width: 100%;
      height: 100%;
    `,
    scanLine: css`
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: green;
      animation: scanAnimation 2s linear infinite;
    `,
    jsonData: css`
      margin-top: 2rem;
      padding: 1rem;
      background-color: #e0f7fa;
      text-align: left;
    `,
};

const MedBillFun = () => {
    const theme = useTheme();
    const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
    const genAI = new GoogleGenerativeAI(API_KEY);
    const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadIn] = useState("portal");
    const [loading, setLoading] = useState(false);
    const [previews, setPreviews] = useState([]);
    const [afterProcess, setAfterProcess] = useState(false);
    const [displayValues_guarantor, setDisplayValues_guarantor] = useState({});
    const [displayValues_patient, setDisplayValues_patient] = useState({});
    const [displayValues_service_info, setDisplayValues_service_info] = useState({});
    const [filePreview, setFilePreview] = useState(null);
    const Authorization = !!localStorage.getItem("Auth") || !!sessionStorage.getItem("NonInsuredAuth");

    const handleReset = () => {
        setAfterProcess(false);
        setDisplayValues_guarantor({});
        setDisplayValues_patient({});
        setDisplayValues_service_info({});
        setSelectedFile(null);
        setPreviews([]);
        setLoading(false);
        setIsSubmitDisabled(true);
        setFilePreview(null);
    };

    const handleFilesUploadToMedBill = (selectedFiles, previews) => {
        setSelectedFile(selectedFiles[0]);
        setPreviews(previews);
        setIsSubmitDisabled(false);
    };

    const handleFileRemove = () => {
        handleReset();
    };


    const processMedbill = async () => {
        if (!selectedFile) {
            alert("Please select a file first.");
            return;
        }

        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('file', selectedFile);

            const allowedFormats = ['pdf', 'jpg', 'png', 'jpeg'];
            const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

            if (!allowedFormats.includes(fileExtension)) {
                alert("Invalid file format. Please upload a PDF, JPG, PNG, or JPEG file.");
                setLoading(false);
                return;
            }

            const response = await axios.post(`${process.env.REACT_APP_URL}AI/process_medbill/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log("API Response:", response);

            if (response && response.data) {
                let extractedData;
                if (response.data.Extracted_information) {
                    console.log("Extracted Information:", response.data.Extracted_information);
                    extractedData = response.data.Extracted_information;
                } else if (response.data.image) {
                    const imagesData = response.data.image;
                    console.log("Number of images received:", imagesData.length);
                    const extractedDetailsArray = [];

                    for (let i = 0; i < imagesData.length; i++) {
                        const base64Image = imagesData[i];
                        console.log(`Processing image ${i + 1}`);

                        let prompt1 = `You are a data analyst working for Innovon Technologies. Extract the following details from the image:
                        - Patient Name
                        - Patient Address
                        - Account Number
                        - Guarantor Name
                        - Guarantor Address
                        - Guarantor Number
                        - Hospital Address
                        - Service Doctor
                        - Statement Date
                        - Date of Service
                        - Charges (including $ symbol)
                        - Payments and Adjustments (including $ symbol): Value ("payments_adjustments" is a general term applicable to any payment corrections)
                        - Insurance payments/adjustments (include $ symbol)
                        - Patient Balance (including $ symbol): Value
                        - Billing Support Contact Number

                        Please provide the extracted information in a simple key: value format, with each piece of information on a new line. If a piece of information is Not found, please replace it with "Not found" ,do not make things up.`;

                        try {
                            const result1 = await model.generateContent([
                                prompt1,
                                {
                                    inlineData: {
                                        data: base64Image,
                                        mimeType: 'image/png',
                                    }
                                }
                            ]);

                            const geminiText1 = await result1.response.text();
                            console.log(`Gemini response for image ${i + 1}:`, geminiText1);

                            const extractedInfo = extractInfoFromText(geminiText1);
                            const defaultValues = {
                                patient_name: 'Not found',
                                patient_address: 'Not found',
                                account_number: 'Not found',
                                guarantor_name: 'Not found',
                                guarantor_address: 'Not found',
                                guarantor_number: 'Not found',
                                hospital_address: 'Not found',
                                service_doctor: 'Not found',
                                statement_date: 'Not found',
                                date_of_service: 'Not found',
                                charges: 'Not found',
                                payments_and_adjustments: 'Not found',
                                insurance_payments_adjustments: 'Not found',
                                patient_balance: 'Not found',
                                billing_support_contact: 'Not found'
                            };

                            const mergedExtractedInfo = {
                                ...defaultValues,
                                ...extractedInfo
                            };

                            extractedDetailsArray.push(mergedExtractedInfo);
                            console.log(`Extracted info for image ${i + 1}:`, mergedExtractedInfo);
                        } catch (geminiError) {
                            console.error(`Error generating content for image ${i + 1}:`, geminiError);
                        }
                    }

                    console.log("Final extractedDetailsArray:", extractedDetailsArray);

                    if (extractedDetailsArray.length > 0) {
                        extractedData = mergeExtractedDetails(extractedDetailsArray);
                    } else {
                        console.error("No valid data extracted from images");
                        alert("Unable to extract information from the provided images. Please try again or contact support.");
                        setLoading(false);
                        return;
                    }
                } else {
                    console.error("Extracted information is missing or undefined.");
                    alert("No data could be extracted from the provided file. Please ensure you've uploaded a valid medical bill.");
                    setLoading(false);
                    return;
                }

                // Set display values, replacing undefined or null values with "Not found"
                setDisplayValues_guarantor(removeEmptyValues(extractedData.guarantor_info || {}));
                setDisplayValues_patient(removeEmptyValues(extractedData.patient_info || {}));
                setDisplayValues_service_info(removeEmptyValues(extractedData.service_info || {}));

                setFilePreview(URL.createObjectURL(selectedFile));
                setAfterProcess(true);

            } else {
                console.error("No data returned from the server.");
                alert("An error occurred while processing the file. Please try again later.");
            }
        } catch (error) {
            console.error("Error in medbill processing", error);
            alert("An unexpected error occurred. Please try again or contact support.");
        } finally {
            setLoading(false);
        }
    };

    // Helper function to extract information from text
    function extractInfoFromText(text) {
        const infoObj = {
            patient_info: {},
            guarantor_info: {},
            service_info: {}
        };
        const lines = text.split('\n');
        for (const line of lines) {
            const [key, value] = line.split(':').map(item => item.trim());
            if (key && value) {
                if (key.includes('Patient Name')) infoObj.patient_info.patient_name = value;
                else if (key.includes('Patient Address')) infoObj.patient_info.patient_address = value;
                else if (key.includes('Account Number')) infoObj.patient_info.account_number = value;
                else if (key.includes('Guarantor Name')) infoObj.guarantor_info.guarantor_name = value;
                else if (key.includes('Guarantor Address')) infoObj.guarantor_info.guarantor_address = value;
                else if (key.includes('Guarantor Number')) infoObj.guarantor_info.guarantor_number = value;
                else if (key.includes('Hospital Address')) infoObj.service_info.hospital_address = value;
                else if (key.includes('Service Doctor')) infoObj.service_info.service_doctor = value;
                else if (key.includes('Statement Date')) infoObj.service_info.statement_date = value;
                else if (key.includes('Date of Service')) infoObj.service_info.date_of_service = value;
                else if (key.includes('Charges')) infoObj.service_info.charges = value;
                else if (key.includes('Payments and Adjustments')) infoObj.service_info.payments_adjustments = value;
                else if (key.includes('Insurance payments/adjustments')) infoObj.service_info.insurance_payments_adjustments = value;
                else if (key.includes('Patient Balance')) infoObj.service_info.patient_balance = value;
                else if (key.includes('Billing Support Contact')) infoObj.service_info.billing_support_contact = value;
            }
        }
        return infoObj;
    }

    // Helper function to merge extracted details from multiple images
    function mergeExtractedDetails(detailsArray) {
        return detailsArray.reduce((acc, curr) => {
            ['patient_info', 'guarantor_info', 'service_info'].forEach(key => {
                acc[key] = { ...acc[key], ...curr[key] };
            });
            return acc;
        }, { patient_info: {}, guarantor_info: {}, service_info: {} });
    }

    // Helper function to remove empty, null, or undefined values from an object
    function removeEmptyValues(obj) {
        console.log('removeEmptyValues: ', obj);
        return Object.fromEntries(
            Object.entries(obj).map(([key, value]) =>
                [key, (value == null || value === '') ? 'Not Found' : value]
            )
        );
    }

    // Helper function to extract information from text
    function extractInfoFromText(text) {
        const infoObj = {
            patient_info: {},
            guarantor_info: {},
            service_info: {}
        };
        const lines = text.split('\n');
        for (const line of lines) {
            const [key, value] = line.split(':').map(item => item.trim());
            if (key && value) {
                console.log(infoObj.guarantor_info)
                if (key.includes('Patient Name')) infoObj.patient_info.patient_name = value || ' ';
                else if (key.includes('Patient Address')) infoObj.patient_info.patient_address = value || ' ';
                else if (key.includes('Account Number')) infoObj.patient_info.account_number = value || ' ';
                else if (key.includes('Guarantor Name')) infoObj.guarantor_info.guarantor_name = value || ' ';
                else if (key.includes('Guarantor Address')) infoObj.guarantor_info.guarantor_address = value || ' ';
                else if (key.includes('Guarantor Number')) infoObj.guarantor_info.guarantor_number = value || ' ';
                else if (key.includes('Hospital Address')) infoObj.service_info.hospital_address = value || ' ';
                else if (key.includes('Service Doctor')) infoObj.service_info.service_doctor = value || '  ';
                else if (key.includes('Statement Date')) infoObj.service_info.statement_date = value || '';
                else if (key.includes('Date of Service')) infoObj.service_info.date_of_service = value || ' ';
                else if (key.includes('Charges')) infoObj.service_info.charges = value || ' ';
                else if (key.includes('Payments and Adjustments')) infoObj.service_info.payments_adjustments = value || ' ';
                else if (key.includes('Insurance payments/adjustments')) infoObj.service_info.insurance_payments_adjustments = value || ' ';
                else if (key.includes('Patient Balance')) infoObj.service_info.patient_balance = value || ' ';
                else if (key.includes('Billing Support Contact')) infoObj.service_info.billing_support_contact = value || ' ';
            }
        }
        return infoObj;
    }

    // Helper function to merge extracted details from multiple images
    function mergeExtractedDetails(detailsArray) {
        return detailsArray.reduce((acc, curr) => {
            ['patient_info', 'guarantor_info', 'service_info'].forEach(key => {
                acc[key] = { ...acc[key], ...curr[key] };
            });
            return acc;
        }, { patient_info: {}, guarantor_info: {}, service_info: {} });
    };

    return (
        <>
            <Grid container spacing={4} style={{ width:'100%',maxWidth:1200,margin:"auto" }}>
                <AnimatePresence>
                    {afterProcess ? (
                        <Grid container spacing={3} style={{ marginTop: "2rem", margin:"auto" }}>
                            <Grid item xs={12} md={6} style={{height: 'auto' }}>
                                <GlassyCard
                                    initial={{ opacity: 0, x: -100 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: -100 }}
                                    transition={{ duration: 0.5 }}

                                >
                                    <Typography className="Nasaliza" variant="h6" style={{ color: "#010066" }} align="left">
                                        Document Preview
                                    </Typography>

                                    {filePreview && (
                                        <div style={{ marginTop: '100px' }}>
                                            {selectedFile?.type === "application/pdf" ? (
                                                <iframe
                                                    src={filePreview}
                                                    width="100%"
                                                    height="700"
                                                    title="File Preview"
                                                    style={{ marginTop: '-100px', border: '2px solid black' }}
                                                ></iframe>
                                            ) : (
                                                <img
                                                    src={filePreview}
                                                    alt="File Preview"
                                                    style={{ width: '100%', height: 'auto', marginTop: '-90px' }} // Add your desired margin here
                                                />
                                            )}
                                        </div>
                                    )}

                                    <StyledButton onClick={handleReset} marginTop="300px">
                                        Reupload
                                    </StyledButton>

                                </GlassyCard>
                            </Grid>
                            <Grid item xs={12} md={6} style={{ height: 'auto' }}>
                                <GlassyCard
                                    initial={{ opacity: 0, x: 100 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: 100 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <Typography className="Nasaliza" variant="h6" style={{ color: "#010066" }} align="center">
                                        Extracted Medical Bill Data
                                    </Typography>

                                    <Box mt={2}>
                                        <Typography className="Nasaliza" variant="h6" style={{ color: "#010066" }} align="left">
                                            Guarantor Data
                                        </Typography>
                                        <ExtractedDataDisplay displayValues={displayValues_guarantor} dataSource="pdf" />
                                    </Box>

                                    <Box mt={4}>
                                        <Typography className="Nasaliza" variant="h6" style={{ color: "#010066" }} align="left">
                                            Patient Data
                                        </Typography>
                                        <ExtractedDataDisplay displayValues={displayValues_patient} dataSource="pdf" />
                                    </Box>

                                    <Box mt={4}>
                                        <Typography className="Nasaliza" variant="h6" style={{ color: "#010066" }} align="left">
                                            Service Data
                                        </Typography>
                                        <ExtractedDataDisplay displayValues={displayValues_service_info} dataSource="pdf" />
                                    </Box>
                                </GlassyCard>
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={12} md={6} style={{ height: 'auto' }}>

                                <GlassyCard
                                    initial={{ opacity: 0, x: -100 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: -100 }}
                                    transition={{ duration: 0.5 }}
                                    isFlex={false}
                                >
                                    <Typography className='Nasaliza' variant="h6" style={{ color: "#010066" }}>
                                        Upload Medical Report
                                    </Typography>
                                    <Grid sx={{ marginTop: '5rem' }}>
                                        <FileUpload
                                            id="portal"
                                            multiple={false}
                                            allowedFormats={['pdf', 'jpg', 'png', 'jpeg']}
                                            setIsSubmitDisabled={setIsSubmitDisabled}
                                            selectedFilesInParent={selectedFile ? [selectedFile] : []}
                                            filePreviews={previews}
                                            uploadIn={uploadIn}
                                            onFilesUpload={handleFilesUploadToMedBill}
                                            onFileRemove={handleFileRemove}
                                        />
                                    </Grid>
                                    <Grid sx={{ marginTop: '3rem' }}>
                                        <StyledButton
                                            onClick={processMedbill}
                                            disabled={isSubmitDisabled || loading}
                                            startIcon={loading ? <CircularProgress size={24} color="inherit" /> : <img src={processclaim} alt="process and claim icon" style={{ height: 24 }} />}
                                        >

                                            {loading ? 'Processing...' : 'Process file'}

                                        </StyledButton>
                                    </Grid>


                                </GlassyCard>

                            </Grid>
                            <Grid item xs={12} md={5} style={{  height: '30%' }}>
                                <GlassyCard
                                    initial={{ opacity: 0, x: 100 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: 100 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <Typography className='Nasaliza' variant="h6" style={{ color: "#010066" }}>
                                        Medical Bill Data
                                    </Typography>
                                    {loading ? (
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', margin: '12rem 0rem' }}>
                                            <CircularProgress size={40} color="inherit" />
                                        </div>
                                    ) : (
                                        <>
                                            <Typography
                                                sx={{
                                                    textAlign: 'left',
                                                    color: '#000166',
                                                    marginBottom: '-9px',
                                                }}
                                                className='Nasaliza'
                                                variant="h6"
                                                style={{ color: "#010066" }}
                                            >
                                                Guarantor Information
                                            </Typography>
                                            <div style={{ maxHeight: '200px', overflowY: 'auto', }}>
                                                <Typography sx={{ textAlign: 'left' }}>
                                                    <ExtractedDataDisplay displayValues={Guarantor_BeforeProcessDisplayValues} />
                                                </Typography>
                                            </div>

                                            <Typography
                                                sx={{
                                                    textAlign: 'left',
                                                    color: '#000166',
                                                    marginBottom: '-9px'
                                                }}
                                                className='Nasaliza'
                                                variant="h6"
                                                style={{ color: "#010066" }}
                                            >
                                                Patient Information
                                            </Typography>
                                            <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                <Typography sx={{ textAlign: 'left' }}>
                                                    <ExtractedDataDisplay displayValues={Patient_BeforeProcessDisplayValues} />
                                                </Typography>
                                            </div>

                                            <Typography
                                                sx={{
                                                    textAlign: 'left',
                                                    color: '#000166',
                                                    marginBottom: '-9px'
                                                }}
                                                className='Nasaliza'
                                                variant="h6"
                                                style={{ color: "#010066" }}
                                            >
                                                Service Information
                                            </Typography>
                                            <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                <Typography sx={{ textAlign: 'left' }}>
                                                    <ExtractedDataDisplay displayValues={Service_BeforeProcessDisplayValues} />
                                                </Typography>
                                            </div>
                                        </>

                                    )}
                                </GlassyCard>
                            </Grid>
                        </>
                    )}
                </AnimatePresence>
            </Grid>
        </>
    );
};

export default MedBillFun;