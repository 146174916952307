import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';

import {
  Grid, useTheme,
  useMediaQuery, Typography, Container, Box, Button, Card, Paper, CardContent, CircularProgress, IconButton,
  Tooltip, TextField, Backdrop
} from "@mui/material";

import "../pagesstyles.css";
import "../services.css";
import StyledButtonComponent from "../../components/StyledButton";

import FileUpload from "../../components/fileupload.js";
import processclaim from "../../assets/processclaim.png";
import { Edit as EditIcon, FileCopy as FileCopyIcon, Description as DescriptionIcon, DataObject as DataObjectIcon } from '@mui/icons-material';

import { Document, Page } from "react-pdf";
import texttract from '../../assets/texttract.png'
import { pdfjs } from "react-pdf";

import axios from "axios";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const initialValues = {
  policy_number: "",
  claim_reported_by: "",
  loss_property: "",
  loss_date_and_time: "",
  loss_type: "",
  loss_address: "",
  loss_street: "",
  loss_city: "",
  loss_state: "",
  loss_zip: "",
  loss_country: "",
  police_fire_contacted: false,
  report_number: null, 
  loss_damage_description: "",
  claim_witness_document_names: [],
  claim_witness_document_url: [],
  claim_witness_document_name_url: [],
  claim_process_document_name: [],
  claim_process_document_url: [],
  claim_process_document_name_url: [],
};

export default function IDPfun() {

  const Authorization = !!localStorage.getItem("Auth") || !!sessionStorage.getItem("NonInsuredAuth");
  const navigate = useNavigate();

  // const [missingFields, setMissingFields] = useState({});
  const [enableFields, setEnableFields] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [updateDisplay, setUpdateDisplay] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [uploadIn, setUploadIn] = useState("idp")
  const [selectedWitnessFiles, setSelectedWitnessFiles] = useState([]);
  const [filesUploadedInChild, setFilesUploadedInChild] = useState(false);
  const [selectedProcessFile, setSelectedProcessFile] = useState([]);
  const [queryvalues, setQueryvalues] = useState(initialValues);
  const [displayValues, setDisplayValues] = useState({});
  const [afterProcess, setAfterProcess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [processSubmit, setProcessSubmit] = useState(false);
  const [previews, setPreviews] = useState([]);
  const [hasEmptyOrInvalidValues, setHasEmptyOrInvalidValues] = useState(true);
  const [openFileView, setOpenFileView] = useState(false);
  const [fileContent, setFileContent] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileObjectURL, setFileObjectURL] = useState("");
  const [showFile, setShowFile] = useState(null)
  const [filePreview, setFilePreview] = useState(null);
  const [isHovered, setHovered] = useState(false);
  // const Authorization = localStorage.getItem("Auth");

  const [typedText, setTypedText] = useState("");
  const text = "InnoClaimFNOL";
  const [isVisible, setIsVisible] = useState([false, false, false]);
  const gridRefs = [useRef(), useRef(), useRef()];

  const [uploading, setUploading] = useState(false);
  const [scanning, setScanning] = useState(false);
  const [jsonData, setJsonData] = useState(null);
  const TheamMedia = useTheme();
  const isMobile = useMediaQuery(TheamMedia.breakpoints.down("sm"));
  const isScreen = useMediaQuery('(max-width:900px)');
  const [startAnimation, setStartAnimation] = useState(false);
  const [videoWidth, setVideoWidth] = useState(560); // Initial video width
  const [videoHeight, setVideoHeight] = useState(315); // Initial video height



  const displayFile = (file) => {

    console.log(file.type, file.type.startsWith("image/"));
    if (file.type === "application/pdf") {
      const reader = new FileReader();
      reader.onload = function (e) {
        setFileContent(e.target.result); // Optionally store file content if needed
        setFileType(file.type);
        setFileName(file.name);
        setFileObjectURL(null); // Reset previous object URL if exists
        setFilePreview(URL.createObjectURL(file));  // Display PDF preview directly

        setOpenFileView(true); // Open dialog automatically after file upload
      };
      reader.readAsDataURL(file);
    } else if (file.type.startsWith("image/")) {
      setFileContent(null);
      setFileType(file.type);
      setFileName(file.name);

      setFilePreview(URL.createObjectURL(file)); // Display image preview directly
      setOpenFileView(true); // Open dialog automatically after file upload
    }
    // Add more conditions for other file types as needed
  };


  const [numPages, setNumPages] = useState(null);

  const [validateAddress, setValidateAddress] = useState('')
  const handleUploadProcessDocument = async () => {
    setLoader(true);
    try {
      const file = selectedProcessFile[0];
      const formData = new FormData();
      formData.append('file', file);
      const responseData = await getExtractedDocumentJson(formData);
      if (responseData) {
        const extractedResponseData = responseData.extracted_data;
        console.log(responseData, "extractedResponseData extractedResponseData");
        console.log(responseData.Validated_Address, " Validated Address");
        setValidateAddress(responseData.Validated_Address)
        const insurance_company_claim_storage_point = responseData.insurance_company_data.claim_storage_type;
        setQueryvalues(extractedResponseData);
        setQueryvalues(prevState => ({
          ...prevState,
          claim_storage_type: insurance_company_claim_storage_point,
        }));



        const displayExtractedData = mapResponseToDisplayFields(extractedResponseData);
        setDisplayValues(displayExtractedData);
        setAfterProcess(true);
        setLoader(false);
      } else {
        console.error("API request did not return expected data.");
      }
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  const getExtractedDocumentJson = async (formData) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}AI/process_file/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response);
      
      localStorage.setItem("user", JSON.stringify(response.data.policy_data));
      localStorage.setItem("company", JSON.stringify(response.data.insurance_company_data));
      return response.data;
    } catch (error) {
      setErrorMessage(error.response.data.message)
      setLoader(false);
      return null;
    }
  };
  const cleanLocation=(location)=> {
    console.log(location);
    
    // Replace occurrences of 'None' with an empty string and trim extra spaces
    return location.split(' ').filter(part => part !== 'None').join(' ').trim();
}

  const mapResponseToDisplayFields = (extractedResponseData) => {
    return {
      "Policy Number": extractedResponseData.policy_number,
      "Reported By": extractedResponseData.claim_reported_by,
      "Property Address": cleanLocation(extractedResponseData.address),
      "Loss Date and Time": extractedResponseData.loss_date_and_time,
      "Type of Loss": extractedResponseData.loss_type,
      "Loss Location": cleanLocation(extractedResponseData.loss_location),
   "Police/Fire Department Contacted?": (extractedResponseData.police_fire_contacted === 'None' ? 'None' : (extractedResponseData.police_fire_contacted  ? 'True' : 'False')),

      "Report Number": extractedResponseData.report_number,
      "Loss Damage Description": extractedResponseData.loss_damage_description,
      "Claim Document": queryvalues.claim_process_document_name
    };
  };

  const handleProcessFileRemove = () => {
    setQueryvalues(initialValues);
    setAfterProcess(false);
    setSelectedProcessFile([]);
    // setSelectedWitnessFiles([]);
    setDisplayValues({});
    setHasEmptyOrInvalidValues(true);
    setErrorMessage("");
    // setMissingFields({})
    setEnableFields(false)
    setIsSaved(false)
    setUpdateDisplay(false)

    setFilePreview(null);

  };

  const handlewitnessFileRemove = (fileName) => {
    console.log(fileName);
    setSelectedWitnessFiles((prevFiles) => [
      ...prevFiles.filter((file) => file.name !== fileName),
    ]);
  }

  const handleWitnessFilesUploadToAWSByIDP = (selectedFiles, previews) => {
    setSelectedWitnessFiles(selectedFiles);
    const claimWitnessDocumentNames = selectedFiles.map(file => file.name);
    setQueryvalues(prevState => ({
      ...prevState,
      claim_witness_document_names: claimWitnessDocumentNames,
    }));
  }

  const handleExtractClaimSubmit = (displayValues, queryvalues) => {
    console.log(displayValues, queryvalues);
    setProcessSubmit(true)
    const formData = new FormData();
    formData.append('policy_number', displayValues["Policy Number"] || queryvalues.policy);
    formData.append('loss_date_and_time', displayValues["Loss Date and Time"] || queryvalues.loss_date_and_time);
    formData.append('loss_type', displayValues["Type of Loss"] || queryvalues.loss_type);
    formData.append('loss_property', displayValues["Property Address"] || queryvalues.address);
    formData.append('loss_damage_description', displayValues["Loss Damage Description"] || queryvalues.loss_damage_description);
    formData.append('loss_address', queryvalues.street_number ?? displayValues["Loss Location"]);
    formData.append('loss_street', queryvalues.street_name);
    formData.append('loss_city', queryvalues.loss_city);
    formData.append('loss_state', queryvalues.loss_state);
    formData.append('loss_zip', queryvalues.loss_zip);
    formData.append('loss_country', queryvalues.loss_country);
    formData.append('police_fire_contacted', displayValues["Police/Fire Department Contacted?"] || queryvalues.police_fire_contacted);
    formData.append('report_number', displayValues["Report Number"] || queryvalues.report_number);
    formData.append('claim_reported_by', displayValues["Reported By"] || queryvalues.reported_by || queryvalues.claim_reported_by);
    formData.append('claim_storage_type', queryvalues.claim_storage_type);

    for (let i = 0; i < selectedProcessFile.length; i++) {
      formData.append('process_document', selectedProcessFile[i]);
    }

    for (let i = 0; i < selectedWitnessFiles.length; i++) {
      formData.append('witness_documents', selectedWitnessFiles[i]);
    }
    submitClaimDetails(formData);
  }

  const submitClaimDetails = async (formData) => {
    await axios.post(`${process.env.REACT_APP_URL}AI/add-idp-claim/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        setProcessSubmit(false);
        localStorage.setItem("claimID", JSON.stringify(response.data));
        navigate('/claimsuccess');
        setSelectedProcessFile([]);
        selectedWitnessFiles([]);
        setQueryvalues(initialValues);
        setDisplayValues({})
        // setMissingFields({})

      }).catch(error => {
        console.log(error);
        setProcessSubmit(false);
        if (error.response) { // Check if there is a response from the server
          setErrorMessage(error.response.statusText); // Access statusText if available
        } else if (error.message) { // If there's no response, check for an error message
          setErrorMessage(error.message);
        } else {
          setErrorMessage("An error occurred. Please try again later.");
        }
      });
  };


  const handleInputChange = (field, value) => {
    console.log(field, value);
    setDisplayValues(prevValues => ({
      ...prevValues,
      [field]: value
    }));
    setQueryvalues(prevValues => ({
      ...prevValues,
      [field.toLowerCase().replace(/ /g, '_')]: value // Ensure field names match 
    }));
  };
  const handleSave = () => {
    console.log(Object.values(displayValues).some(value => !value));
    console.log(displayValues);
    setEnableFields(false);

  };

  const check = (updatedDisplayValues) => {
    if (!(Object.values(updatedDisplayValues).some(value => !value))) {
      setIsSaved(true);
      setEnableFields(false);
      setUpdateDisplay(true);
    }

  };
  // console.log(displayValues, queryvalues);

  const handleFilesUploadToAWSByIDP = (selectedFiles, previews) => {
    setSelectedProcessFile(selectedFiles);
    setQueryvalues((prevValue) => ({ ...prevValue, claim_process_document_name: selectedFiles[0].name }));
    displayFile(selectedFiles[0]);
  }
  useEffect(() => {
    setHasEmptyOrInvalidValues(Object.values(displayValues).some(value => !value));
  }, [updateDisplay]);





  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    return () => {
      if (filePreview && typeof filePreview === "string") {
        URL.revokeObjectURL(filePreview);
      }
    };
  }, [filePreview]);
  //.......................................
  useEffect(() => {
    setHasEmptyOrInvalidValues(Object.values(displayValues).some(value => !value));
  }, [displayValues]);



  useEffect(() => {
    const observerOptions = {
      threshold: 0.5,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = parseInt(entry.target.dataset.index);
          setIsVisible((prev) => {
            const newState = [...prev];
            newState[index] = true;
            return newState;
          });
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    gridRefs.forEach((ref, index) => {
      if (ref.current) {
        ref.current.dataset.index = index;
        observer.observe(ref.current);
      }
    });

    return () => {
      gridRefs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [gridRefs]);
  // .....................................


  useEffect(() => {
    // Trigger animation when component mounts
    setStartAnimation(true);
    // Update video dimensions on window resize
    function handleResize() {
      setVideoWidth(window.innerWidth > 768 ? 560 : 320); // Adjust width based on screen size
      setVideoHeight(window.innerWidth > 768 ? 315 : 180); // Adjust height based on screen size
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //render upload

  const [isUploadClicked, setIsUploadClicked] = useState(false);
  const UploadDocument = useRef(null)

  const handleUploadButtonClick = async () => {
    navigate('')
    await setIsUploadClicked(true);
    scrollToGrid();
  };


  const [progress, setProgress] = useState(0);

  const handleProgress = (value) => {
    setProgress(value);
  };

  useEffect(() => {
    // Trigger animation when component mounts
    setStartAnimation(true);
    // Update video dimensions on window resize
    function handleResize() {
      setVideoWidth(window.innerWidth > 768 ? 560 : 320); // Adjust width based on screen size
      setVideoHeight(window.innerWidth > 768 ? 315 : 180); // Adjust height based on screen size
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const startCycle = () => {
      setUploading(true);
      setJsonData(null);

      setTimeout(() => {
        setUploading(false);
        setScanning(true);

        setTimeout(() => {
          setScanning(false);
          const data = {
            "Policy Number": "HI23001001",
            "Property Address": "123 Main Street Springfield 62701 Illinois USA",
            "Loss Date and Time": "2022-01-15",
            "Loss Location": "9573, Northwest Street, Houston, Texas,77030, USA",
            "Type of Loss": "Fire",
            "Loss Description": "Shoot circuit in my house",
            "Reported By": "John Street Singh",
            "Report Number": "FIRE3727",
            "Police/Fire Department Contacted?": "True",
            "Claim Document": "Claim Note 4 (1).pdf",
          };
          setJsonData(data);
        }, 4000); // Time for scanning effect
      }, 2000); // Time for uploading effect
    };

    startCycle(); // Start the initial cycle

    const interval = setInterval(() => {
      startCycle(); // Restart the cycle every 10 seconds
    }, 10000);

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  useEffect(() => {
    const startTyping = () => {
      let index = 0;
      const typingInterval = setInterval(() => {
        if (index <= text.length) {
          setTypedText(text.substring(0, index));
          index++;
        } else {
          clearInterval(typingInterval);
        }
      }, 300);
    };

    startTyping();
    const intervalId = setInterval(startTyping, 10000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.5
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = parseInt(entry.target.dataset.index);
          setIsVisible((prev) => {
            const newState = [...prev];
            newState[index] = true;
            return newState;
          });
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    gridRefs.forEach((ref, index) => {
      if (ref.current) {
        ref.current.dataset.index = index;
        observer.observe(ref.current);
      }
    });

    return () => {
      gridRefs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [gridRefs]);

  const checked = (val) => {
    if (!Authorization) {
      window.scrollTo(0, 0);
      localStorage.setItem("rout", val);
    } else {
      window.scrollTo(0, 0);
    }
  };

  const scrollToGrid = () => {
    if (UploadDocument.current) {
      UploadDocument.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('Footer reference is null');
    }
  };
  return <>

    <div ref={UploadDocument} >
      <Box sx={{ width: '100%', maxWidth: 1200, margin: "auto" }}>

        <Grid container id="grid"  >
          <Grid container style={{ display: "flex", justifyContent: "space-between" }}>
            <Grid item md={6} className="floderuplod-box">
              {!afterProcess && (
                <>
                  <Paper
                    elevation={3} // Adds box shadow
                    sx={{
                      width: "100%",
                      maxWidth: 1000,
                      margin: "auto",
                      mt: 2,
                      borderRadius: 3,
                      p: 4,
                      backdropFilter: "blur(10px)", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",// Blur effect for glass look
                      border: "1px solid rgba(255, 255, 255, 0.2)", // Custom box shadow
                      background: 'transparent'
                    }}
                  >


                    <Typography
                      style={{
                        fontSize: "1.1rem",
                        color: "#010066",
                        fontWeight: "bold",
                        textAlign: 'center'
                      }}
                    >
                      Upload your document to process the Claim
                    </Typography>



                    <FileUpload

                      id="idp"
                      onFilesUpload={handleFilesUploadToAWSByIDP}
                      multiple={false}
                      onFileRemove={handleProcessFileRemove}
                      selectedFilesInParent={selectedProcessFile}
                      allowedFormats={["png", "jpg", "pdf", "txt"]}
                      filesUploadedInChild={filesUploadedInChild}
                      setIsSubmitDisabled={setIsSubmitDisabled}
                      uploadIn={uploadIn}
                    />

                    <Typography
                      style={{
                        fontSize: "1.1rem",
                        color: "#010066",
                        fontWeight: "bold",
                        marginTop: "6%",
                        textAlign: 'center',

                      }}
                    >
                      Upload claim supporting documents.
                    </Typography>
                    <div style={{ width: "71.5%", marginLeft: "4.5rem" }}>
                      <FileUpload
                        id="portal"
                        onFilesUpload={handleWitnessFilesUploadToAWSByIDP}
                        multiple={true}
                        allowedFormats={[
                          "png",
                          "jpg",
                          "jpeg",
                          "plain",
                          "pdf",
                          "DOCX",
                          "mp4",
                          "plain",
                          "msword",
                        ]}
                        setIsSubmitDisabled={setIsSubmitDisabled}
                        selectedFilesInParent={selectedWitnessFiles}
                        filePreviews={previews}
                        filesUploadedInChild={filesUploadedInChild}
                        uploadIn={uploadIn}
                        onFileRemove={handlewitnessFileRemove}
                      />
                    </div>
                  </Paper>

                </>
              )}
              {afterProcess && (
                <>


                  <Paper
                    elevation={3} // Adds box shadow
                    sx={{
                      width: "100%",
                      maxWidth: 1000,
                      margin: "auto",

                      borderRadius: 3,
                      p: 2,
                      backdropFilter: "blur(10px)", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",// Blur effect for glass look
                      border: "1px solid rgba(255, 255, 255, 0.2)", // Custom box shadow
                      background: 'transparent'
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "1.2rem",
                        color: "#010066",
                        fontWeight: "bold",
                        textAlign: 'center'
                      }}
                    >
                      Uploaded Document.
                    </Typography>
                    <Grid style={{ maxWidth: 600, margin: "auto" }}>

                      <Card variant="outlined" sx={{ border: "1px solid blue", height: '530px' }} >
                        <CardContent>
                          <Typography variant="h6" component="h2">
                            {fileName}
                          </Typography>
                          {fileType.startsWith("image/") && (
                            <img
                              src={filePreview}
                              alt={fileName}
                              style={{ maxWidth: "100%", height: "auto" }}
                            />
                          )}
                          {fileType === "application/pdf" && (
                            <iframe
                              title={fileName}
                              src={filePreview}
                              width="100%"
                              height="600px"
                            />
                          )}

                        </CardContent>
                      </Card>
                      <Box mt={2}>
                        <StyledButtonComponent buttonWidth={250}
                          onClick={() => {
                            handleProcessFileRemove()
                            setAfterProcess(false)
                          }}>
                          Upload Document
                        </StyledButtonComponent>
                      </Box>
                      <Typography
                        style={{
                          fontSize: "1.1rem",
                          color: "#010066",
                          fontWeight: "bold",
                          marginTop: "6%",
                          textAlign: 'center'

                        }}
                      >
                        Upload claim supporting documents.
                      </Typography>

                      <FileUpload
                        id="portal"
                        onFilesUpload={handleWitnessFilesUploadToAWSByIDP}
                        multiple={true}

                        allowedFormats={[
                          "png",
                          "jpg",
                          "jpeg",
                          "plain",
                          "pdf",
                          "docx",
                          "mp4",
                          "plain",
                          "msword",
                        ]}
                        setIsSubmitDisabled={setIsSubmitDisabled}
                        selectedFilesInParent={selectedWitnessFiles}
                        filePreviews={previews}
                        filesUploadedInChild={filesUploadedInChild}
                        uploadIn={uploadIn}
                        onFileRemove={handlewitnessFileRemove}
                      />
                    </Grid>
                  </Paper>
                </>
              )}



              <Grid style={{ textAlign: "center" }}>
                <StyledButtonComponent buttonWidth={200}

                  disableColor="#CCCCCC"

                  sx={{ marginTop: "10%", marginBottom: '25px' }}
                  onClick={handleUploadProcessDocument}
                  disabled={
                    selectedProcessFile.length === 0 ||
                    loader === true ||
                    afterProcess === true
                  }
                >
                  <img
                    src={processclaim}
                    alt="process and claim icon"
                    style={{
                      height: 38,
                      paddingRight: 10,

                    }}
                  />
                  Process
                </StyledButtonComponent>
              </Grid>
            </Grid>
            <Grid item md={6} margin={isMobile ? "0rem 1rem " : "0rem 0rem"}>

              <Grid container>

                <Grid className="idp-fetch-container">



                  {hasEmptyOrInvalidValues && (
                    <Typography style={{ color: "red", marginBottom: "10px", textAlign: 'center' }}>
                      Please provide mandatory details in the document to claim
                      the policy.
                    </Typography>
                  )}
                  {afterProcess === true ? (
                    <Paper
                      elevation={3} // Adds box shadow
                      sx={{
                        width: "100%",
                        maxWidth: 1000,
                        margin: "auto",
                        marginTop: '10px',

                        borderRadius: 3,
                        p: 2,
                        backdropFilter: "blur(10px)", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",// Blur effect for glass look
                        border: "1px solid rgba(255, 255, 255, 0.2)", // Custom box shadow
                        background: 'transparent'
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "1.2rem",
                          color: "#010066",
                          fontWeight: "bold",
                          textAlign: 'center',

                        }}

                      > Extracted claim FNOL details.</Typography>

                      <Grid className="fetch-idp-data">

                        {!updateDisplay ? (
                          <>


                            <Typography variant="h5" className="ipd-titles Nasaliza" sx={{ borderBottom: '2px solid #1976D2', display: 'inline-block', width: '100%', marginBottom: '20px' }}>Policy Details</Typography>
                            <Grid container spacing={2} style={{ marginBottom: '7px', marginLeft: isMobile ? '0' : '15px' }}>
                              {Object.entries(displayValues)
                                .filter(([key]) => key === "Policy Number" || key === "Property Address" || key === "Claim Document")
                                .map(([key, value]) => (
                                  <React.Fragment key={key}  >
                                    <Grid item xs={5} sm={5} md={5} style={{ fontWeight: 550, fontSize: 13, textAlign: "left" }}>
                                      {key}
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1.5} style={{ textAlign: "left" }}>
                                      :
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={5.5} style={{ textAlign: "left" }}>
                                      <span style={{ fontWeight: 500, fontSize: 13, wordWrap: "break-word", whiteSpace: "normal" }}>
                                        {value ? (
                                          value === 'None'?'Not Found': value
                                        ) : (
                                          <span style={{ color: "red", fontWeight: 500, fontSize: 13 }}>
                                            {`${key} required`}
                                          </span>
                                        )}
                                      </span>
                                    </Grid>
                                  </React.Fragment>
                                ))}
                            </Grid>
                            <Typography variant="h5" className="ipd-titles Nasaliza" sx={{ borderBottom: '2px solid #1976D2', display: 'inline-block', width: '100%', marginBottom: '20px' }}>Loss Details</Typography>
                            <Grid container spacing={2} style={{ marginBottom: '7px', marginLeft: isMobile ? '0' : '15px' }}>
                              {Object.entries(displayValues)
                                .filter(([key]) => ["Loss Date and Time", "Loss Location", "Type of Loss", "Loss Damage Description"].includes(key))
                                .map(([keys, value]) => (
                                  <>


                                    <React.Fragment key={keys}>
                                      <Grid item xs={5} sm={5} md={5} style={{ fontWeight: 550, fontSize: 13, textAlign: "left" }}>
                                        {keys}
                                      </Grid>
                                      <Grid item xs={1} sm={1} md={1.5} style={{ textAlign: "left" }}>
                                        :
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={5.5} style={{ textAlign: "left", wordWrap: "break-word", whiteSpace: "normal" }}>
                                        {enableFields ?
                                          (
                                            <TextField
                                              sx={{
                                                '& .MuiOutlinedInput-root': {
                                                  height: '35px',
                                                  backgroundColor: "none"
                                                },
                                              }}
                                              variant="outlined"
                                              required
                                              name={keys}
                                              value={displayValues[keys]}
                                              onChange={(e) => handleInputChange(keys, e.target.value)}
                                            />
                                          ) : (<>


                                            {keys === 'Loss Location' ?
                                              <>
                                                <span style={{ fontWeight: 500, fontSize: 13 }}>
                                                  {value ? (
                                                    value === '' ? 'Not Found' : value
                                                  ) : (
                                                    <span style={{ color: "red", fontWeight: 500, fontSize: 13 }}>
                                                      {`${keys} required`}
                                                    </span>
                                                  )
                                                  }
                                                </span>

                                              </>


                                              :
                                              <span style={{ fontWeight: 500, fontSize: 13 }}>
                                                {value ? (
                                                  value === 'None' ? 'Not Found' : value
                                                ) : (
                                                  <span style={{ color: "red", fontWeight: 500, fontSize: 13 }}>
                                                    {`${keys} required`}
                                                  </span>
                                                )
                                                }
                                              </span>

                                            }

                                          </>
                                          )
                                        }
                                      </Grid>
                                    </React.Fragment>
                                  </>
                                ))}
                            </Grid>
                            <Typography variant="h5" className="ipd-titles Nasaliza" sx={{ borderBottom: '2px solid #1976D2', display: 'inline-block', width: '100%', marginBottom: '20px' }}>Report Details</Typography>
                            <Grid container spacing={2} style={{ marginBottom: '7px', marginLeft: isMobile ? '0' : '15px' }}>
                              {Object.entries(displayValues)
                                .filter(([key]) => ["Reported By", "Report Number", "Police/Fire Department Contacted?"].includes(key))
                                .map(([key, value]) => (
                                  <React.Fragment key={key}>
                                    <Grid item xs={5} sm={5} md={5} style={{ fontWeight: 550, fontSize: 13, textAlign: "left" }}>
                                      {key}
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1.5} style={{ textAlign: "left" }}>
                                      :
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={5.5} style={{ textAlign: "left", wordWrap: "break-word", whiteSpace: "normal" }}>
                                      {enableFields ? (
                                        <TextField
                                          sx={{
                                            '& .MuiOutlinedInput-root': {
                                              height: '35px',
                                              backgroundColor: "none"
                                            },
                                          }}
                                          variant="outlined"
                                          required
                                          name={key}
                                          value={displayValues[key]}
                                          onChange={(e) => handleInputChange(key, e.target.value)}
                                        />
                                      ) : (
                                        <span style={{ fontWeight: 500, fontSize: 13 }}>
                                          {value ? (
                                            value === 'None' ? 'Not Found' : value
                                          ) : (
                                            <span style={{ color: "red", fontWeight: 500, fontSize: 13 }}>
                                              {`${key} required`}
                                            </span>
                                          )}
                                        </span>
                                      )}
                                    </Grid>
                                  </React.Fragment>
                                ))}
                            </Grid>

                          </>

                        ) : (
                          <></>
                        )}

                        <Grid container justifyContent="center" alignItems="center" style={{ margin: "3rem 1px" }} spacing={1}>

                          <Grid item>
                            {enableFields ? (
                              <StyledButtonComponent buttonWidth={100}
                                onClick={handleSave}
                              >
                                Save
                              </StyledButtonComponent>
                            ) : (
                              <StyledButtonComponent buttonWidth={100}
                                onClick={() => setEnableFields(true)}
                                startIcon={<EditIcon />}
                              >
                                Edit
                              </StyledButtonComponent>
                            )}
                          </Grid>

                          <Grid item>
                            <StyledButtonComponent buttonWidth={150} disableColor={"#B6E3FF"}
                              onClick={() => handleExtractClaimSubmit(displayValues, queryvalues)}
                              disabled={hasEmptyOrInvalidValues || enableFields}>
                              Submit Claim
                            </StyledButtonComponent>
                          </Grid>


                          <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={processSubmit}
                          >
                            <CircularProgress color="inherit" />
                          </Backdrop>
                        </Grid>


                      </Grid>
                    </Paper>
                  ) : (
                    <Grid style={{ marginTop: "3%" }}>
                      {
                        !loader && !errorMessage && <>
                          <Paper
                            elevation={3} // Adds box shadow
                            sx={{
                              width: "100%",
                              maxWidth: 1000,
                              margin: "auto",
                              height: '424px',
                              borderRadius: 3,
                              p: 4,
                              backdropFilter: "blur(10px)", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",// Blur effect for glass look
                              border: "1px solid rgba(255, 255, 255, 0.2)", // Custom box shadow
                              background: 'transparent',
                              marginTop: '1.49rem'
                            }}
                          >

                            <Grid
                             
                              style={{ width: '100%', margin: "auto" }}>
                              <Typography
                                style={{
                                  fontSize: "1.2rem",
                                  color: "#010066",
                                  fontWeight: "bold",
                                  textAlign: 'center',
                                  marginBottom: '1rem'

                                }}

                              > Extracted claim details will be displayed.</Typography>
                              <img src={texttract} alt="img" style={{ width: '100%' }} />
                            </Grid>
                          </Paper>
                        </>
                      }
                      {loader ? (
                        <>
                          <Typography
                            style={{
                              fontSize: "1.2rem",
                              color: "#010066",
                              fontWeight: "bold",
                              textAlign: 'center',
                              margin: "0rem 0rem",
                              marginTop: "1rem"
                            }}

                          > Extracted claim details will be displayed.</Typography>
                          <Typography style={{ textAlign: "center" }}>
                            {filePreview && (
                              <div className="document-container">
                                <div className={selectedProcessFile[0]?.type !== "application/pdf" ? "document-wrapper" : "pdf-document-wrapper"}>
                                  {selectedProcessFile[0]?.type === "application/pdf" ? (
                                    <Document
                                      file={filePreview}
                                      onLoadSuccess={onDocumentLoadSuccess}
                                      className="pdf-document"
                                    >
                                      <Page pageNumber={1} width={300} />
                                    </Document>
                                  ) : (
                                    <img src={filePreview} alt="File preview" className="document" width={300} height={500} />
                                  )}
                                  {numPages > 1 && (
                                    <Typography style={{ textAlign: "center", fontSize: "0.8rem" }}>
                                      Page 1 of {numPages}
                                    </Typography>
                                  )}
                                  {filePreview &&
                                    <>
                                      <div className="idp-scan-line idp-top"></div>
                                      <div className="idp-scan-line idp-bottom"></div>
                                    </>
                                  }
                                </div>
                              </div>
                            )}
                          </Typography>
                        </>
                      ) : (
                        <Typography style={{ textAlign: "center", color: "red" }}>
                          {errorMessage ? errorMessage : null}
                        </Typography>
                      )
                      }
                    </Grid>
                  )}
                  <Grid marginBottom={"2rem"}></Grid>
                </Grid>
              </Grid>

            </Grid>

          </Grid>
        </Grid>
      </Box>
    </div>
  </>
}