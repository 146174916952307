import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  Paper,
  Typography,
  Box,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio, InputAdornment,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,Tooltip,
  Alert
} from '@mui/material';
import DatePicker from "react-datepicker";
import SelectField from '../../Fields/SelectField';
import StyledButtonComponent from '../../../components/StyledButton';
import { NavigateNext as NavigateNextIcon, NavigateBefore as NavigateBeforeIcon } from "@mui/icons-material";
import { states } from "../../../data/states.js";
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import '../PolicyIntake/Policy.css';
import axios from 'axios';
import {
  // ... your other imports ...
  Edit as EditIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@mui/icons-material';


const DwellingInfo = ({ onNext, onBack, formData, setFormData, dwellingconfirmAddress, setDwellingConfirmAddress, dwellingeditAddress, setDwellingEditAddress, policyHolderAddress, enableReviewButton, onReviewClick, updateStepValidity }) => {
  console.log("dwelling data", formData, setFormData, policyHolderAddress);

  const [errors, setErrors] = useState({});
  const [isAddressValid, setIsAddressValid] = useState(false); // State for validation button
  const [isValidatingAddress, setIsValidatingAddress] = useState(false); // Loading state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [suggestedAddress, setSuggestedAddress] = useState(null);
  const [spittedAddress, setSpittedAddress] = useState(null);
  const [showAddress, setShowAddress] = useState(false);

  useEffect(() => {
    const isValid = isFormValid();
    updateStepValidity(isValid);
  }, [formData, errors, dwellingconfirmAddress]);

  const handleDwellingInfoChange = (event, date, fieldName) => {

    // if (fieldName & date) {
    //   // Ensure date is not null or undefined before calling toISOString
    //   // const formattedDate = date ? date.toISOString().split("T")[0] : ''; // Format date as YYYY-MM-DD or set as an empty string
    //   const year = date.getFullYear(); 
    //   // Update the roof field in formData with the selected date
    //   setFormData((prevFormData) => ({
    //     ...prevFormData,
    //     dwellingInfo: {
    //       ...prevFormData.dwellingInfo,
    //       [fieldName]: year,

    //     },
    //   }));

    //   // Validate the field with the formatted date or empty string
    //   // validateField(fieldName, formattedDate);
    //   validateField(fieldName, year);

    if (fieldName && date) {
      const year = date.getFullYear();

      // Validation: Check if the year is in the future
      if (year > new Date().getFullYear()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: 'Year cannot be in the future.',
        }));
        return; // Don't update formData if year is invalid
      }

      // Update formData with the selected year
      setFormData((prevFormData) => ({
        ...prevFormData,
        dwellingInfo: {
          ...prevFormData.dwellingInfo,
          [fieldName]: year,
        },
      }));

      // Clear the error if valid
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: undefined,
      }));


    } else if (event) {
      // Handle other input changes
      const { name, value } = event.target;
      console.log(name, value);

      setFormData((prevFormData) => ({
        ...prevFormData,
        dwellingInfo: {
          ...prevFormData.dwellingInfo,
          [name]: value,
        },
      }));

      // Check address validity for specific fields
      if (['CoverageLocation_street_number', 'CoverageLocation_street_name', 'CoverageLocation_zip'].includes(name)) {
        checkAddressValidity();
      }

      // Validate the field with the current value
      validateField(name, value);
    }
      // After updating formData, check form validity
      const isValid = isFormValid();
      updateStepValidity(isValid);
  };



  console.log("dwelling data after input change", formData, setFormData);

  // Function to check if address fields are filled
  const checkAddressValidity = () => {
    setIsAddressValid(
      formData.CoverageLocation_street_number &&
      formData.CoverageLocation_street_name &&
      formData.CoverageLocation_zip &&
      !errors.CoverageLocation_street_number && // Check for errors
      !errors.CoverageLocation_street_name &&
      !errors.CoverageLocation_zip
    );
  };

  useEffect(() => {
    checkAddressValidity();
  }, [formData]); // Run once when the component mounts



  // Function to handle address validation API call (update with your API logic)
  const handleValidateAddress = async () => {

    setIsValidatingAddress(true);
    try {
      const address = `${formData.CoverageLocation_street_number} ${formData.CoverageLocation_street_name}, ${formData.CoverageLocation_city}, ${formData.CoverageLocation_state} ${formData.CoverageLocation_zip} ${formData.CoverageLocation_country}`;
      const response = await axios.post(`${process.env.REACT_APP_URL}validate_address/`, { address: address },);
      console.log('API Response:', response.data);

      if (response.data && response.data.validated_address && response.data.splitted_address) {
        setSuggestedAddress(response.data.validated_address);
        setSpittedAddress(response.data.splitted_address);
        setSnackbarSeverity('info');
        setShowAddress(true);
      }


    } catch (error) {
      console.error('Error during address validation:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage(
        error.response && error.response.data && error.response.data.error
          ? `${error.response.data.error}, Please check you address.`
          : 'An error occurred during address validation. Please try again later.'
      );
      setSnackbarOpen(true);

    } finally {
      setIsValidatingAddress(false);
    }
  };

  const handleConfirmAddress = (spittedAddress) => {
    console.log("clcikd for changing fileds", spittedAddress);
    setFormData((prevFormData) => ({
      ...prevFormData,
      dwellingInfo: {
        ...prevFormData.dwellingInfo,
        CoverageLocation_street_number: spittedAddress.street_number || '',
        CoverageLocation_street_name: spittedAddress.street_name || '',
        CoverageLocation_city: spittedAddress.city || '',
        CoverageLocation_state: spittedAddress.state || '',
        CoverageLocation_zip: spittedAddress.zip_code || '',
        CoverageLocation_country: spittedAddress.country || '',
      },
    }));

    // Clear the address field errors after confirming the address
    setErrors((prevErrors) => ({
      ...prevErrors,
      CoverageLocation_street_number: undefined,
      CoverageLocation_street_name: undefined,
      CoverageLocation_city: undefined,
      CoverageLocation_zip: undefined,
      CoverageLocation_country: undefined,
      CoverageLocation_state: undefined,
    }));
    setDwellingConfirmAddress(true);
    setDwellingEditAddress(false);
    setShowAddress(false);

  }

  console.log(errors);


  const handleEditFields = () => {
    setSameAsPolicyInfoAddress(false);
    setDwellingConfirmAddress(false);
    setDwellingEditAddress(true);
  };


  const validateField = (name, value) => {
    const newErrors = { ...errors };

    switch (name) {
      case 'residenceType':
      case 'constructionType':
      case 'heatingType':
      case 'alternateHeating':
      case 'any_business_conducted_on_premises':
      case 'trampolineRamp':
      case 'subjectToFlood':
      case 'floodInsuranceRequested':
      case 'rentedToOthers':
      case 'CoverageLocation_state':
      case 'CoverageLocation_country':
        if (value === "") {
          newErrors[name] = `${name.replace('CoverageLocation_', '').replace(/_/g, ' ')} is required.`;
        } else {
          delete newErrors[name];
        }
        break;
      case 'yearBuilt':
      case 'plumbing_installed_year':
      case 'wiring_installed_year':
      case 'heating_installed_year':
      case 'roof_installed_year':
        // if (value && dayjs(value).isAfter(dayjs(), 'year')) { // Use Day.js for year comparison
        if (value > new Date().getFullYear()) {
          newErrors[name] = 'Year cannot be in the future.';
        } else {
          delete newErrors[name];
        }
        break;

      case 'numberOfStories':
        if (!/^\d+$/.test(value) || parseInt(value, 10) <= 0) {
          newErrors[name] = 'Please enter a valid number of stories (greater than 0).';
        } else {
          delete newErrors[name];
        }
        break;
      case 'squareFootage':
        if (!/^\d+$/.test(value) || parseInt(value, 10) <= 0) {
          newErrors[name] = 'Please enter a valid square footage (greater than 0).';
        } else {
          delete newErrors[name];
        }
        break;
      case 'fireHydrantDistance':
      case 'fireStationDistance':
        if (!/^\d+$/.test(value) || parseInt(value, 10) < 0) {
          newErrors[name] = 'Please enter a valid distance (cannot be negative).';
        } else {
          delete newErrors[name];
        }
        break;
      // Coverage Location Validations
      case 'CoverageLocation_street_number':
        if (!/^\d+$/.test(value)) {
          newErrors[name] = 'Please enter a valid street number.';
        } else {
          delete newErrors[name];
        }
        break;
      case 'CoverageLocation_street_name':
        if (value === '' || value === undefined) {
          newErrors[name] = 'Please enter a valid Street Name.';
        } else if (value.length > 40) {
          newErrors[name] = 'Street Name must be 40 characters or less.';
        } else {
          delete newErrors[name];
        }
        break;
      case 'CoverageLocation_city':
        if (!/^[A-Za-z\s]+$/.test(value)) {
          newErrors[name] = 'Please enter a valid city name (only letters and spaces).';
        } else {
          delete newErrors[name];
        }
        break;
      case 'CoverageLocation_zip':
        if (!/^\d{5}$/.test(value)) {
          newErrors[name] = 'Please enter a valid 5-digit ZIP code.';
        } else {
          delete newErrors[name];
        }
        break;

      // Other Construction Type validation
      case 'otherConstructionType':
        if (formData.constructionType === 'other' && value === "") {
          newErrors[name] = 'Specify Other Construction Type is required.';
        } else {
          delete newErrors[name];
        }
        break;

      // Other Heating Type validation
      case 'otherHeatingType':
        if (formData.heatingType === 'other' && value === "") {
          newErrors[name] = 'Specify Other Heating Type is required.';
        } else {
          delete newErrors[name];
        }
        break;

      default:
        delete newErrors[name];
    }

    setErrors(newErrors);
  };

  const isFormValid = () => {
    const requiredFields = [
      'residenceType',
      'constructionType',
      'yearBuilt',
      'numberOfStories',
      'squareFootage',
      'heatingType',
      'plumbing_installed_year',
      'wiring_installed_year',
      'heating_installed_year',
      'roof_installed_year',
      'fireHydrantDistance',
      'fireStationDistance',
      'alternateHeating',
      'any_business_conducted_on_premises',
      'trampolineRamp',
      'subjectToFlood',
      'floodInsuranceRequested',
      'rentedToOthers',
      'CoverageLocation_street_number',
      'CoverageLocation_street_name',
      'CoverageLocation_city',
      'CoverageLocation_state',
      'CoverageLocation_country',
      'CoverageLocation_zip',
    ];

    for (const field of requiredFields) {
      if (
        (formData[field] === "" || errors[field]) &&
        // Check for otherConstructionType only if constructionType is 'other'
        (field !== 'otherConstructionType' || formData.constructionType === 'other') &&
        // Check for otherHeatingType only if heatingType is 'other'
        (field !== 'otherHeatingType' || formData.heatingType === 'other')
      ) {
        return false;
      }
    }
    return true;
  };

  console.log(formData, 'data');
  const handleNext = () => {
    if (isFormValid()) {
      onNext("dwellingInfo", formData);
    } else {
      console.log("Form has errors or missing required fields. Please correct them.");
    }
  };

  const CustomStylesForTextFileds = {
    '&:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
    },
    '&.Mui-focused:after': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
    },
    '& .MuiInputBase-input': {
      // Target the input element
      fontSize: '13px', // Set the desired font size here
    },

  };

  const renderYearField = (fieldName, label) => (
    <Grid item xs={12} sm={6} md={6}>
      <DatePicker
        selected={formData[fieldName] ? new Date(formData[fieldName], 0, 1) : null}
        onChange={(date) => handleDwellingInfoChange(null, date, fieldName)}
        dateFormat="yyyy"
        showYearPicker
        dropdownMode="select"
        placeholderText='yyyy'
        customInput={
          <TextField
            label={label}
            name={fieldName}
            required
            fullWidth
            value={formData[fieldName] ? formData[fieldName].toString() : ''}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            error={!!errors[fieldName]}
            helperText={errors[fieldName]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
          />
        }
      />
    </Grid>
  );




  const [sameAsPolicyInfoAddress, setSameAsPolicyInfoAddress] = useState(false);
  const handleRadioChange = (event) => {
    console.log(event.target.value);
    setSameAsPolicyInfoAddress(event.target.value === 'yes');
    if (event.target.value === 'yes') {
      handleConfirmAddress(policyHolderAddress);
    }
  };



  return (
    <Grid
      container
      sx={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,
        overflowX: 'hidden',
        margin: 'auto',
      }}
    >
      <Grid item xs={12} md={8} textAlign='center'>
        <Typography
          className='Nasaliza'
          variant="h6"
          sx={{
            fontWeight: 600,
            mb: 2, mt: 2,
            color: '#010066',
            textAlign: 'left',
          }}
        >
          Property Information
        </Typography>

        <Grid item xs={12}>
          <Paper elevation={2} sx={{ padding: 2, marginTop: 2 }}>
            <Grid container spacing={2}>
              {/* Row 1: Residence Type and Construction Type */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <SelectField
                    name="residenceType"
                    label="Residence Type"
                    required
                    options={[
                      { value: 'single_family', label: 'Single Family' },
                    ]}
                    value={formData.residenceType}
                    onChange={handleDwellingInfoChange}
                    error={!!errors.residenceType}
                    helperText={errors.residenceType ? <FormHelperText error>{errors.residenceType}</FormHelperText> : ""}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <SelectField
                    name="constructionType"
                    label="Construction Type"
                    required
                    options={[
                      { value: 'frame', label: 'Frame' },
                      { value: 'masonry', label: 'Masonry' },
                      { value: 'fire_resistive', label: 'Fire Resistive' },
                      { value: 'steel_frame', label: 'Steel Frame' },
                      { value: 'log_cabin', label: 'Log Cabin' },
                      { value: 'other', label: 'Other' },
                    ]}
                    value={formData.constructionType}
                    onChange={handleDwellingInfoChange}
                    error={!!errors.constructionType}
                    helperText={errors.constructionType ? <FormHelperText error>{errors.constructionType}</FormHelperText> : ""}
                  />
                </FormControl>
              </Grid>
              {formData.constructionType === 'other' && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Specify Other Construction Type"
                    name="otherconstructionType"
                    value={formData.otherconstructionType}
                    onChange={handleDwellingInfoChange}
                    required
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    sx={CustomStylesForTextFileds}
                    error={!!errors.otherconstructionType}
                    helperText={errors.otherconstructionType}
                  />
                </Grid>
              )}

              {/* Row 2: Year Built, Number of Stories, Square Footage */}


              {/* {renderDateField('yearBuilt', 'Year Built *')} */}


              <Grid item xs={12} sm={6}>
                <TextField
                  label="Number of Stories"
                  name="numberOfStories"
                  type="number"
                  value={formData.numberOfStories}
                  onChange={handleDwellingInfoChange}
                  required
                  fullWidth
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  sx={CustomStylesForTextFileds}
                  error={!!errors.numberOfStories}
                  helperText={errors.numberOfStories}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Square Footage"
                  name="squareFootage"
                  type="number"
                  value={formData.squareFootage}
                  onChange={handleDwellingInfoChange}
                  required
                  fullWidth
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  sx={CustomStylesForTextFileds}
                  error={!!errors.squareFootage}
                  helperText={errors.squareFootage}
                />
              </Grid>

              {/* Row 3: Heating Type and Specify Other Heating Type  */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <SelectField
                    name="heatingType"
                    label="Heating Type"
                    required
                    options={[
                      { value: 'gas', label: 'Gas' },
                      { value: 'electric', label: 'Electric' },
                      { value: 'heat_pump', label: 'Heat Pump' },
                      { value: 'other', label: 'Other' },
                    ]}
                    value={formData.heatingType}
                    onChange={handleDwellingInfoChange}
                    error={!!errors.heatingType}
                    helperText={errors.heatingType ? <FormHelperText error>{errors.heatingType}</FormHelperText> : ""}
                  />
                </FormControl>
              </Grid>
              {formData.heatingType === 'other' && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Specify Other Heating Type"
                    name="otherHeatingType"
                    value={formData.otherHeatingType}
                    onChange={handleDwellingInfoChange}
                    required
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    sx={CustomStylesForTextFileds}
                    error={!!errors.otherHeatingType}
                    helperText={errors.otherHeatingType}
                  />
                </Grid>
              )}

              {/* Row 5: plumbing_installed_year and wiring_installed_year Upgrade Dates */}


              {/* Row 7: Distance to Fire Hydrant and Fire Station */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Distance to Fire Hydrant (Feet)"
                  name="fireHydrantDistance"
                  type="number"
                  value={formData.fireHydrantDistance}
                  onChange={handleDwellingInfoChange}
                  required
                  fullWidth
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  sx={CustomStylesForTextFileds}
                  error={!!errors.fireHydrantDistance}
                  helperText={errors.fireHydrantDistance}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Distance to Fire Station (Miles)"
                  name="fireStationDistance"
                  type="number"
                  value={formData.fireStationDistance}
                  onChange={handleDwellingInfoChange}
                  required
                  fullWidth
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  sx={CustomStylesForTextFileds}
                  error={!!errors.fireStationDistance}
                  helperText={errors.fireStationDistance}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={6}>
                <DatePicker
                  selected={formData.yearBuilt ? new Date(formData.yearBuilt, 0, 1) : null} // Create a date object
                  onChange={(date) => handleDwellingInfoChange(null, date, 'yearBuilt')}   
                  placeholderText='yyyy'                
                  dateFormat="yyyy" // Only show the year
                  showYearPicker // Only show the year picker
                  dropdownMode="select" // Use select dropdowns for year and month
                  customInput={
                    <TextField
                      label="Year Built"
                      name="yearBuilt"
                      required
                      fullWidth                
                      value={formData.yearBuilt ? formData.yearBuilt.toString() : ''} // Display year as string
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.yearBuilt}
                      helperText={errors.yearBuilt}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CalendarTodayIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                />
              </Grid> */}
              {renderYearField('yearBuilt', 'Year Built *')}
            </Grid>
          </Paper>



          <Typography
            className='Nasaliza'
            variant="h6"
            sx={{
              fontWeight: 600,
              mt: 3, mb: 3,
              color: '#010066',
              textAlign: 'left',
            }}
          >
            Upgrades
          </Typography>

          <Paper elevation={2} sx={{ padding: 2, marginTop: 2 }}>
            <Grid container spacing={2}>

              
              {renderYearField('plumbing_installed_year', 'Year Plumbing System Installed/Last Upgraded *')}
              {renderYearField('wiring_installed_year', 'Year Wiring System Installed/Last Upgraded *')}
              {renderYearField('heating_installed_year', 'Year Heating System Installed/Last Upgraded *')}
              {renderYearField('roof_installed_year', 'Year Roof System Installed/Last Upgraded *')}
            </Grid>

          </Paper>

          <Typography
            className='Nasaliza'
            variant="h6"
            sx={{
              fontWeight: 600,
              mb: 3, mt: 3,
              color: '#010066',
              textAlign: 'left',
            }}
          >
            Additional Dwelling Questions
          </Typography>

          <Paper elevation={2} sx={{ padding: 2, marginTop: 2 }}>
            {/* Row 1: Alternate Heating */}
            <Grid item xs={12} container alignItems="center">
              <Grid item xs={8} container textAlign={"left"}>
                <Typography variant="body2">
                  Alternate Heating? (Wood/Coal/Pellet Stoves, Space Heaters, etc.)
                  <span style={{ color: 'black' }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={4} container justifyContent="flex-end">
                <FormControl required>
                  <RadioGroup
                    row
                    name="alternateHeating"
                    value={formData.alternateHeating}
                    onChange={handleDwellingInfoChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio size="small" />}
                      label={<Typography variant="body2">Yes</Typography>}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio size="small" />}
                      label={<Typography variant="body2">No</Typography>}
                    />
                  </RadioGroup>
                  {errors.alternateHeating && (
                    <FormHelperText error>{errors.alternateHeating}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            {/* Row 2: Business Conducted on Premises */}
            <Grid item xs={12} container alignItems="center">
              <Grid item xs={8} container textAlign={"left"}>
                <Typography variant="body2">
                  Any Business Conducted On Premises?<span style={{ color: 'black' }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={4} container justifyContent="flex-end">
                <FormControl required>
                  <RadioGroup
                    row
                    name="any_business_conducted_on_premises"
                    value={formData.any_business_conducted_on_premises}
                    onChange={handleDwellingInfoChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio size="small" />}
                      label={<Typography variant="body2">Yes</Typography>}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio size="small" />}
                      label={<Typography variant="body2">No</Typography>}
                    />                  </RadioGroup>
                  {errors.any_business_conducted_on_premises && (
                    <FormHelperText error>{errors.any_business_conducted_on_premises}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            {/* Row 3: Trampoline or Ramp */}
            <Grid item xs={12} container alignItems="center">
              <Grid item xs={8} container textAlign={"left"}>
                <Typography variant="body2">
                  Trampoline or Skateboard/Bicycle Ramp?<span style={{ color: 'black' }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={4} container justifyContent="flex-end">
                <FormControl required>
                  <RadioGroup
                    row
                    name="trampolineRamp"
                    value={formData.trampolineRamp}
                    onChange={handleDwellingInfoChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio size="small" />}
                      label={<Typography variant="body2">Yes</Typography>}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio size="small" />}
                      label={<Typography variant="body2">No</Typography>}
                    />                  </RadioGroup>
                  {errors.trampolineRamp && (
                    <FormHelperText error>{errors.trampolineRamp}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            {/* Row 4: Subject to Flood */}
            <Grid item xs={12} container alignItems="center">
              <Grid item xs={8} container textAlign={"left"}>
                <Typography variant="body2">
                  Subject to Flood, Wave Wash, Windstorm or Seacoast?<span style={{ color: 'black' }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={4} container justifyContent="flex-end">
                <FormControl required>
                  <RadioGroup
                    row
                    name="subjectToFlood"
                    value={formData.subjectToFlood}
                    onChange={handleDwellingInfoChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio size="small" />}
                      label={<Typography variant="body2">Yes</Typography>}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio size="small" />}
                      label={<Typography variant="body2">No</Typography>}
                    />                  </RadioGroup>
                  {errors.subjectToFlood && (
                    <FormHelperText error>{errors.subjectToFlood}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            {/* Row 5: Flood Insurance Requested */}
            <Grid item xs={12} container alignItems="center">
              <Grid item xs={8} container textAlign={"left"}>
                <Typography variant="body2">
                  Flood Insurance Requested?<span style={{ color: 'black' }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={4} container justifyContent="flex-end">
                <FormControl required>
                  <RadioGroup
                    row
                    name="floodInsuranceRequested"
                    value={formData.floodInsuranceRequested}
                    onChange={handleDwellingInfoChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio size="small" />}
                      label={<Typography variant="body2">Yes</Typography>}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio size="small" />}
                      label={<Typography variant="body2">No</Typography>}
                    />                  </RadioGroup>
                  {errors.floodInsuranceRequested && (
                    <FormHelperText error>{errors.floodInsuranceRequested}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            {/* Row 6: Rented to Others */}
            <Grid item xs={12} container alignItems="center">
              <Grid item xs={8} container justifyContent="flex-start">
                <Typography variant="body2">
                  Rented to Others?<span style={{ color: 'black' }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={4} container justifyContent="flex-end">
                <FormControl required>
                  <RadioGroup
                    row
                    name="rentedToOthers"
                    value={formData.rentedToOthers}
                    onChange={handleDwellingInfoChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio size="small" />}
                      label={<Typography variant="body2">Yes</Typography>}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio size="small" />}
                      label={<Typography variant="body2">No</Typography>}
                    />
                  </RadioGroup>
                  {errors.rentedToOthers && (
                    <FormHelperText error>{errors.rentedToOthers}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Paper>

          <Grid item xs={12} md={12} textAlign="center">
            {/* Left side: Coverage Location and Same as Policy Holder Address */}
            <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
              <Typography
                className='Nasaliza'
                variant="h6"
                sx={{
                  fontWeight: 600,
                  color: '#010066',
                  textAlign: 'left',
                }}
              >
                Coverage Location
              </Typography>

            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: -1 }}>
              <Typography sx={{ fontSize: '0.7rem', mr: 2 }}>
                Same as Policy Holder Address
              </Typography>
              <FormControl required>
                <RadioGroup
                  row
                  value={sameAsPolicyInfoAddress ? 'yes' : 'no'}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio size="small" />}
                    label={<Typography sx={{ fontSize: '0.7rem' }}>Yes</Typography>}
                    disabled={sameAsPolicyInfoAddress}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio size="small" />}
                    label={<Typography sx={{ fontSize: '0.7rem' }}>No</Typography>}
                    disabled={sameAsPolicyInfoAddress}
                  />
                </RadioGroup>
              </FormControl>
            </Box>


            {/* Coverage Location Section */}
            <Paper elevation={2} sx={{ padding: 2 }}>
              <Grid container spacing={2}>
                {/* Section for the Address Confirmation */}
                <Grid item xs={12} sm={9} sx={{ mb: 2 }}>
                  {showAddress && suggestedAddress && (
                    <Typography variant="body2">
                      Below is the Validated Address, Click to use it &nbsp;&nbsp;
                      <Button
                        size="small"
                        sx={{
                          color: '#0B70FF',
                          fontWeight: 'bold',
                          border: '1px solid #0B70FF',
                          padding: '2px 8px', // Smaller padding for a sleek look
                          fontSize: '0.75rem', // Smaller font size
                          borderRadius: '4px', // Rounded edges
                          minWidth: 'auto', // Remove minimum width to make it smaller
                          textTransform: 'none', // Disable uppercase text transformation
                        }}
                        onClick={() => handleConfirmAddress(spittedAddress)}
                      >
                        Yes
                      </Button>
                    </Typography>
                  )}
                  {showAddress && suggestedAddress && (
                    <Typography
                      style={{ color: '#0B70FF', fontSize: '0.85rem', marginTop: "0.5rem" }}
                    >
                      "{suggestedAddress}"
                    </Typography>
                  )}
                </Grid>
                {/* Section for the Validate Button and Edit Icon */}
                <Grid item xs={12} sm={3}>
                  {!dwellingconfirmAddress ? (
                    <StyledButtonComponent
                      buttonWidth={90}
                      onClick={handleValidateAddress}
                      disabled={!isAddressValid || isValidatingAddress || dwellingconfirmAddress}
                      disableColor={"#B6E3FF"}
                      size="small"
                    >
                      {isValidatingAddress ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Validate"
                      )}
                    </StyledButtonComponent>
                  ) : (
                    <CheckCircleOutlineIcon color="success" />
                  )}
 <Tooltip title="Edit" arrow placement="right">

                  <IconButton
                    onClick={handleEditFields}
                    disabled={!dwellingconfirmAddress}
                    sx={{ color: '#010066' }}
                  >
                    <EditIcon />
                  </IconButton>
 </Tooltip>
                </Grid>
              </Grid>
              <Grid container spacing={2} >
                {/* Row 1: Street Number and Street Name */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Street Number"
                    name="CoverageLocation_street_number"
                    value={formData.CoverageLocation_street_number}
                    onChange={handleDwellingInfoChange}
                    required
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    sx={CustomStylesForTextFileds}
                    error={!!errors.CoverageLocation_street_number}
                    helperText={errors.CoverageLocation_street_number}
                    disabled={dwellingconfirmAddress && !dwellingeditAddress}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Street Name"
                    name="CoverageLocation_street_name"
                    value={formData.CoverageLocation_street_name}
                    onChange={handleDwellingInfoChange}
                    required
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    sx={CustomStylesForTextFileds}
                    error={!!errors.CoverageLocation_street_name}
                    helperText={errors.CoverageLocation_street_name}
                    disabled={dwellingconfirmAddress && !dwellingeditAddress}

                  />
                </Grid>

                {/* Row 2: City and State */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="City"
                    name="CoverageLocation_city"
                    value={formData.CoverageLocation_city}
                    onChange={handleDwellingInfoChange}
                    required
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    sx={CustomStylesForTextFileds}
                    error={!!errors.CoverageLocation_city}
                    helperText={errors.CoverageLocation_city}
                    disabled={dwellingconfirmAddress && !dwellingeditAddress}

                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <SelectField
                      name="CoverageLocation_state"
                      label="State"
                      options={states.map((state) => ({
                        value: state.value,
                        label: `${state.select} (${state.value})`,
                      }))}
                      value={formData.CoverageLocation_state}
                      onChange={handleDwellingInfoChange}
                      disabled={dwellingconfirmAddress && !dwellingeditAddress}

                      error={!!errors.CoverageLocation_state}
                      helperText={errors.CoverageLocation_state ? <FormHelperText error>{errors.CoverageLocation_state}</FormHelperText> : ""}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <SelectField
                      name="CoverageLocation_country"
                      label="Country"
                      options={[{ value: 'USA', label: 'USA' }]}
                      value={formData.CoverageLocation_country}
                      onChange={handleDwellingInfoChange}
                      disabled={dwellingconfirmAddress && !dwellingeditAddress}

                      error={!!errors.CoverageLocation_country}
                      helperText={errors.CoverageLocation_country ? <FormHelperText error>{errors.CoverageLocation_country}</FormHelperText> : ""}
                    />
                  </FormControl>
                </Grid>

                {/* Row 3: Zip Code */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="ZIP Code"
                    name={"CoverageLocation_zip"}
                    value={formData.CoverageLocation_zip}
                    onChange={handleDwellingInfoChange}
                    required
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    sx={CustomStylesForTextFileds}
                    error={!!errors.CoverageLocation_zip}
                    helperText={errors.CoverageLocation_zip}
                    disabled={dwellingconfirmAddress && !dwellingeditAddress}

                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Additional Information"
                    name="additionalInfo"
                    multiline
                    rows={4}
                    value={formData.additionalInfo}
                    onChange={handleDwellingInfoChange}
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    sx={CustomStylesForTextFileds}
                  />
                </Grid>
              </Grid>


            </Paper>
          </Grid>
          {/* "Next" and "Back" Buttons */}
          <Box mt={3} display="flex" justifyContent={'space-between'}>
            <Box >
              <StyledButtonComponent
                buttonWidth={100}
                variant="contained"
                onClick={onBack}
                startIcon={<NavigateBeforeIcon />}
              >
                Back
              </StyledButtonComponent>
            </Box>
            <Box sx={{ textAlign: 'right', mt: 2 }}>
              <StyledButtonComponent
                buttonWidth={100}
                variant="outlined"
                sx={{ mr: 2 }}
                disableColor={"#B6E3FF"}
                disabled={!enableReviewButton}  // Correctly use the prop here   
                onClick={onReviewClick} // Add the onClick handler   
              >
                Review
              </StyledButtonComponent>
              <StyledButtonComponent
                buttonWidth={100}
                variant="outlined"
                onClick={handleNext}
                endIcon={<NavigateNextIcon />}
                disableColor={"#B6E3FF"}
                disabled={!isFormValid() || !dwellingconfirmAddress}
              >
                Next
              </StyledButtonComponent>
            </Box>

          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default DwellingInfo;