import React, { useState, useCallback } from 'react';
import {
  Box, Grid, CircularProgress, Button, Typography, Container, Avatar,
  TextField, Paper, InputAdornment, Snackbar, FormControlLabel, Link, Checkbox
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import StyledButtonComponent from '../../../components/StyledButton';
import { useNavigate } from 'react-router-dom';
import SignInNavbar from '../../../components/SignInNavbar';
import ReCAPTCHA from 'react-google-recaptcha';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import InsurAI_IMG from "../../../assets/sideimg_1.jpg";
import Footer from '../../../components/footer';
import axios from 'axios';
import Header from '../../../components/header';

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export default function PolicySignin() {
  const [formState, setFormState] = useState({
    email: '',
    otp: '',
    recaptchaToken: null,
    error: '',
    sent: false,
    jwtToken: null,
  });
  const [loading, setLoading] = useState({
    verify: false,
    resendOTP: false,
    signIn: false,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isScreen = useMediaQuery('(max-width:900px)');
  const [policyToken, setpolicyToken] = useState('')
  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormState(prev => ({ ...prev, [name]: value, error: '' }));
  }, []);

  const handleRecaptchaChange = useCallback((value) => {
    setFormState(prev => ({ ...prev, recaptchaToken: value }));
  }, []);

  const isEmailValid = useCallback((email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }, []);

  const handleApiCall = useCallback(async (apiFunction, loadingKey, successCallback) => {
    setLoading(prev => ({ ...prev, [loadingKey]: true }));
    setFormState(prev => ({ ...prev, error: '' }));

    try {
      const response = await apiFunction();
      if (response.status === 202) {
        successCallback(response);
      } else {
        throw new Error(response.data.error || 'Operation failed. Please try again.');
      }
    } catch (error) {
      console.error(`Error during ${loadingKey}:`, error);
      // Check for different error properties in the response
      const errorMessage = error.response?.data?.error ||
        error.response?.data?.email_error || // Check for email_error
        `An error occurred during ${loadingKey}.`;

      setFormState(prev => ({ ...prev, error: errorMessage }));
      localStorage.removeItem("policyIntakeToken")
    } finally {
      setLoading(prev => ({ ...prev, [loadingKey]: false }));
    }
  }, []);

  const handleVerifyEmail = useCallback(() => {
    const apiCall = () => axios.post(`${process.env.REACT_APP_URL}Policy/policy_verify_email/`, { email: formState.email });
    const successCallback = (response) => {
      console.log("response", response, response.data);
      setpolicyToken(response.data.token)
      // localStorage.setItem("policyIntakeToken", response.data.token)      
      setpolicyToken(response.data.token)
      // localStorage.setItem("policyIntakeToken", response.data.token)      
      setFormState(prev => ({ ...prev, sent: true, jwtToken: response.data.token }));
      setOpenSnackbar(true);
    };
    handleApiCall(apiCall, 'verify', successCallback);
  }, [formState.email, handleApiCall]);

  const handleResendOTP = useCallback(() => {
    const apiCall = () => axios.post(`${process.env.REACT_APP_URL}Policy/policy_verify_email/`, {
      email: formState.email,
      token: formState.jwtToken
    });
    const successCallback = () => setOpenSnackbar(true);
    handleApiCall(apiCall, 'resendOTP', successCallback);
  }, [formState.email, formState.jwtToken, handleApiCall]);

  const handleLogin = useCallback(() => {
    const apiCall = () => axios.post(`${process.env.REACT_APP_URL}Policy/policy_signin/`, {
      email: formState.email,
      otp: formState.otp
    });
    const successCallback = (response) => {
      localStorage.setItem("PolicyHolderDetailsAfterPolicyIntakeSignIn", JSON.stringify(response.data.user_details));
      navigate('/Demo/SmartQuote');
    }
    localStorage.setItem("policyIntakeToken", policyToken)
    handleApiCall(apiCall, 'signIn', successCallback);
  }, [formState.email, formState.otp, handleApiCall, navigate]);

  const handleCloseSnackbar = useCallback((event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  }, []);

  const [checked, setChecked] = useState(false);


  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      {/* <SignInNavbar /> */}
      <Header />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Verification OTP sent successfully to your email!
        </Alert>
      </Snackbar>
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <Box sx={{ position: "relative", width: isScreen ? "100vw" : "50vw", height: "700px", background: 'linear-gradient(to bottom, #001660, blue)' }}>
            
            <Box sx={{
              position: "absolute", top: 0, left: 0, right: 0, bottom: 0,
              display: "flex", flexDirection: "column", justifyContent: "center",
              alignItems: "center", color: "white", padding: 4,
            }}>
               <Typography variant="h3"   className='Nasaliza' sx={{color:'orange'}}>  Policy Intake</Typography>
              <Typography variant="h3" component="h1" gutterBottom className='Nasaliza'>
              Sign in to explore
              </Typography>
              <Typography variant="h2" component="h2" className='Nasaliza'>
                Innovon Tech Solutions
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box sx={{
            bgcolor: "background.paper", borderRadius: 2,
            width: isScreen ? "100vw" : "50vw", height: "700px",
            display: "flex", flexDirection: "column", justifyContent: "center",
          }}>
            <Container component="main" maxWidth="xs">
              <Paper elevation={6} sx={{ padding: 2, borderRadius: 2 }}>
                <Box>
                  <Grid sx={{
                    backgroundColor: "rgb(49, 114, 246)", width: "100%", height: '40%',
                    position: 'relative', bottom: "2rem", color: 'white',
                    borderRadius: "20px", padding: '0.5rem 0rem'
                  }}>
                    <Typography component="h1" variant="h6" sx={{ color:'orange'}} className='Nasaliza'>
                      
                      Policy Intake  
                    </Typography>
                  <Typography>Sign in</Typography>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                        <LockOutlinedIcon />
                      </Avatar>
                    </Box>
                  </Grid>
                  <Box component="form" sx={{ width: "100%", mt: 1 }}>
                    <Box sx={{ padding: theme.spacing(4), margin: 'auto', maxWidth: '400px' }}>
                      <Grid item xs={12} md={12} container justifyContent="center" spacing={isMobile ? 1 : 2}>
                        {formState.error && (
                          <Grid item xs={12}>
                            <Typography style={{ color: "red" }}>{formState.error}</Typography>
                          </Grid>
                        )}
                        <Grid item xs={12} md={12} container justifyContent="center">
                          <TextField
                            fullWidth
                            margin="normal"
                            required
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={formState.email}
                            onChange={handleInputChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {formState.sent ? (
                                    <CheckCircleOutlineIcon style={{ color: 'green' }} />
                                  ) : (
                                    <StyledButtonComponent
                                      buttonWidth={90}
                                      disableColor={"#B6E3FF"}
                                      onClick={handleVerifyEmail}
                                      style={{ margin: 0, padding: 0 }}
                                      disabled={!isEmailValid(formState.email) || loading.verify}
                                    >
                                      {loading.verify ? <CircularProgress size={24} color="inherit" /> : 'Verify'}
                                    </StyledButtonComponent>
                                  )}
                                </InputAdornment>
                              ),
                              sx: {
                                color: '#000000',
                                '& .MuiOutlinedInput-notchedOutline': {
                                  border: 'none',
                                  borderBottom: '0.2px solid rgba(0, 0, 0, 0.5)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderBottom: '0.2px solid rgba(0, 0, 0, 0.5)',
                                  borderRadius: "none"
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderBottom: '2px solid #0B70FF',
                                  borderRadius: "none"
                                },
                              }
                            }}
                          />
                        </Grid>
                        {formState.sent && (
                          <>
                            <Grid item xs={12} md={12} container alignItems="center">
                              <Grid item xs={7} md={6}>
                                <TextField
                                  fullWidth
                                  margin="normal"
                                  required
                                  name="otp"
                                  label="OTP"
                                  id="otp"
                                  value={formState.otp}
                                  onChange={handleInputChange}
                                  autoComplete="off"
                                  InputProps={{
                                    sx: {
                                      color: '#000000',
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                        borderBottom: '0.2px solid rgba(0, 0, 0, 0.5)',
                                      },
                                      '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderBottom: '0.2px solid rgba(0, 0, 0, 0.5)',
                                      },
                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderBottom: '2px solid #0B70FF',
                                      },
                                    }
                                  }}
                                />
                              </Grid>

                              <Grid item xs={3} md={6}>
                                <StyledButtonComponent
                                  buttonWidth={130}
                                  disableColor={"#B6E3FF"}
                                  onClick={handleResendOTP}
                                  disabled={loading.resendOTP}
                                >
                                  {loading.resendOTP ? <CircularProgress size={24} color="inherit" /> : 'Resend OTP'}
                                </StyledButtonComponent>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} md={12} sx={{ marginBottom: '0.8rem' }}>
                              <ReCAPTCHA sitekey={`${process.env.REACT_APP_RECAPTCHA}`} onChange={handleRecaptchaChange} />
                            </Grid>
                            <Grid sx={{ textAlign: 'left' }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name='terms&conditions'
                                    checked={checked}
                                    onChange={handleCheckboxChange}
                                    color="primary"
                                    size='25px'
                                  />
                                }
                                label={
                                  <Typography variant="body2" >
                                    I accept the <Link href="/TermsofUse" target="_blank" >Terms of Use</Link>
                                  </Typography>
                                } />
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                              <StyledButtonComponent
                                buttonWidth={150}
                                disableColor={"#B6E3FF"}
                                onClick={handleLogin}
                                disabled={
                                  !formState.email ||
                                  !formState.otp ||
                                  !formState.recaptchaToken || !checked ||
                                  loading.signIn // Button is disabled while loading
                                }
                              >
                                {loading.signIn ? ( // Show loading indicator while signIn is true
                                  <>
                                    <CircularProgress size={24} color="inherit" />
                                    <span variant="body2" sx={{ marginLeft: 1 }}>Signing...</span> {/* Added text */}
                                  </>
                                ) : (
                                  'Sign In'
                                )}
                              </StyledButtonComponent>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12}>
                          <Typography align="center" sx={{ paddingTop: 2, color: 'gray' }} className='Nasaliza'>
                            Don't have an account?<Button color="primary" onClick={() => navigate('/policysignup')}>Sign up</Button>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Container>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}